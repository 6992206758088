import { gql } from 'apollo-boost';

export const FIND_FOLDERS = gql`
    query findFolders($data: FindFoldersInput!) {
        findFolders(data: $data) {
            folders {
                id
                title
                parent
                owner
                before
                path
                lastViewedAt
                lastMovedAt
                createdAt
                updatedAt
                deletedAt
            }
            cursor
        }
    }
`;