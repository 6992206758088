import React, { useState } from 'react';
import styled from 'styled-components';
import CarouselModal from '../../../components/Modals/CarouselModal';
import { AuthContext } from '../../../util/context';
import environment from '../../../env-config';
import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';
import { PRIMARY_COLOR, RED, PRIMARY_COLOR_1 } from '../../../appearance/Colors';
import { Image, Transformation } from 'cloudinary-react';
import { IoIosClose, IoMdSettings } from 'react-icons/io';
import useCheckValidity from '../../../util/useCheckValidity';

import ImageNAIcon from '../../../appearance/ImageNAIcon';
import customAnalytics from '../../../util/custom-analytics';

function URLPreviewPlugin({ index, metaData, message, style }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    const { checkImageValidity } = useCheckValidity();

    const { updateURL } = useUpdateMessageMutation();

    const {
        state: { holdingKeys, keys }
    } = React.useContext(AuthContext);

    // const metaActive = holdingKeys && (keys.Meta || keys.Control);
    const metaActive = false;

    // const checkIfEmpty = async () => {
    //     let urlMeta = message.urlMeta[index];
    //     if (!urlMeta.description && !urlMeta.image && !urlMeta.publisher && !urlMeta.title) {
    //         await message.urlMeta.splice(index, 1);
    //     }
    // };

    const deleteAll = async () => {
        await message.urlMeta.splice(index, 1, {});
        updateURL(message, message.urlMeta);
    };

    const deleteMeta = async () => {
        await message.urlMeta.splice(index, 1, { url: message.urlMeta[index].url, logo: message.urlMeta[index].logo });
        updateURL(message, message.urlMeta);
    };

    const deleteUrlImage = () => {
        message.urlMeta[index].image = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deletePublisher = () => {
        message.urlMeta[index].publisher = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deleteTitle = () => {
        message.urlMeta[index].title = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const deleteDescription = () => {
        message.urlMeta[index].description = null;
        // checkIfEmpty();
        updateURL(message, message.urlMeta);
    };

    const removeButton = callback => {
        if (metaActive || isEditable) {
            return (
                <div onClick={callback} style={{ cursor: 'pointer' }}>
                    <IoIosClose color={RED} size={24}></IoIosClose>
                </div>
            );
        }
        return null;
    };

    // const removeSection = callback => {
    //     if (metaActive) {
    //         return (
    //             <div onClick={callback} style={{ color: 'white', paddingLeft: 8, marginBottom: 16, cursor: 'pointer' }}>
    //                 Remove preview
    //             </div>
    //         );
    //     }
    // };

    const renderOpenOrRemove = () => {
        if (!isEditable) {
            return (
                <a
                    href={metaData.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        color: PRIMARY_COLOR,
                        textDecoration: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: PRIMARY_COLOR,
                        borderStyle: 'solid',
                        flex: 1
                        // marginTop: 12,
                        // marginLeft: metaActive ? 8 : 0
                    }}
                    onClick={() => {
                        customAnalytics.message_action({
                            'action': 'Open',
                            "type": "Link"
                        }, message);
                    }}
                >
                    <div style={{ padding: 8 }}>Open link</div>
                </a>
            );
        } else {
            return (
                <>
                    {metaData.description || metaData.title || metaData.image ? (
                        <div
                            onClick={() => {
                                deleteMeta();
                                setIsEditable(!isEditable);
                                customAnalytics.message_edit({
                                    'action': 'Keep',
                                    "type": "Link"
                                }, message);
                            }}
                            style={{
                                color: PRIMARY_COLOR,
                                textDecoration: 'none',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: 8,
                                borderWidth: 1,
                                borderColor: PRIMARY_COLOR,
                                borderStyle: 'solid',
                                flex: 1,
                                fontWeight: '600',
                                cursor: 'pointer',
                                marginRight: 8,
                                textAlign: 'center'
                                // marginTop: 12,
                                // marginLeft: metaActive ? 8 : 0
                            }}
                        >
                            <div style={{ padding: 8 }}>Keep link</div>
                        </div>
                    ) : null}
                    <div
                        onClick={() => {
                            deleteAll()
                            customAnalytics.message_edit({
                                "action": 'Delete',
                                "type": "Link"
                            }, message);
                        }}
                        style={{
                            color: RED,
                            textDecoration: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: 8,
                            borderWidth: 1,
                            borderColor: RED,
                            borderStyle: 'solid',
                            flex: 1,
                            fontWeight: '600',
                            cursor: 'pointer',
                            textAlign: 'center'
                            // marginTop: 12,
                            // marginLeft: metaActive ? 8 : 0
                        }}
                    >
                        <div style={{ padding: 8 }}>Delete</div>
                    </div>
                </>
            );
        }
    };

    const renderSettingsButton = () => {
        let urlMeta = message.urlMeta[index];
        if (urlMeta) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 8,
                        borderStyle: 'solid',
                        borderRadius: 8,
                        borderWidth: 1,
                        borderColor: 'black',
                        marginRight: 8,
                        marginLeft: metaActive || isEditable ? 8 : 0,
                        cursor: 'pointer'
                    }}
                    onClick={() => {
                        setIsEditable(!isEditable);
                        if (!isEditable) {
                            customAnalytics.ui_action({
                                "action": "Open Link Edit",
                            });
                        }
                    }}
                >
                    {!isEditable ? (
                        <IoMdSettings name="settings-sharp" size={24} color={PRIMARY_COLOR}></IoMdSettings>
                    ) : (
                        <span style={{ fontWeight: '600' }}>Close</span>
                        // <IoIosClose name="close" size={24} color={PRIMARY_COLOR}></IoIosClose>
                    )}
                </div>
            );
        }
    };

    const isDirectImageUrl = metaData?.url?.match(/\.(jpeg|jpg|gif|png)$/i);

    if (metaData) {
        return (
            <>
                <div
                    style={{
                        backgroundColor: 'white',
                        borderRadius: 8,
                        padding: 8,
                        // maxWidth: 450,
                        ...style
                    }}
                >
                    <div
                        style={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            borderLeft: 'black',
                            borderLeftWidth: 4,
                            borderStyle: 'solid',
                            paddingLeft: metaActive || isEditable ? 0 : 8
                            // maxWidth: 450
                        }}
                    >
                        {/* {metaData.publisher ? (
                            <UrlSection>
                                {removeButton(deletePublisher)}
                                <div style={{ color: '#333', fontWeight: 600 }}>{metaData.publisher}</div>
                            </UrlSection>
                        ) : null} */}
                        {(metaData.image || isDirectImageUrl) ? (
                            <ImageUrlSection>
                                {removeButton(deleteUrlImage)}
                                <div
                                    style={{ flex: 1 }}
                                    onClick={e => {
                                        e.preventDefault();
                                        setLightboxIsOpen(true);
                                    }}
                                >
                                    {isDirectImageUrl ? (
                                        <img
                                            src={metaData.url}
                                            style={{
                                                borderRadius: 8,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                maxHeight: '300px'
                                            }}
                                        />
                                    ) : checkImageValidity(metaData.image) ? (
                                        <Image
                                            cloudName={environment.REACT_APP_CLOUD_NAME}
                                            publicId={metaData.image}
                                            width={'1.0'}
                                            crop={'pad'}
                                            style={{
                                                borderRadius: 8,
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                maxHeight: '250px'
                                            }}
                                        >
                                            <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                                        </Image>
                                    ) : (
                                        <div style={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            minWidth: 150,
                                            minHeight: 100,
                                            backgroundColor: PRIMARY_COLOR_1,
                                            borderRadius: 8,
                                            display: 'flex'
                                        }}>
                                            <ImageNAIcon />
                                        </div>
                                    )}
                                </div>
                            </ImageUrlSection>
                        ) : null}
                        {metaData.title ? (
                            <UrlSection>
                                {removeButton(deleteTitle)}
                                <div
                                    style={{
                                        flex: 1,
                                        fontWeight: 600
                                    }}
                                >
                                    {metaData.title}
                                </div>
                            </UrlSection>
                        ) : null}
                        {metaData.description ? (
                            <UrlSection>
                                {removeButton(deleteDescription)}
                                <TruncateWrapper>{metaData.description}</TruncateWrapper>
                            </UrlSection>
                        ) : null}
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                marginTop: metaData.description || metaData.title || metaData.image ? 4 : 0
                            }}
                        >
                            {renderSettingsButton()}
                            {renderOpenOrRemove()}
                        </div>
                    </div>
                </div>

                <CarouselModal
                    isOpen={lightboxIsOpen}
                    closeModal={e => {
                        e.stopPropagation();
                        setLightboxIsOpen(false);
                    }}
                    items={[metaData]}
                    fileType="Link"
                ></CarouselModal>
            </>
        );
    }
    return null;
}

const TruncateWrapper = styled.div`
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -webkit-box-orient: vertical;
    // overflow: hidden;
    flex: 1;
    color: rgba(0, 0, 0, 0.8);
`;

const UrlSection = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
`;

const ImageUrlSection = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 4px;
    overflow: hidden;
    max-height: 300px;
`;

export default URLPreviewPlugin;
