import React, { useRef, useState } from 'react';
import { format, compareAsc } from 'date-fns';

import { useDrag } from 'react-dnd';
import styled, { css } from 'styled-components';
import ItemTypes from '../ItemTypes';

import Linkify from 'react-linkify';

import { FaRegStickyNote } from 'react-icons/fa';
import { MdLock } from 'react-icons/md';
import { IoMdMenu } from 'react-icons/io';

import URLPreviewSection from './Sections/URLPreviewSection';
import ImageSection from './Sections/ImageSection';
import FileSection from './Sections/FileSection';
// import ImageFileSection from './Sections/ImageFileSection';
import VideoSection from './Sections/VideoSection';
import AudioSection from './Sections/AudioSection/AudioSection';
import CheckboxSection from './Sections/CheckboxSection';
import { AuthContext } from '../../util/context';

import { NOTE_BACKGROUND, WHITE, STARRED, PRIMARY_COLOR_6, PRIMARY_COLOR_8, PALE_WHITE_6, DETAIL_TEXT, PRIMARY_COLOR_4 } from '../../appearance/Colors';

import { useLazyQuery } from '@apollo/react-hooks';
import { QUERY } from '../../gql-operations';

import ReactTooltip from 'react-tooltip';
import MessageDropdown from './MessageDropdown';
import customAnalytics from '../../util/custom-analytics';
import { isFileTypeImage } from '../../constants/fileUtils';
import EmbedSection from './Sections/EmbedSection';

export const SHOW_OPTIONS = {
    SHOW_FOLDER: 'SHOW_FOLDER'
};

const DefaultMessage = ({
    index,
    screenName,
    name,
    isDropped,
    message,
    carouselMessages,
    multipleSelect,
    imagePreview,
    showFolder,
    smallImage,
    messageFeatures,
    showDeleteModal,
    showMoveMessageModal,
    openMenu,
    showDateAtBottom
}) => {
    const messageRef = useRef();
    const {
        state: { holdingKeys, keys, selectedMessagesMap },
        context: { setSelectedMessage }
    } = React.useContext(AuthContext);
    // console.log('da fffff', holdingKeys, keys.Meta);

    let date = format(new Date(message.createdAt), 'HH:mm');

    const [getMessage] = useLazyQuery(QUERY.GET_MESSAGE);
    const [hoveringMessage, setHoveringMessage] = useState(false);
    const [hoveringContainer, setHoveringContainer] = useState(false);

    const [{ isDragging }, drag] = useDrag({
        item: { name, type: ItemTypes.MESSAGE, message },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            // console.log(dropResult);
            // console.log(item);
            // alert(`You dropped ${message.id} into ${dropResult.name}!`);

            // just to refresh a message when getting dropped
            if (item && dropResult) {
                // console.log('do you go in?', item, dropResult, message)
                getMessage({
                    variables: {
                        data: {
                            id: message.id
                        }
                    }
                });
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        }),
        canDrag: () => {
            if (holdingKeys && keys.Meta) {
                console.log('easy');
                return false;
            } else {
                console.log('not easy');
                return true;
            }
        }
    });

    const opacity = isDragging ? 0.4 : 1;

    const renderImageContent = () => {
        let margin = 0;
        if (message.file) {
            margin = MARGIN;
        }
        if (message.urlMeta.length > 0) {
            message.urlMeta.map((metaData, index) => {
                if (message.urlMeta[index]) {
                    if (metaData.title || metaData.description || metaData.image) {
                        margin = MARGIN;
                    }
                }
            });
        }
        if (message.title && message.title.length > 0) {
            margin = MARGIN;
        }
        if (message.body && message.body.length > 0) {
            margin = MARGIN;
        }
        if (message.image)
            if (!smallImage) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <ImageSection
                            url={message.image.url}
                            image={message.image}
                            onClick={() => {
                                imagePreview(message);
                            }}
                        ></ImageSection>
                    </div>
                );
            } else {
                return (
                    <div
                        style={{
                            marginBottom: margin,
                            backgroundColor: NOTE_BACKGROUND,
                            borderRadius: 8,
                            maxHeight: 250,
                            overflow: 'hidden',
                            display: 'flex'
                        }}
                    >
                        <ImageSection
                            url={message.image.url}
                            image={message.image}
                            // width={250}
                            onClick={() => {
                                imagePreview(message);
                            }}
                        ></ImageSection>
                    </div>
                );
            }
    };

    const renderAWSContent = () => {
        let margin = 0;
        if (message.urlMeta.length > 0) {
            message.urlMeta.map((metaData, index) => {
                if (message.urlMeta[index]) {
                    if (metaData.title || metaData.description || metaData.image) {
                        margin = MARGIN;
                    }
                }
            });
        }
        if (message.title && message.title.length > 0) {
            margin = MARGIN;
        }
        if (message.body && message.body.length > 0) {
            margin = MARGIN;
        }

        if (message.file) {
            if (
                isFileTypeImage(message.file.type) ||
                message.file.type.includes('application')
                // message.file.type === 'image/png' ||
                // message.file.type === 'image/jpg' ||
                // message.file.type === 'image/jpeg' ||
                // message.file.type === 'image/svg' ||
                // message.file.type === 'image/svg+xml' ||
                // message.file.type === 'image/heic' ||
                // message.file.type === 'application/zip' ||
                // message.file.type === 'application/pdf'
            ) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <FileSection file={message.file}></FileSection>
                    </div>
                );
            } else if (
                message.file.type.includes('audio')
                // message.file.type === 'audio/wav' ||
                // message.file.type === 'audio/aac' ||
                // message.file.type === 'audio/mpeg' ||
                // message.file.type === 'audio/mp3'
            ) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <AudioSection file={message.file}></AudioSection>
                    </div>
                );
            } else if (message.file.type.includes('video')) {
                return (
                    <div style={{ marginBottom: margin }}>
                        <VideoSection url={message.file.url}></VideoSection>
                    </div>
                );
            } else {
                // just displaying it as an ordinary file
                return (
                    <div style={{ marginBottom: margin }}>
                        <FileSection file={message.file}></FileSection>
                    </div>
                );
            }
        }
        return null;
    };

    const renderUrlMeta = () => {
        let margin = 0;
        if (message.title && message.title.length > 0) {
            margin = MARGIN / 2;
        }
        if (message.body && message.body.length > 0) {
            margin = MARGIN / 2;
        }
        if (message.urlMeta && message.urlMeta.length > 0) {
            if (message.urlMeta.length > 1) {
                margin = MARGIN / 2
            }
            return message.urlMeta.map((metaData, index) => {
                if (message.urlMeta[index]) {
                    return (
                        <div
                            key={`url-meta-${message.id}-${index}`}
                            style={{ marginBottom: margin, display: 'flex' }}
                        >
                            <URLPreviewSection
                                key={`url-preview-${message.id}-${index}`}
                                index={index}
                                metaData={metaData}
                                message={message}
                            />
                        </div>
                    );
                }
                return null;
            }).filter(Boolean); // Remove null values
        }
        return null;
    };

    const renderTitleContent = () => {
        let margin = 0;
        if (message.body && message.body.length > 0) {
            margin = MARGIN / 2;
        }

        // Updated regex to match numbers with or without spaces
        const phoneRegex = /(\+?\d[\d\s]{9,})/g;
        // Email regex pattern
        const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

        const componentDecorator = (href, text, key) => {
            // Check if the text is an email address
            if (text.match(emailRegex)) {
                return (
                    <CopyableText 
                        key={key} 
                        text={text} 
                        type="Email"
                    />
                );
            }
            return (
                <a
                    href={href.startsWith('http') ? href : `https://${href}`}
                    key={key}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                        customAnalytics.message_action({
                            'action': "Open",
                            "type": "Link"
                        }, message);
                    }}
                >
                    {text}
                </a>
            );
        };

        const CopyableText = ({ text, type = "PhoneNumber" }) => {
            const [showCopied, setShowCopied] = useState(false);

            const handleCopy = async (e) => {
                e.stopPropagation();
                try {
                    // Remove spaces when copying to clipboard only for phone numbers
                    const textToCopy = type === "PhoneNumber" ? text.replace(/\s/g, '') : text;
                    await navigator.clipboard.writeText(textToCopy);
                    customAnalytics.message_action({
                        'action': "Copy",
                        "type": type
                    }, message);
                    setShowCopied(true);
                    setTimeout(() => {
                        setShowCopied(false);
                    }, 1500);
                } catch (error) {
                    console.error('Failed to copy text:', error);
                }
            };

            return (
                <span
                    onClick={handleCopy}
                    style={{
                        position: 'relative',
                        display: 'inline-block',
                        color: '#2b88d9',
                        cursor: 'pointer',
                        fontWeight: 600,
                    }}
                >
                    {text}
                    {showCopied && (
                        <div
                            style={{
                                position: 'absolute',
                                top: '-2em',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                background: 'rgba(0, 0, 0, 0.75)',
                                color: '#fff',
                                padding: '2px 6px',
                                borderRadius: '4px',
                                fontSize: '12px',
                                whiteSpace: 'nowrap',
                                zIndex: 9999,
                                pointerEvents: 'none'
                            }}
                        >
                            Copied!
                        </div>
                    )}
                </span>
            );
        };

        const processText = (text) => {
            // First split by phone numbers
            const parts = text.split(phoneRegex);
            
            return parts.map((part, index) => {
                // Check if part is a phone number
                if (part && part.match(phoneRegex)) {
                    return <CopyableText key={`phone-${index}`} text={part} type="PhoneNumber" />;
                }
                
                // If not a phone number, process for emails using Linkify
                return (
                    <Linkify key={`text-${index}`} componentDecorator={componentDecorator}>
                        {part}
                    </Linkify>
                );
            });
        };

        if (message.title) {
            return (
                <div style={{ marginBottom: margin }}>
                    <PreMessage>
                        {processText(message.title)}
                    </PreMessage>
                </div>
            );
        }
    };

    const renderBodyContent = () => {
        if (message.body)
            return (
                <div
                    style={{
                        color: 'rgba(0,0,0,0.6)',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: NOTE_BACKGROUND,
                        borderRadius: 8,
                        padding: 6
                    }}
                >
                    <TruncateWrapper>
                        <div>{message.body}</div>
                    </TruncateWrapper>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: 4
                            // marginTop: 6
                        }}
                    >
                        <FaRegStickyNote size={14} style={{ marginLeft: 4 }}></FaRegStickyNote>
                        <OpenNoteButton
                            onClick={(e) => {
                                e.stopPropagation();
                                customAnalytics.message_action({
                                    'action': "Open",
                                    "type": "Note"
                                }, message);
                                setSelectedMessage({
                                    ...message,
                                    loadContext: true
                                });
                            }}
                        >
                            Open
                        </OpenNoteButton>
                    </div>
                </div>
            );
    };

    const renderInFolder = () => {
        if (message.folder && message.folder.title) {
            if (messageFeatures && messageFeatures.includes(SHOW_OPTIONS.SHOW_FOLDER)) {
                return (
                    <div
                        style={{
                            marginTop: 8,
                            color: 'rgba(0,0,0,0.6)'
                        }}
                    >
                        {message.folder.title}
                    </div>
                );
            }
        }
    };

    const embedSection = React.useMemo(() => {
        if (message?.urlMeta[0]?.embed) {
            let margin = MARGIN;
            return (
                <div style={{ marginBottom: margin }}>
                    <EmbedSection
                        key={message.id}
                        embed={message.urlMeta[0].embed}
                        onClick={() => {
                            customAnalytics.message_action({
                                'action': "Open",
                                "type": "Embed"
                            }, message);
                        }}
                    />
                </div>
            );
        }
        return null;
    }, [message.id, message?.urlMeta[0]?.embed, message]); // Add dependencies

    const renderEmbedContent = () => {
        return embedSection;
    };

    const renderDateSection = () => {
        return (
            <div
                onClick={e => {
                    if (!showDateAtBottom) {
                        e.stopPropagation();
                        if (multipleSelect) {
                            multipleSelect();
                        }
                    }
                }}
                style={{
                    color: showDateAtBottom ? PRIMARY_COLOR_4 : PALE_WHITE_6,
                    paddingTop: 8,
                    paddingBottom: 2,
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: 12,
                    justifyContent: showDateAtBottom ? 'flex-end' : 'flex-start'
                }}
            >
                <span>{date}</span>
            </div>
        );
    };

    const renderCheckboxSection = () => {
        return (
            <div style={{}}>
                <CheckboxSection
                    disabled={Object.keys(selectedMessagesMap).length > 0}
                    customShowPlus={hoveringMessage}
                    message={message}
                    height={messageRef}
                ></CheckboxSection>
            </div>
        );
    };

    const renderDropdown = () => {
        return (
            <MessageDropdown
                index={index}
                screenName={screenName}
                hide={Object.keys(selectedMessagesMap).length > 0}
                hovering={hoveringContainer}
                message={message}
                showDeleteModal={val => {
                    if (val) showDeleteModal(message);
                }}
                showMoveMessageModal={val => {
                    if (val) showMoveMessageModal(message);
                }}
                openMenu={() => {
                    openMenu();
                    setHoveringMessage(false);
                }}
            ></MessageDropdown>
        );
    };

    return (
        <MessageContainer
            id={message.id}
            ref={messageRef}
            onMouseEnter={() => {
                if (!Object.keys(selectedMessagesMap).length > 0) setHoveringContainer(true);
            }}
            onMouseLeave={() => {
                if (!Object.keys(selectedMessagesMap).length > 0) setHoveringContainer(false);
            }}
            onClick={e => {
                if (e.target?.tagName === 'A') {
                    e.stopPropagation();
                }
            }}
        >
            {renderCheckboxSection()}
            <MessageDiv
                ref={drag}
                holdingKeys={holdingKeys && keys.Meta}
                onMouseEnter={() => {
                    if (!Object.keys(selectedMessagesMap).length > 0) setHoveringMessage(true);
                }}
                onMouseLeave={() => {
                    if (!Object.keys(selectedMessagesMap).length > 0) setHoveringMessage(false);
                }}
                keys={keys}
                style={{
                    opacity,
                    padding: 8,
                    backgroundColor: message.isStarred ? STARRED : WHITE,
                    position: 'relative'
                }}
                dir="auto"
            >
                <MessageContent style={Object.keys(selectedMessagesMap).length > 0 ? { pointerEvents: 'none' } : null}>
                    {renderImageContent()}
                    {renderAWSContent()}
                    {/* {renderEmbedContent()} */}
                    {renderUrlMeta()}
                    {renderTitleContent()}
                    {renderBodyContent()}
                    {renderInFolder()}
                    {showDateAtBottom && renderDateSection()}
                </MessageContent>

                {renderDropdown()}
                {/* {isDropped ? {name} : name} */}
            </MessageDiv>
            {holdingKeys && keys.Meta ? (
                <>
                    <a data-tip data-for="lockIcon">
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 4,
                                borderRadius: 8,
                                backgroundColor: 'rgba(255,255,255,.4)',
                                cursor: 'pointer',
                                width: 24,
                                height: 24
                            }}
                        >
                            <div>
                                <MdLock size={16} color={PRIMARY_COLOR_8}></MdLock>
                            </div>
                        </div>
                    </a>
                    <ReactTooltip place="right" type="dark" effect="solid" id="lockIcon">
                        <span>{`Message locked, you can select any text inside`}</span>
                    </ReactTooltip>
                </>
            ) : null}
            {/* <div style={{ flex: 1 }} /> */}
        </MessageContainer>
    );
};

export const MARGIN = 8;

const MessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    // align-items: center;
    // cursor: pointer;
    flex: 1;
    // width: 100%;
`;

const MessageDiv = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    max-width: 500px;
    word-break: break-word;
    user-select: text;
    margin-right: 40px;
    // padding-top: 12px;
    // padding-bottom: 12px;
    // padding-right: 12px;
    // padding-left: 12px;
    // white-space: pre-wrap;
    // line-height: 150%;
`;

// ${props => {
//     if (props.holdingKeys && props.keys.Meta) {
//         return `text`;
//     }
// }};

const MessageContent = styled.div`
    flex-direction: column;
`;

export const PreMessage = styled.div`
    word-break: break-word;
    white-space: pre-wrap;
    line-height: 150%;
    a {
        font-weight: bold;
        color: #2b88d9;
        text-decoration: none;
    }
`;

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    flex: 1;
    white-space: pre-wrap;
    padding: 1px;
`;

const OpenNoteButton = styled.button`
    background: none;
    border: none;
    color: #2b88d9;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding: 0;
    text-decoration: none;
    
    &:hover {
        text-decoration: underline;
    }
`;

export default DefaultMessage;
