import React from 'react';

import Modal from './Modal';

import { PRIMARY_COLOR, RED, HOVER_RED, GREEN } from '../../appearance/Colors';

import styled from 'styled-components';
import customAnalytics from '../../util/custom-analytics';

function LimitationModal({ title, description, closeTitle, actionTitle, actionTitle2, isOpen, closeModal, userData }) {
    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const onCloseModal = () => {
        closeModal(undefined);
        // cleanup
    };

    // if (title) {
    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            style={deleteModalStyle}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ color: PRIMARY_COLOR, fontWeight: 500, fontSize: 16, marginBottom: 8 }}>{title}</div>
                <span style={{ color: PRIMARY_COLOR, marginBottom: 16 }}>{description}</span>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <ActionButton style={{ marginRight: 8 }} onClick={() => {
                        closeModal(false)
                        customAnalytics.user_action({
                            "action": 'Continue Without Clicked'
                        })
                    }}>
                        {closeTitle ? closeTitle : 'Cancel'}
                    </ActionButton>
                    {userData && (typeof userData.me.emergencyBuffer === 'undefined' || userData.me.emergencyBuffer !== 0) ?
                        <ActionButton style={{ backgroundColor: GREEN, marginRight: 8 }} onClick={() => {
                            closeModal('EMERGENCY')
                            customAnalytics.user_action({
                                "action": 'Add Emergency Messages Clicked'
                            })
                        }}>
                            {actionTitle ? actionTitle : 'Get 10 free messages'}
                        </ActionButton>
                        : null}
                    <ActionButton style={{ backgroundColor: GREEN }} onClick={() => {
                        closeModal('PRO')
                        customAnalytics.user_action({
                            "action": 'Upgrade Clicked'
                        })
                    }}>
                        {actionTitle2 ? actionTitle2 : 'Upgrade'}
                    </ActionButton>
                </div>
            </div>
        </Modal>
    );
    // }
    // return null;
}

const deleteModalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 0,
        maxWidth: 420,
        border: 'none',
        borderRadius: 4,
        padding: 16
    }
};

const ActionButton = styled.button`
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.36);
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.86);
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    border: none;
    user-select: none;
    &:hover {
        background-color: rgba(0, 0, 0, 0.42);
        color: rgba(255, 255, 255, 1);
    }
`;

const DangerTag = styled.button`
    padding-right: 6px;
    padding-left: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: ${RED};
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.86);
    font-size: 12px;
    cursor: pointer;
    font-weight: bold;
    border: none;
    user-select: none;
    &:hover {
        background-color: ${HOVER_RED};
        color: rgba(255, 255, 255, 1);
    }
`;

export default LimitationModal;
