import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import CarouselModal from '../../Modals/CarouselModal';
import DeleteModal from '../../Modals/DeleteModal';

import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';

import { AuthContext } from '../../../util/context';
import environment from '../../../env-config';
import { Image, Transformation } from 'cloudinary-react';

import { IoIosClose, IoMdLink, IoMdTrash } from 'react-icons/io';
import useCheckValidity from '../../../util/useCheckValidity';

import {
    PRIMARY_COLOR,
    WHITE,
    NOTE_BACKGROUND,
    FILE_BLUE,
    PRIMARY_COLOR_4,
    PRIMARY_COLOR_2,
    PRIMARY_COLOR_1,
    PRIMARY_COLOR_6
} from '../../../appearance/Colors';
import ImageNAIcon from '../../../appearance/ImageNAIcon';

import domain_from_url from "../../../util/domain-from-url";
import customAnalytics from '../../../util/custom-analytics';

function URLPreviewSection({ index, metaData, message }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [hovered, setHovered] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const wrapperRef = useRef(null);
    const resizeObserverRef = useRef(null);

    const { checkImageValidity } = useCheckValidity();
    const { updateURL } = useUpdateMessageMutation();
    const { size, aspect_ratio, width } = message.urlMeta[index];

    // Setup ResizeObserver
    useEffect(() => {
        let rafId = null;
        let isObserving = true;
        
        const checkWidth = () => {
            if (!isObserving) return;
            if (wrapperRef.current) {
                const width = wrapperRef.current.getBoundingClientRect().width;
                setIsSmallScreen(width < 400);
            }
        };

        const throttledCheckWidth = () => {
            if (rafId || !isObserving) return;
            
            rafId = window.requestAnimationFrame(() => {
                checkWidth();
                rafId = null;
            });
        };

        resizeObserverRef.current = new ResizeObserver(throttledCheckWidth);
        
        if (wrapperRef.current) {
            resizeObserverRef.current.observe(wrapperRef.current);
            checkWidth();
        }

        // Listen for drag start/end
        const handleDragStart = () => {
            isObserving = false;
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
        };

        const handleDragEnd = () => {
            isObserving = true;
            if (resizeObserverRef.current && wrapperRef.current) {
                resizeObserverRef.current.observe(wrapperRef.current);
                checkWidth();
            }
        };

        document.addEventListener('dragstart', handleDragStart);
        document.addEventListener('dragend', handleDragEnd);

        return () => {
            isObserving = false;
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
            if (rafId) {
                window.cancelAnimationFrame(rafId);
            }
            document.removeEventListener('dragstart', handleDragStart);
            document.removeEventListener('dragend', handleDragEnd);
        };
    }, []);

    // Calculate dimensions
    const getImageDimensions = () => {
        if (!wrapperRef.current) return { width: 200, height: 150 };
        
        const containerWidth = wrapperRef.current.getBoundingClientRect().width;
        let maxWidth = 200; // Default width
        
        // Only resize if container is too small
        if (isSmallScreen && containerWidth < maxWidth + 32) { // 32px for padding/margins
            maxWidth = containerWidth - 16; // Account for padding
        }

        // Calculate height while maintaining aspect ratio
        const height = aspect_ratio ? Math.floor(maxWidth * aspect_ratio) : maxWidth;
        
        return {
            width: maxWidth,
            height: Math.min(height, 350) // Cap height at 350px
        };
    };

    const { width: imageWidth, height: imageHeight } = getImageDimensions();

    const {
        state: { holdingKeys, keys }
    } = React.useContext(AuthContext);

    // super annoying on the message itself, only showing the plugin
    const metaActive = holdingKeys && (keys.Meta || keys.Control) && keys.Shift;

    const deleteUrl = async () => {
        await message.urlMeta.splice(index, 1, { url: message.urlMeta[index].url });
        updateURL(message, message.urlMeta);
    };

    const deleteUrlImage = () => {
        message.urlMeta[index].image = null;
        updateURL(message, message.urlMeta);
    };

    const deletePublisher = () => {
        message.urlMeta[index].publisher = null;
        updateURL(message, message.urlMeta);
    };

    const deleteTitle = () => {
        message.urlMeta[index].title = null;
        updateURL(message, message.urlMeta);
    };

    const deleteDescription = () => {
        message.urlMeta[index].description = null;
        updateURL(message, message.urlMeta);
    };

    const removeButton = callback => {
        if (metaActive) {
            return (
                <div onClick={callback} style={{ cursor: 'pointer' }}>
                    <IoIosClose color={PRIMARY_COLOR} size={16}></IoIosClose>
                </div>
            );
        }
        return null;
    };

    const removeSection = () => {
        if (metaActive) {
            return (
                <div style={{ marginTop: 4, marginLeft: 16 }} onClick={deleteUrl}>
                    Remove preview
                </div>
            );
        }
        return null;
    };

    const isDirectImageUrl = metaData?.url?.match(/\.(jpeg|jpg|gif|png)$/i);

    if (metaData && (metaData.title || metaData.image)) {
        return (
            <>
                <UrlWrapper 
                    ref={wrapperRef}
                    onMouseEnter={() => setHovered(true)} 
                    onMouseLeave={() => setHovered(false)}
                    isSmallScreen={isSmallScreen}
                >
                    {(metaData.image || isDirectImageUrl) ? (
                        <ImageUrlSection isSmallScreen={isSmallScreen}>
                            <div
                                style={{
                                    position: 'relative',
                                    marginRight: !isSmallScreen && metaData.title ? 12 : 0,
                                    width: isSmallScreen ? '100%' : 'auto'
                                }}
                            >
                                <div
                                    onClick={e => {
                                        e.stopPropagation();
                                        setLightboxIsOpen(true);
                                    }}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {isDirectImageUrl ? (
                                        <img
                                            src={metaData.url}
                                            style={{
                                                borderRadius: 8,
                                                maxHeight: 350,
                                                objectFit: 'cover',
                                                width: imageWidth,
                                                height: imageHeight
                                            }}
                                        />
                                    ) : checkImageValidity(metaData.image) ? (
                                        <Image
                                            cloudName={environment.REACT_APP_CLOUD_NAME}
                                            publicId={metaData.image}
                                            width={imageWidth}
                                            height={imageHeight}
                                            crop={'pad'}
                                            style={{
                                                borderRadius: 8,
                                                objectFit: 'cover'
                                            }}
                                        >
                                            <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                                        </Image>
                                    ) : (
                                        <div
                                            style={{
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: imageWidth,
                                                height: Math.min(imageWidth, 100),
                                                backgroundColor: PRIMARY_COLOR_1,
                                                borderRadius: 8,
                                                display: 'flex'
                                            }}
                                        >
                                            <ImageNAIcon />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </ImageUrlSection>
                    ) : null}
                    {metaData.title ? (
                        <ContentSection isSmallScreen={isSmallScreen}>
                            <UrlSection>
                                <div
                                    style={{
                                        fontWeight: 500,
                                        color: 'black',
                                        marginBottom: 4
                                    }}
                                >
                                    {metaData.title}
                                </div>
                                {metaData.description ? (
                                    <TruncateWrapper style={{ color: PRIMARY_COLOR_6 }}>{metaData.description}</TruncateWrapper>
                                ) : null}
                            </UrlSection>
                            <div style={{ 
                                display: 'flex', 
                                flexDirection: 'row', 
                                marginTop: 8, 
                                alignItems: 'center'
                            }}>
                                {metaData.logo ?
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <img 
                                            src={metaData.logo} 
                                            style={{ 
                                                height: 16, 
                                                width: 16, 
                                                objectFit: 'contain', 
                                                marginRight: 6 
                                            }}
                                        />
                                        <span style={{ 
                                            color: PRIMARY_COLOR_4,
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap'
                                        }}>
                                            {metaData.publisher && metaData.publisher.length > 0 
                                                ? metaData.publisher 
                                                : domain_from_url(metaData && metaData.url)}
                                        </span>
                                    </div>
                                    :
                                    <span style={{ 
                                        color: PRIMARY_COLOR_4,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }}>
                                        {domain_from_url(metaData && metaData.url)}
                                    </span>
                                }
                            </div>
                        </ContentSection>
                    ) : null}
                    {hovered ? (
                        <IconWrapper>
                            <IoMdTrash 
                                size={16} 
                                onClick={e => {
                                    e.stopPropagation();
                                    setShowDeleteModal(true);
                                    customAnalytics.message_edit({
                                        "action": 'Delete',
                                        "type": "Link",
                                        "quick": true
                                    }, message);
                                }}
                            />
                        </IconWrapper>
                    ) : null}
                </UrlWrapper>
                <CarouselModal
                    isOpen={lightboxIsOpen}
                    closeModal={e => {
                        e.stopPropagation();
                        setLightboxIsOpen(false);
                    }}
                    items={[metaData]}
                    fileType="Link"
                />
                <DeleteModal
                    isOpen={showDeleteModal}
                    title="Remove Preview?"
                    actionButtonText="Remove"
                    closeModal={response => {
                        if (response) {
                            deleteUrl();
                        }
                        setShowDeleteModal(false);
                    }}
                />
            </>
        );
    } else if (metaData) {
        return (
            <UrlWrapper ref={wrapperRef} style={{ justifyContent: 'flex-start' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    {metaData.logo ?
                        <img 
                            src={metaData.logo} 
                            style={{ 
                                height: 32, 
                                width: 32, 
                                objectFit: 'contain', 
                                borderRadius: 8, 
                                marginRight: 6,
                                flexShrink: 0
                            }}
                        />
                        :
                        <div
                            style={{
                                display: 'flex',
                                padding: 8,
                                backgroundColor: FILE_BLUE,
                                borderRadius: 8,
                                marginRight: 6,
                                flexShrink: 0
                            }}
                        >
                            <IoMdLink color={WHITE} size={16} />
                        </div>
                    }
                    <span style={{ 
                        color: PRIMARY_COLOR_4,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap'
                    }}>
                        {domain_from_url(metaData.url)}
                    </span>
                </div>
            </UrlWrapper>
        );
    }
    return null;
}

const UrlWrapper = styled.div`
    display: flex;
    flex-direction: ${props => props.isSmallScreen ? 'column' : 'row'};
    padding: 8px;
    background-color: ${NOTE_BACKGROUND};
    border-radius: 8px;
    width: 100%;
    position: relative;
    gap: ${props => props.isSmallScreen ? '8px' : '0'};
`;

const ImageUrlSection = styled.div`
    display: flex;
    flex-direction: row;
    width: ${props => props.isSmallScreen ? '100%' : 'auto'};
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: ${props => props.isSmallScreen ? 'center' : 'flex-start'};
    overflow: hidden;
    max-width: ${props => props.isSmallScreen ? '100%' : '200px'};
`;

const ContentSection = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    ${props => props.isSmallScreen && `
        width: 100%;
    `}
    justify-content: center;
`;

const UrlSection = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 0;
    flex: 1;
`;

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.8);
`;

const IconWrapper = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    color: ${PRIMARY_COLOR_2};
    cursor: pointer;
    &:hover {
        color: ${PRIMARY_COLOR_4};
    }
`;

export default URLPreviewSection;
