import React from 'react';

import { MUTATION, QUERY } from '../gql-operations';

import { useMutation } from '@apollo/react-hooks';

import useMoveFolderMutation from './useMoveFolderMutation';

import { AuthContext } from '../util/context';
import customAnalytics from '../util/custom-analytics';

function useReorderFoldersMutation(props) {
    const [reorderFolders] = useMutation(MUTATION.REODER_FOLDERS, {
        onCompleted: res => {
            customAnalytics.folder_edit({
                'action': 'Reorder'
            });
        }
    });
    const [moveFolder] = useMoveFolderMutation();

    const {
        state: { showDeletedMessages, selectedFolder }
    } = React.useContext(AuthContext);

    const runThis = (treeData, source, destination) => {
        // console.log('=== CLIENT REORDER DEBUG START ===');
        // console.log('Tree data before reorder:', treeData);
        // console.log('Source:', source);
        // console.log('Destination:', destination);

        if (source && destination && treeData) {
            const getFolderObject = (parentId, index) => {
                var sourceParent = treeData.items[parentId];
                var newSourceChildren = [...sourceParent.children];
                let realObjId = newSourceChildren[index];
                var realObject = treeData.items[realObjId];

                if (realObjId) {
                    realObject = JSON.parse(JSON.stringify(treeData.items[realObjId].data));
                }

                return realObject;
            };

            let newSource = getFolderObject(source.parentId, source.index);
            let newDestination;

            if (source.parentId === destination.parentId && source.index === destination.index) {
                // console.log('nothing happened, was put to the same place');
            } else if (source.parentId === destination.parentId && source.index !== destination.index) {
                // console.log('moving in the root or children');

                if (source.index > destination.index) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    if (newDestination) {
                        newSource = { ...newSource, before: newDestination.before };
                        newDestination = { ...newDestination, before: newSource.id };
                    }
                } else {
                    if (getFolderObject(destination.parentId, destination.index + 1)) {
                        newDestination = getFolderObject(destination.parentId, destination.index + 1);
                        if (newDestination) {
                            newSource = { ...newSource, before: newDestination.before };
                            newDestination = { ...newDestination, before: newSource.id };
                        }
                    } else {
                        newDestination = getFolderObject(destination.parentId, destination.index);
                        if (newDestination) {
                            newSource = { ...newSource, before: newDestination.id };
                            newDestination = null;
                        } else {
                            return;
                        }
                    }
                }

                // console.log('Constructed move data:', {
                //     newSource: newSource,
                //     newDestination: newDestination
                // });

                return commitFolders(newSource, newDestination);
            } else if (destination.parentId === 'root') {
                // console.log('destination is a root');

                if (getFolderObject(destination.parentId, destination.index)) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    // console.log('i exist somewhere in the middle', newDestination);
                    newSource = { 
                        ...newSource, 
                        parent: null,
                        before: newDestination.before 
                    };
                    newDestination = { 
                        ...newDestination, 
                        before: newSource.id 
                    };
                } else {
                    newDestination = getFolderObject(destination.parentId, destination.index - 1);
                    // console.log('i exist somewhere at the end', newDestination);
                    newSource = { 
                        ...newSource, 
                        parent: null,
                        before: newDestination ? newDestination.id : null 
                    };
                }

                return commitFolders(newSource, newDestination);
            } else if (source.parentId === 'root') {
                // console.log('source is a root');

                if (getFolderObject(destination.parentId, destination.index)) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    // console.log('i exist somewhere in the middle', newDestination);
                    newSource = { 
                        ...newSource, 
                        parent: newDestination.parent 
                    };
                    newSource = { 
                        ...newSource, 
                        before: newDestination.before 
                    };
                    newDestination = { 
                        ...newDestination, 
                        before: newSource.id 
                    };
                } else {
                    newDestination = getFolderObject(destination.parentId, destination.index - 1);
                    // console.log('i exist somewhere at the end', newDestination);
                    if (newDestination) {
                        newSource = { 
                            ...newSource, 
                            parent: newDestination.parent 
                        };
                        newSource = { 
                            ...newSource, 
                            before: newDestination.id 
                        };
                    } else {
                        // console.log('using moveFolder function', newSource, newDestination);
                        let folderToMove = { id: newSource.id };
                        let newParent = { id: destination.parentId };
                        return moveFolderResponse(folderToMove, newParent);
                    }
                }

                // console.log('moving folder to 2', newSource, newDestination);

                return commitFolders(newSource, newDestination);
            } else {
                // console.log('SOMETHING UNEXPECTED');

                if (getFolderObject(destination.parentId, destination.index)) {
                    newDestination = getFolderObject(destination.parentId, destination.index);
                    // console.log('i exist somewhere in the middle', newDestination);
                    newSource = { 
                        ...newSource, 
                        parent: newDestination.parent 
                    };
                    newSource = { 
                        ...newSource, 
                        before: newDestination.before 
                    };
                    newDestination = { 
                        ...newDestination, 
                        before: newSource.id 
                    };
                } else {
                    newDestination = getFolderObject(destination.parentId, destination.index - 1);
                    // console.log('i exist somewhere at the end', newDestination);
                    if (newDestination) {
                        newSource = { 
                            ...newSource, 
                            parent: newDestination.parent 
                        };
                        newSource = { 
                            ...newSource, 
                            before: newDestination.id 
                        };
                    } else {
                        // console.log('using moveFolder function', newSource, newDestination);
                        let folderToMove = { id: newSource.id };
                        let newParent = { id: destination.parentId };
                        return moveFolderResponse(folderToMove, newParent);
                    }
                }

                // console.log('moving folder to 2', newSource, newDestination);

                return commitFolders(newSource, newDestination);
            }
        } else {
            alert('some case is MISSING for reordering');
            // console.log('something is MISSING');
        }
    };

    const moveFolderResponse = async (newFolder, newParent) => {
        let response = await moveFolder(newFolder, newParent);
        let foldersToUpdate = response.data.moveFolder;
        return foldersToUpdate;
    };

    const commitFolders = async (sourceFolder, destinationFolder) => {
        // console.log('Committing folder changes:', {
        //     source: sourceFolder,
        //     destination: destinationFolder
        // });

        let constructData;

        if (!destinationFolder) {
            constructData = {
                folderPrimary: {
                    id: sourceFolder.id,
                    before: sourceFolder.before,
                    parent: sourceFolder.parent
                }
            };
        } else {
            constructData = {
                folderPrimary: {
                    id: sourceFolder.id,
                    before: sourceFolder.before,
                    parent: sourceFolder.parent
                },
                folderFollower: null,
                folderDestination: {
                    id: destinationFolder.id,
                    before: destinationFolder.before
                }
            };
        }

        // console.log('Mutation input data:', constructData);

        let response = await reorderFolders({
            variables: {
                data: constructData
            },
            refetchQueries: await createRefetchQuery(constructData)
        });

        // console.log('Mutation response:', response);
        // console.log('=== CLIENT REORDER DEBUG END ===');

        let foldersToUpdate = response.data.reorderFolders;
        return foldersToUpdate;
    };

    const createRefetchQuery = constructData => {
        let queries = [];
        queries.push({
            query: QUERY.GET_FOLDER_CONTENT,
            variables: {
                data: {
                    folderId: selectedFolder.id,
                    isTrash: showDeletedMessages
                }
            }
        });
        return queries;
    };

    return [runThis];
}

export default useReorderFoldersMutation;
