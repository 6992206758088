import React from 'react';
import classNames from 'classnames';
import { Link, useLocation, useHistory } from 'react-router-dom';

const FooterNav = ({ className, ...props }) => {
    const classes = classNames('footer-nav', className);
    const location = useLocation();
    const history = useHistory();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'auto'
            /* you can also use 'auto' behaviour 
           in place of 'smooth' */
        });
    };

    const handleFAQClick = (e) => {
        e.preventDefault();
        if (location.pathname !== '/') {
            // If we're not on the landing page, navigate there first
            history.push('/', { scrollToFAQ: true });
        } else {
            // If we're already on the landing page, just scroll
            const faqSection = document.getElementById('faq');
            if (faqSection) {
                faqSection.scrollIntoView({ behavior: 'smooth' });
            }
        }
    };

    return (
        <nav {...props} className={classes}>
            <ul className="list-reset">
                <li>
                    <Link to="/privacy" onClick={() => scrollToTop()}>
                        Privacy
                    </Link>
                </li>
                <li>
                    <Link to="/terms" onClick={() => scrollToTop()}>
                        Terms
                    </Link>
                </li>
                <li>
                    <Link to="/contact" onClick={() => scrollToTop()}>
                        Contact
                    </Link>
                    {/* <a href="mailto: support@luckynote.io">Contact</a> */}
                </li>
                <li>
                    <a href="#faq" onClick={handleFAQClick}>
                        FAQ
                    </a>
                </li>
                {/* <li>
          <Link to="#0">About us</Link>
        </li>
        <li>
          <Link to="#0">FAQ's</Link>
        </li>
        <li>
          <Link to="#0">Privacy</Link>
        </li>
        <li>
          <Link to="#0">Support</Link>
        </li> */}
            </ul>
        </nav>
    );
};

export default FooterNav;
