import React, { useState, useEffect } from 'react';
import { cloudinaryCore } from '../../../util/cloudinary';
import {
    GREEN,
    RED,
    ACTION_TAG,
    NOTE_BACKGROUND,
    PALE_WHITE_1,
    PALE_WHITE_4,
    PALE_WHITE_2
} from '../../../appearance/Colors';

import styled, { css } from 'styled-components';
import { Image, Transformation } from 'cloudinary-react';
import environment from '../../../env-config';
import { prettySize } from '../../../util/fileSizeCalculator';
import CarouselModal from '../../../components/Modals/CarouselModal';
import DeleteModal from '../../../components/Modals/DeleteModal';

import FileSaver from 'file-saver';

import { IoMdTrash, IoIosDocument } from 'react-icons/io';
import useS3Request from '../../../hooks/useS3Request';
import useCloudinaryRequest from '../../../hooks/useCloudinaryRequest';
import getImagePreviews from '../../../util/getImagePreviews';

import { IoMdDownload, IoIosRepeat } from 'react-icons/io';

import useUpdateMessageMutation from '../../../hooks/useUpdateMessageMutation';

import ActionTag from '../../Action/ActionTagButton';
import InactiveTag from '../../Action/InactiveTagButton';
import DangerTag from '../../Action/DangerTagButton';
import SimpleTag from '../../Action/SimpleTag';

import { useQuery } from '@apollo/react-hooks';
import { QUERY } from '../../../gql-operations';

import useGetMaxFileSize from '../../../util/max_file_size';
import USER_TYPE from '../../../util/userType';
import USER_ROLE from '../../../util/userRole';
import { AuthContext } from '../../../util/context';
import customAnalytics from '../../../util/custom-analytics';
import { isFileTypeImage } from '../../../constants/fileUtils';

function ImagePreviewPlugin({ message, width, height, crop, onClick, style, dropzoneFiles, hideActions, ...rest }) {
    const wrapperRef = React.useRef();

    // 310 is the widht of the side panel
    const [wrapperWidth, setWrapperWidth] = useState(310);
    // const [wrapperHeight, setWrapperHeight] = useState(20);
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const [imagesPreview, setImagesPreview] = useState(undefined);

    const inputFile = React.useRef(null);

    const {
        state: { uploadProgress }
    } = React.useContext(AuthContext);

    const { data } = useQuery(QUERY.ME, {
        fetchPolicy: 'network-only'
    });

    const { user_max_file_size } = useGetMaxFileSize();

    // custom hooks
    // const { updateMessageFiles } = useS3Request();
    const { updateMessageImages } = useCloudinaryRequest();
    const { deleteImageMutation } = useUpdateMessageMutation();

    const onChangeFile = async event => {
        event.stopPropagation();
        event.preventDefault();
        const fileListAsArray = Array.from(event.target.files);
        getPreviews(fileListAsArray);
        // this.setState({file}); /// if you want to upload latter
    };

    const onDropzoneFiles = async files => {
        const fileListAsArray = Array.from(files);
        getPreviews(fileListAsArray);
    };

    useEffect(() => {
        if (dropzoneFiles) {
            console.log('dropzoneFiles', dropzoneFiles);
            onDropzoneFiles(dropzoneFiles);
        }
        return () => {
            // cleanup
        };
    }, [dropzoneFiles]);

    const getPreviews = async files => {
        let res = await getImagePreviews(files);
        if (res) {
            setImagesPreview(res);
        }
    };

    const clearFileToSend = () => {
        setImagesPreview(undefined);
        // inputFile.current.value = '';
    };

    useEffect(() => {
        if (wrapperRef.current) {
            // console.log('wrapperRef', wrapperRef.current.offsetWidth);
            setWrapperWidth(wrapperRef.current.offsetWidth);
            // setWrapperHeight(wrapperRef.current.offsetHeight);
        }
        return () => { };
    }, [wrapperRef]);

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const publishFiles = async index => {
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        let imageFiles = [];
        // let ordinaryFiles = [];
        filesToSend.forEach(file => {
            if (isFileTypeImage(file.type)) {
                imageFiles.push(file);
            }
            // else {
            //     ordinaryFiles.push(file);
            // }
        });
        // somewhere here the successful upload
        clearFileToSend();
        // if (ordinaryFiles.length) {
        //     await updateMessageFiles(ordinaryFiles, message);
        // }
        if (imageFiles.length) {
            const onlyFirst = imageFiles[index];
            await updateMessageImages([onlyFirst], message);
            customAnalytics.message_edit({
                "action": message.image ? "Replace" : "Add",
                "type": "Image"
            }, message);
        }
    };

    const deleteImage = async () => {
        await deleteImageMutation(message);
    };

    // NOT USED NOW
    // const removeFromTheList = file => {
    //     let previewClone = [...imagesPreview];
    //     let previewIndex = previewClone.findIndex(listFile => listFile.file === file);

    //     if (previewIndex > -1) {
    //         previewClone.splice(previewIndex, 1);
    //         if (previewClone.length > 0) {
    //             setImagesPreview(previewClone);
    //         } else {
    //             setImagesPreview(undefined);
    //         }
    //     }
    // };

    const downloadImage = async (url, name, size) => {
        try {
            // downloadFile(cloudinaryCore.url(message.image.url));
            let url = await cloudinaryCore.url(url);
            FileSaver.saveAs(url, name);
            // const link = document.createElement('a');
            // link.href = cloudinaryCore.url(message.image);
            // link.click();
        } catch (error) {
            console.log('error, trying the old way', error);
            const link = document.createElement('a');
            link.href = cloudinaryCore.url(url);
            link.target = '_blank';
            link.download = true;
            link.click();
        }
        customAnalytics.ui_action({
            'action': "Download Image",
            "size": size
        })
    };

    const renderPublishAction = (index, size) => {
        if (data.me) {
            // if (data.me.role === USER_ROLE.ADMIN) {
            //     return (
            //         <ActionTag
            //             label={message.image ? 'Replace' : 'Publish'}
            //             style={{ backgroundColor: GREEN, marginRight: 8 }}
            //             onClick={() => publishFiles(index)}
            //         />
            //     );
            // } else if (data.me.planType === USER_TYPE.PRO) {
            //     return (
            //         <ActionTag
            //             label={message.image ? 'Replace' : 'Publish'}
            //             style={{ backgroundColor: GREEN, marginRight: 8 }}
            //             onClick={() => publishFiles(index)}
            //         />
            //     );
            // } else if (data.me.planType === USER_TYPE.PERSONAL && size > user_max_file_size) {
            //     return (
            //         <InactiveTag
            //             label="File too large"
            //             style={{
            //                 marginRight: 8
            //             }}
            //         />
            //     );
            // } else
            if (size > user_max_file_size) {
                // TODO PLAN
                return (
                    <InactiveTag
                        label="File too large"
                        style={{
                            marginRight: 8
                        }}
                    />
                );
            } else {
                return (
                    <ActionTag
                        label={message.image ? 'Replace' : 'Publish'}
                        style={{ backgroundColor: GREEN, marginRight: 8 }}
                        onClick={e => {
                            e.stopPropagation();
                            publishFiles(index);
                        }}
                    />
                );
            }
        }
    };

    const renderPreview = () => {
        if (imagesPreview) {
            return imagesPreview.map(({ reader, file }, index) => {
                const { name, size, type } = file;
                console.log('show it sisy', file);
                return (
                    <ImageWrapper key={index} style={{ ...style }} onClick={() => setLightboxIsOpen(true)}>
                        <TopSection>
                            <SectionWrapper>
                                <SimpleTag label={prettySize(size)}></SimpleTag>
                            </SectionWrapper>
                        </TopSection>
                        <div ref={wrapperRef} style={{ width: '100%' }}>
                            {type !== 'image/heic' ? (
                                <img
                                    src={reader.result}
                                    width="100%"
                                    // crop={crop ? crop : 'scale'}
                                    style={{ borderRadius: 8, height: height ? height : 300, objectFit: 'contain' }}
                                ></img>
                            ) : (
                                <div
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        margin: '0px 40px'
                                    }}
                                >
                                    <span>
                                        <strong>.heic</strong> images can be displayed after upload
                                    </span>
                                </div>
                            )}
                        </div>
                        <BottomSection>
                            <SectionWrapper>
                                {/* <div style={{ flex: 1 }}></div> */}
                                {renderPublishAction(index, size)}
                                <ActionTag
                                    label="Cancel"
                                    onClick={e => {
                                        e.stopPropagation();
                                        clearFileToSend();
                                    }}
                                ></ActionTag>
                            </SectionWrapper>
                        </BottomSection>
                        {renderCarousel({ src: reader.result })}
                    </ImageWrapper>
                );
            });
        }
    };

    const renderCarousel = item => {
        return (
            <CarouselModal
                isOpen={lightboxIsOpen}
                closeModal={e => {
                    e.stopPropagation();
                    setLightboxIsOpen(false);
                }}
                items={[item]}
                fileType="Image"
            ></CarouselModal>
        );
    };

    const copyToClipboard = async url => {
        const cloudUrl = cloudinaryCore.url(url);
        await navigator.clipboard.writeText(cloudUrl);
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 1500);
    };

    if (imagesPreview) {
        return renderPreview();
    } else if (message.image) {
        const { url, public_id, size, name, format, displayName } = message.image;
        return (
            <>
                <ImageWrapper ref={wrapperRef} style={{ ...style }}>
                    {!hideActions ? (
                        <TopSection>
                            <SectionWrapper>
                                <SimpleTag label={prettySize(size)}></SimpleTag>
                                <div style={{ flex: 1 }}></div>
                                {format ? <SimpleTag label={format}></SimpleTag> : null}
                            </SectionWrapper>
                        </TopSection>
                    ) : null}
                    {/* <div ref={wrapperRef} style={{ width: '100%', minHeight: 100 }}> */}
                    <Image
                        cloudName={environment.REACT_APP_CLOUD_NAME}
                        publicId={public_id ? public_id : url}
                        width={width ? width : wrapperWidth}
                        height={height ? height : 300}
                        crop={crop ? crop : 'fit'}
                        style={{
                            cursor: 'zoom-in',
                            maxHeight: '100%'
                            // borderRadius: 4
                            // border: BORDER,
                            // borderStyle: 'solid',
                            // borderColor: 'rgba(255,255,255,.6)'
                        }}
                        onClick={e => {
                            // open the carousel
                            e.stopPropagation();
                            // onClick();
                            setLightboxIsOpen(true);
                        }}
                        {...rest}
                    >
                        <Transformation fetchFormat="auto" flags="lossy" quality="50" />
                    </Image>
                    {/* </div> */}
                    {!hideActions ? (
                        <BottomSection>
                            <SectionWrapper>
                                <ActionTag
                                    label="Download"
                                    style={{ marginRight: 8 }}
                                    onClick={e => {
                                        e.stopPropagation();
                                        downloadImage(url, displayName, size);
                                    }}
                                ></ActionTag>
                                <ActionTag
                                    label={
                                        uploadProgress &&
                                            uploadProgress[message.id] &&
                                            uploadProgress[message.id].type === 'image'
                                            ? `Updating... ${parseInt(uploadProgress[message.id].percentage)}%`
                                            : 'Replace'
                                    }
                                    style={
                                        uploadProgress &&
                                            uploadProgress[message.id] &&
                                            uploadProgress[message.id].type === 'image'
                                            ? { marginRight: 8, backgroundColor: GREEN }
                                            : { marginRight: 8 }
                                    }
                                    onClick={e => {
                                        e.stopPropagation();
                                        onButtonClick();
                                    }}
                                ></ActionTag>
                                <DangerTag
                                    label="Remove"
                                    onClick={e => {
                                        e.stopPropagation();
                                        setIsDeleteModalOpen(true);
                                    }}
                                ></DangerTag>
                                <div style={{ flex: 1 }}></div>
                                {/* NOT DEVELOPED YET */}
                                {/* <ActionTag
                                    style={copiedToClipboard ? { backgroundColor: GREEN } : null}
                                    label={!copiedToClipboard ? 'Share' : 'Copied!'}
                                    onClick={e => {
                                        e.stopPropagation();
                                        copyToClipboard(message.image.url);
                                    }}
                                ></ActionTag> */}
                                <DeleteModal
                                    title="Delete image?"
                                    description="You will not be able to get this file once deleted."
                                    isOpen={isDeleteModalOpen}
                                    closeModal={response => {
                                        if (response) {
                                            console.log('what response', response);
                                            deleteImage();
                                        }
                                        setIsDeleteModalOpen(false);
                                    }}
                                ></DeleteModal>
                            </SectionWrapper>
                        </BottomSection>
                    ) : null}
                    <input
                        type="file"
                        accept="image/*, .heic"
                        id="file"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        onChange={onChangeFile}
                    />
                </ImageWrapper>
                {renderCarousel(message.image)}
            </>
        );
    } else {
        return (
            <AddFileWrapper
                style={{ ...style }}
                uploading={uploadProgress && uploadProgress[message.id] && uploadProgress[message.id].type === 'image'}
                onClick={() => onButtonClick()}
            >
                <div style={{ color: 'white' }}>
                    {uploadProgress && uploadProgress[message.id] && uploadProgress[message.id].type === 'image'
                        ? 'Uploading...'
                        : 'Add image'}
                </div>

                <input
                    type="file"
                    accept="image/*"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={onChangeFile}
                />
            </AddFileWrapper>
        );
    }
}

const BORDER = 1;

const AddFileWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid ${PALE_WHITE_4};
    background-color: ${props => (props.uploading ? GREEN : PALE_WHITE_1)};
    cursor: pointer;
    padding: 8px 12px;
    display: flex;
    justify-content: center;
    &:hover {
        background-color: ${PALE_WHITE_2};
    }
`;

const SectionWrapper = styled.div`
    flex: 1;
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`;

const TopSection = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    left: 8px;
`;

const BottomSection = styled.div`
    position: absolute;
    bottom: 8px;
    right: 8px;
    left: 8px;
`;

const ImageWrapper = styled.div`
    position: relative;
    border-radius: 8px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${NOTE_BACKGROUND};
`;

export default ImagePreviewPlugin;
