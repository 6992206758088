import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Button from '../elements/Button';
import ButtonGroup from '../elements/ButtonGroup';
import { FiCheck, FiArrowUpCircle, FiSmile, FiSun } from 'react-icons/fi';
import { GREEN, PRIMARY_TEXT, RED, PRIMARY_COLOR, GREEN_OP_2, GREEN_OP_1 } from '../../../../appearance/Colors';

import { Link } from 'react-router-dom';

import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import customAnalytics from '../../../../util/custom-analytics';

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};

const Pricing = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    pushLeft,
    ...props
}) => {
    const outerClasses = classNames(
        'testimonial section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'testimonial-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
        title: 'Pricing',
        paragraph: `Try before you buy and and upgrade your account by your needs`
    };

    const isMobile = useMediaQuery({ maxWidth: 767 });


    return (
        <section {...props} className={outerClasses} id="pricing">
            <div className="container" style={{
                // maxWidth: 'inherit'
            }}>
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className={tilesClasses}>
                        <div className="tiles-item reveal-from-right" data-reveal-delay="200" style={{ flex: '1 1 300px' }}>
                            <div className="tiles-item-inner">
                                <div className="testimonial-item-content">
                                    <div style={{ fontSize: 16, lineHeight: '28px' }}>Personal</div>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            lineHeight: '34px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        Free
                                    </div>
                                    <div style={{ fontSize: 16, lineHeight: '30px', marginBottom: 16 }}>
                                        try it out
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Unlimited folders & subfolders
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                No limits on the number of folders and subfolders
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Sync across all devices
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Access your messages from any device
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Lucky Actions
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Smart actions on messages
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Look Up
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Search for message content directly
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <Link
                                        to={isMobile ? 'mobile' : 'register'}
                                        className="button button-green button-wide-mobile button-md"
                                        style={{
                                            width: '100%',
                                            maxWidth: 'none'
                                        }}
                                    >
                                        Get Started Free
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" style={{ flex: '1 1 300px' }}>
                            <div className="tiles-item-inner" style={{ position: 'relative' }}>
                                <div className="testimonial-item-content">
                                    <div style={{ fontSize: 16, lineHeight: '28px' }}>Standard</div>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            lineHeight: '34px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <span>$29</span>
                                    </div>
                                    <div style={{ fontSize: 16, lineHeight: '30px', marginBottom: 16 }}>
                                        one time
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Everything in Personal, plus:
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                All Personal features included
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            500 messages
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Perfect for personal use
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Advanced in-app browser
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Quick save content while browsing
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Hug to Luckynote team 🫂
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Support indie developers building useful tools
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <Link
                                        to={isMobile ? 'mobile' : 'register'}
                                        className="button button-green button-wide-mobile button-md"
                                        style={{
                                            width: '100%',
                                            maxWidth: 'none'
                                        }}
                                    >
                                        Get Started Free
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="tiles-item reveal-from-bottom" style={{ flex: '1 1 300px' }}>
                            <div className="tiles-item-inner" style={{ position: 'relative' }}>
                                <div style={{
                                    position: 'absolute',
                                    top: -12,
                                    right: 8,
                                    backgroundColor: RED,
                                    color: 'white',
                                    padding: '6px 12px',
                                    borderRadius: '4px',
                                    fontSize: 12,
                                    fontWeight: '500',
                                    transform: 'rotate(3deg)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                    zIndex: 1
                                }}>
                                    Limited offer
                                </div>
                                <div className="testimonial-item-content">
                                    <div style={{ fontSize: 16, lineHeight: '28px' }}>Unlimited</div>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            lineHeight: '34px',
                                            fontWeight: '600'
                                        }}
                                    >
                                        <span>$49</span>
                                        <span
                                            style={{
                                                fontSize: 16,
                                                lineHeight: '30px',
                                                textDecoration: 'line-through',
                                                marginLeft: 8,
                                                color: RED
                                            }}
                                        >
                                            $69
                                        </span>
                                    </div>
                                    <div style={{ fontSize: 16, lineHeight: '30px', marginBottom: 16 }}>
                                        one time
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Everything in Standard, plus:
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                All Standard features included
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Unlimited messages
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Perfect for power users
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Priority support
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Get help when you need it
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start', marginBottom: 24 }}>
                                        <div style={{ marginRight: 14, marginTop: 4 }}>
                                            <FiCheck size={24}></FiCheck>
                                        </div>
                                        <div style={{ fontSize: 16, lineHeight: '30px', flex: 1, fontWeight: '500' }}>
                                            Early access to new features
                                            <div style={{ fontSize: 14, lineHeight: '20px', color: PRIMARY_COLOR, fontWeight: 'normal' }}>
                                                Be the first to try new capabilities
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                                    <Link
                                        to={isMobile ? 'mobile' : 'register'}
                                        className="button button-green button-wide-mobile button-md"
                                        style={{
                                            width: '100%',
                                            maxWidth: 'none'
                                        }}
                                    >
                                        Get Started Free
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="tiles-item reveal-from-bottom" style={{ flexBasis: 824, maxWidth: '100%', margin: '24px 0 0' }}>
                        <div className="tiles-item-inner">
                            <div className="testimonial-item-content">
                                <div style={{ flexDirection: 'row', alignItems: 'start', display: 'flex' }}>
                                    <div style={{ marginRight: 16 }}>
                                        <FiArrowUpCircle color={GREEN} size={36} />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                        <div style={{ fontSize: 16, lineHeight: '28px', fontWeight: '600' }}>
                                            Pay as you go
                                        </div>

                                        <div style={{ fontSize: 16, lineHeight: '30px' }}>
                                            More options will be available in your account when you're reaching the limits
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </section>
    );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;

export default Pricing;

