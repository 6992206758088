import React, { useState, useEffect, useReducer } from 'react';

import Modal from './Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { useLazyQuery, useMutation } from '@apollo/react-hooks';

import { MUTATION, QUERY } from '../../gql-operations';

import styled from 'styled-components';

import SelectableList from '../SelectableList';

import { AuthContext } from '../../util/context';

import useGetSubfolders from '../../hooks/useGetSubfolders';
import useUpdateFoldersOnTree from '../../hooks/useUpdateFoldersOnTree';
import useMoveMessagesMutation from '../../hooks/useMoveMessagesMutation';
import loadMoreFilteredFolders from '../Folder/Components/loadMoreFilteredFolders';
import { PALE_WHITE, WHITE, PALE_WHITE_2, PALE_WHITE_6, SECONDARY_TEXT } from '../../appearance/Colors';

import useRedirectErrors from '../../errors/useRedirectErrors';

import customAnalytics from '../../util/custom-analytics';
import { HISTORY_SORT_FIELD, DEFAULT_SORT_FIELD, DateField } from '../../constants/dateField';

export const searchActions = {
    JUMP: 'JUMP',
    MOVE_FOLDER: 'MOVE_FOLDER',
    MOVE_MESSAGES: 'MOVE_MESSAGES'
};

function SearchFoldersModal({
    isOpen,
    closeModal,
    currentFolder,
    addNew,
    title,
    addNewMoveMessage,
    enableMoveToRoot,
    dropdownMessage,
    disableMoveToInbox,
    action
}) {
    const [folderSearchInput, setFolderSearchInput] = useState('');
    // const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [createFolderMutation, { data, loading, error }] = useMutation(MUTATION.CREATE_FOLDER, {
        onCompleted: res => {
            customAnalytics.create_folder_from_move_message();
        }
    });
    const [moveMessages] = useMoveMessagesMutation();
    const { moveFolderToRoot, createAndMoveFolder } = useUpdateFoldersOnTree();
    const [loadMoreCheck, setLoadMoreCheck] = useState(true);
    const [loadingOlder, setLoadingOlder] = useState(false);

    const [getSubfolders] = useGetSubfolders();

    const { passingErrors } = useRedirectErrors();

    const inputRef = React.useRef();

    const {
        state: { selectedFolder, messagesToSend, selectedMessage, homeFolder },
        context: { setSelectedFolder, setMessagesToSend, setSelectedMessagesMap, setShowMultiSelect }
    } = React.useContext(AuthContext);

    const [findFolders, { data: findFolderData, error: findFolderError, loading: findFolderLoading, fetchMore }] =
        useLazyQuery(QUERY.FIND_FOLDERS, {
            onError: res => {
                passingErrors(res);
            },
            fetchPolicy: 'network-only'
        });

    useEffect(() => {
        // handleFolderSearchChange('');
        if (isOpen) {
            customAnalytics.modalview('Search Folders');
            customAnalytics.pageview('/message', 'SearchFoldersScreen');
            findFolders({
                variables: {
                    data: {
                        text: '',
                        sortBy: getSortField('', action)
                    }
                }
            });
        }
        return () => {
            // cleanup
        };
    }, [isOpen]);

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    // Add this helper function near the top of your component (inside SearchFoldersModal)
    const getSortField = (searchText, action) => {
        if (searchText.trim() === '') {
            // When no search text, decide based on the current search action
            if (action === searchActions.JUMP) {
                return HISTORY_SORT_FIELD; // Use the HISTORY_SORT_FIELD constant for JUMP
            } else {
                // For MOVE_FOLDER or MOVE_MESSAGES, use the alternate sort field
                return DateField.LAST_MOVED_AT;
            }
        } else {
            // When search text is not empty fall back to the default sort field
            return DEFAULT_SORT_FIELD;
        }
    };

    const handleFolderSearchChange = value => {
        setFolderSearchInput(value);
        // console.log('here is going to be commenced search');
        findFolders({
            variables: {
                data: {
                    text: value,
                    sortBy: getSortField(value, action)
                }
            }
        });
        // putting back the load after each search
        if (!loadMoreCheck) {
            setLoadMoreCheck(true);
        }
    };

    const resetFoldersSearch = () => {
        handleFolderSearchChange('');
        setFolderSearchInput('');
    };

    const moveToRoot = async () => {
        if (currentFolder) {
            let updatedFolder = await moveFolderToRoot(currentFolder);
            console.log('updated', updatedFolder);
            closeModal();
            setTimeout(() => {
                if (updatedFolder) {
                    if (document.getElementById(updatedFolder.id)) {
                        document.getElementById(updatedFolder.id).scrollIntoView();
                    }
                }
            }, 150);
        } else if (selectedFolder) {
            let updatedFolder = await moveFolderToRoot(selectedFolder);
            console.log('updated', updatedFolder);
            closeModal();
            setTimeout(() => {
                if (updatedFolder) {
                    if (document.getElementById(updatedFolder.id)) {
                        document.getElementById(updatedFolder.id).scrollIntoView();
                    }
                }
            }, 150);
        }
    };

    const moveToInbox = async () => {
        if (homeFolder) {
            moveMessagesTo(homeFolder);
            closeModal();
        }
    };

    const renderMoveToRoot = () => {
        if (enableMoveToRoot) {
            if (currentFolder && currentFolder.parent) {
                // console.log('this is the current', currentFolder)
                return <RootFolder onClick={() => moveToRoot()}>Move to root</RootFolder>;
            } else if (selectedFolder && selectedFolder.parent) {
                return <RootFolder onClick={() => moveToRoot()}>Move to root</RootFolder>;
            }
        }
    };

    const renderMoveToInbox = () => {
        if (!enableMoveToRoot && !disableMoveToInbox) {
            if (homeFolder && messagesToSend && messagesToSend.length > 0) {
                if ((selectedFolder && selectedFolder.id.toString()) !== homeFolder.id.toString()) {
                    return <RootFolder onClick={() => moveToInbox()}>Move to Inbox</RootFolder>;
                }
            }
            // NO inbox move for folders
            else if (homeFolder && dropdownMessage) {
                if ((dropdownMessage.folder && dropdownMessage.folder.id.toString()) !== homeFolder.id.toString()) {
                    return <RootFolder onClick={() => moveToInbox()}>Move to Inbox</RootFolder>;
                }
            } else if (homeFolder && selectedMessage) {
                if ((selectedMessage.folder && selectedMessage.folder.id.toString()) !== homeFolder.id.toString() && (selectedFolder && selectedFolder.id.toString()) !== homeFolder.id.toString()) {
                    return <RootFolder onClick={() => moveToInbox()}>Move to Inbox</RootFolder>;
                }
            }
        }
    };

    const removeSelectedMessages = () => {
        setSelectedMessagesMap({});
        setMessagesToSend([]);
        setShowMultiSelect(false);
    };

    const checkHomeFolder = folder => {
        if (homeFolder.id === folder.id) {
            return null;
        } else {
            return folder.id;
        }
    };

    const createFolder = async () => {
        // console.log('messages and message', messagesToSend, selectedMessage);
        if (dropdownMessage) {
            let response = await createFolderMutation({
                variables: {
                    data: {
                        title: folderSearchInput,
                        parent: checkHomeFolder(dropdownMessage.folder)
                    }
                },
            });

            let justCreated = await response.data.createFolder;

            if (justCreated) {
                await moveMessages([dropdownMessage], justCreated, dropdownMessage.folder);
                closeModal(justCreated);
                resetFoldersSearch();
                removeSelectedMessages();
                // route.params.callback(true);
                // analytics here because we can follow the 
                customAnalytics.message_edit({
                    "action": "Move",
                    "type": "Just created folder",
                    'multi_select': messagesToSend && messagesToSend.length,
                    "from": dropdownMessage && dropdownMessage.folder.title === "Inbox" ? "Inbox" : dropdownMessage && dropdownMessage.folder.parent ? "Subfolder" : "HomeFolder",
                    "destination": justCreated.parent ? "Subfolder" : "HomeFolder"
                }, dropdownMessage)
            }
        } else if (messagesToSend.length > 0) {
            let response = await createFolderMutation({
                variables: {
                    data: {
                        title: folderSearchInput,
                        parent: checkHomeFolder(messagesToSend[0].folder)
                    }
                },
            });

            let justCreated = await response.data.createFolder;

            if (justCreated) {
                await moveMessages(messagesToSend, justCreated, messagesToSend[0].folder);
                closeModal(justCreated);
                resetFoldersSearch();
                removeSelectedMessages();
                // route.params.callback(true);
                // analytics here because we can follow the 
                messagesToSend.forEach(message => {
                    customAnalytics.message_edit({
                        "action": "Move",
                        "type": "Just created folder",
                        'multi_select': messagesToSend && messagesToSend.length,
                        "from": message && message.folder.title === "Inbox" ? "Inbox" : message && message.folder.parent ? "Subfolder" : "HomeFolder",
                        "destination": justCreated.parent ? "Subfolder" : "HomeFolder"
                    }, message)
                });
            }
        } else {
            let response = await createFolderMutation({
                variables: {
                    data: {
                        title: folderSearchInput,
                        parent: checkHomeFolder(selectedMessage.folder)
                    }
                }
            });

            let justCreated = await response.data.createFolder;

            if (justCreated) {
                await moveMessages([selectedMessage], justCreated, selectedMessage.folder);
                closeModal(justCreated);
                resetFoldersSearch();
                removeSelectedMessages();
                // route.params.callback(true);
                // analytics here because we can follow the 
                customAnalytics.message_edit({
                    "action": "Move",
                    "type": "Just created folder",
                    'multi_select': messagesToSend && messagesToSend.length,
                    "destination": selectedMessage && selectedMessage.folder.title === "Inbox" ? "Inbox" : selectedMessage && selectedMessage.folder.parent ? "Subfolder" : "HomeFolder",
                    "from": justCreated.parent ? "Subfolder" : "HomeFolder"
                }, selectedMessage)
            }
        }
        customAnalytics.create_folder_from_move_message();
    };

    const renderAddNewMoveMessage = () => {
        if (addNewMoveMessage && folderSearchInput.length > 0) {
            return (
                <SearchFolder
                    onClick={() => {
                        createFolder();
                        // let response = await createFolder({
                        //     variables: {
                        //         data: {
                        //             title: folderSearchInput,
                        //             parent: selectedFolder.id
                        //         }
                        //     }
                        // });
                        // let createdFolder = response.data.createFolder;
                        // closeModal(createdFolder);
                        // resetFoldersSearch();
                        // setSelectedFolder(createdFolder);
                        // setTimeout(() => {
                        //     if (createdFolder) {
                        //         if (document.getElementById(createdFolder.id)) {
                        //             document.getElementById(createdFolder.id).scrollIntoView();
                        //         }
                        //     }
                        // }, 150);
                    }}
                >
                    + Create new {folderSearchInput}
                </SearchFolder>
            );
        }
    };

    const renderAddNew = () => {
        if (addNew && folderSearchInput.length > 0) {
            return (
                <SearchFolder
                    onClick={async () => {
                        console.log('you run this guy, dont you');
                        if (currentFolder) {
                            let createdFolder = await createAndMoveFolder(folderSearchInput, currentFolder);
                            closeModal();
                            resetFoldersSearch();
                            setTimeout(() => {
                                if (createdFolder) {
                                    if (document.getElementById(createdFolder.id)) {
                                        document.getElementById(createdFolder.id).scrollIntoView();
                                    }
                                }
                            }, 150);
                        } else if (selectedFolder) {
                            let createdFolder = await createAndMoveFolder(folderSearchInput, selectedFolder);
                            closeModal();
                            resetFoldersSearch();
                            setTimeout(() => {
                                if (createdFolder) {
                                    if (document.getElementById(createdFolder.id)) {
                                        document.getElementById(createdFolder.id).scrollIntoView();
                                    }
                                }
                            }, 150);
                        }
                    }}
                >
                    + Create new {folderSearchInput}
                </SearchFolder>
            );
        }
    };

    // if (findFolderLoading) return null;

    if (findFolderData) {
        // console.log('all of them', findFolderData);
    }

    const showFoldersList = () => {
        if (!findFolderData?.findFolders?.folders) {
            return [];
        }

        const folders = findFolderData.findFolders.folders;

        // For move actions, sort by LAST_MOVED_AT locally
        if (action === searchActions.MOVE_FOLDER || action === searchActions.MOVE_MESSAGES) {
            const sortedFolders = [...folders].sort((a, b) => {
                // If both have LAST_MOVED_AT, compare them
                if (a[DateField.LAST_MOVED_AT] && b[DateField.LAST_MOVED_AT]) {
                    return new Date(b[DateField.LAST_MOVED_AT]) - new Date(a[DateField.LAST_MOVED_AT]);
                }
                // If only one has LAST_MOVED_AT, it should come first
                if (a[DateField.LAST_MOVED_AT]) return -1;
                if (b[DateField.LAST_MOVED_AT]) return 1;
                // If neither has LAST_MOVED_AT, maintain server's sort order
                return 0;
            });
            return sortedFolders;
        }

        // For JUMP action, use server's sort order
        return folders;
    };

    const onCloseModal = () => {
        closeModal(undefined);
        // cleanup
        resetFoldersSearch();
    };

    const moveMessagesTo = toFolder => {
        // console.log('move me', toFolder, dropdownMessage);
        if (dropdownMessage) {
            moveMessages([dropdownMessage], toFolder, selectedFolder);
            customAnalytics.message_edit({
                "action": "Move",
                "destination": toFolder && toFolder.title === "Inbox" ? "Inbox" : toFolder && toFolder.parent ? "Subfolder" : "HomeFolder",
                "from": selectedFolder.title === "Inbox" ? "Inbox" : selectedFolder.parent ? "Subfolder" : "HomeFolder"
            }, dropdownMessage)
        } else if (messagesToSend.length > 0) {
            moveMessages(messagesToSend, toFolder, selectedFolder);
            messagesToSend.forEach(message => {
                customAnalytics.message_edit({
                    "action": "Move",
                    'multi_select': messagesToSend && messagesToSend.length,
                    "destination": toFolder && toFolder.title === "Inbox" ? "Inbox" : toFolder && toFolder.parent ? "Subfolder" : "HomeFolder",
                    "from": selectedFolder.title === "Inbox" ? "Inbox" : selectedFolder.parent ? "Subfolder" : "HomeFolder"
                }, message)
            });
        } else if (selectedMessage) {
            moveMessages([selectedMessage], toFolder, selectedFolder);
            customAnalytics.message_edit({
                "action": "Move",
                "destination": toFolder && toFolder.title === "Inbox" ? "Inbox" : toFolder && toFolder.parent ? "Subfolder" : "HomeFolder",
                "from": selectedFolder.title === "Inbox" ? "Inbox" : selectedFolder.parent ? "Subfolder" : "HomeFolder"
            }, selectedMessage)
        }
        removeSelectedMessages();
        // console.log('moveMessagesTo', toFolder, selectedFolder)
        // console.log('messagesToSend', messagesToSend, messagesToSend && messagesToSend.length)
    };

    const renderFooter = () => {
        if (loadMoreCheck) {
            if (findFolderData && findFolderData.findFolders.folders.length >= 10) {
                return (
                    <div
                        style={{
                            // justifyContent: 'center',
                            alignItems: 'center',
                            marginVertical: 16,
                            color: PALE_WHITE_6
                        }}
                    >
                        Loading...
                    </div>
                );
            }
        }
    };

    const loadContent = () => {
        if (findFolderLoading) {
            return <LoadingText>Loading...</LoadingText>;
        } else {
            return (
                <>
                    {renderAddNew()}
                    {renderAddNewMoveMessage()}
                    <SelectableList
                        currentItem={currentFolder ? currentFolder : selectedFolder}
                        items={showFoldersList()}
                        selectedItem={value => {
                            resetFoldersSearch();
                            if (action === searchActions.JUMP) {
                                closeModal(value);
                            } else if (action === searchActions.MOVE_FOLDER) {
                                // function to move message to selected folder
                                closeModal(value);
                            } else {
                                moveMessagesTo(value);
                                closeModal();
                            }
                        }}
                        loadMore={loadMore}
                    ></SelectableList>
                    {renderFooter()}
                </>
            );
        }
    };

    const loadMore = () => {
        if (!findFolderData || !findFolderData.findFolders) {
            console.error('No folder data available for loading more');
            setLoadMoreCheck(false);
            return;
        }

        const originalFolders = findFolderData.findFolders.folders;
        if (!originalFolders || !Array.isArray(originalFolders)) {
            console.error('Invalid folders data structure');
            setLoadMoreCheck(false);
            return;
        }

        loadMoreFilteredFolders(
            folderSearchInput,
            originalFolders,
            fetchMore,
            {
                loadingMore: loadingOlder,
                setLoadingOlder,
                setLoadMoreCheck
            },
            folderSearchInput.length === 0 ? HISTORY_SORT_FIELD : DEFAULT_SORT_FIELD
        );
    };

    if (findFolderData && findFolderData.folders) {
        console.log('folderssss', findFolderData.findFolders.folders);
    }

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            style={customStyles}
            // className="Modal"
            overlayClassName="Overlay"
            contentLabel="Example Modal"
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white'
                }}
            >
                <div style={{ display: 'flex' }}>
                    <div style={{ color: 'rgba(255,255,255,.6)', fontWeight: 500, fontSize: 16, flex: 1 }}>{title}</div>
                    {renderMoveToRoot()}
                    {renderMoveToInbox()}
                </div>
                <div
                    style={{
                        backgroundColor: 'rgba(255,255,255,.1)',
                        padding: 10,
                        borderRadius: 12,
                        marginTop: 16,
                        marginBottom: 8,
                        color: 'white'
                    }}
                >
                    <input
                        ref={inputRef}
                        style={{
                            color: 'rgba(255,255,255,.8)',
                            backgroundColor: 'transparent',
                            border: 0,
                            fontSize: 18,
                            color: 'white',
                            width: '100%'
                        }}
                        autoFocus
                        onKeyDown={e => {
                            if (e.keyCode === 40 || e.keyCode === 38) {
                                e.preventDefault();
                            }
                        }}
                        value={folderSearchInput}
                        onChange={e => handleFolderSearchChange(e.target.value)}
                        type="text"
                        placeholder="Search"
                    ></input>
                </div>

                <div
                    style={{
                        display: 'flex',
                        height: 400,
                        flexDirection: 'column',
                        overflowY: 'auto'
                    }}
                >
                    {loadContent()}
                </div>
                {/* {loadMoreCheck ? <LoadMore onClick={() => loadMore()}>Load more</LoadMore> : null} */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ textAlign: 'center', color: 'rgba(255, 255, 255, 0.4)' }}>
                        <small>Use up down keys and hit enter to select, or use the mouse</small>
                    </span>
                </div>
            </div>
        </Modal>
    );
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        color: 'black',
        borderRadius: 10,
        border: 0,
        // borderColor: 'red',
        // maxWidth: 640,
        width: 600,
        // minHeight: '80%',
        maxHeight: '80%',
        backgroundColor: '#222729'
        // overflow: 'none'
    }
};

const LoadMore = styled.div`
    cursor: pointer;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 4px;
    margin-bottom: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    &:hover {
        color: rgba(255, 255, 255, 0.8);
    }
`;

const SearchFolder = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    margin-top: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    line-height: 14px;
    &:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }
`;

const RootFolder = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 8px;
    border: 1px solid ${PALE_WHITE_2};
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 14px;
    color: ${PALE_WHITE_6};
    &:hover {
        background-color: ${PALE_WHITE};
        color: ${WHITE};
    }
`;

const LoadingText = styled.div`
    display: flex;
    // justify-content: center;
    color: ${PALE_WHITE_6};
    margin-top: 16px;
`;

export default SearchFoldersModal;
