import { gql } from 'apollo-boost';

export const NEW_MESSAGE_SUBSCRIPTION = gql`
    subscription($folderId: String) {
        newMessage(folderId: $folderId) {
            id
            title
            body
            createdAt
            updatedAt
            deletedAt
            image {
                url
                name
                displayName
                type
                format
                size
                width
                aspect_ratio
                public_id
            }
            file {
                url
                name
                displayName
                type
                format
                size
                mime
            }
            isCheckbox
            isChecked
            isStarred
            folder {
                id
                title
                owner
                parent
                deletedAt
            }
            urlMeta {
                author
                date
                description
                image
                logo
                publisher
                title
                url
                public_id
                width
                format
                aspect_ratio
                size
                embed {
                    html
                    script {
                        src
                        async
                        charset
                    }
                }
            }
        }
    }
`;
