import React, { useState } from 'react';
import styled from 'styled-components';
import { QUERY, MUTATION } from '../../gql-operations';

import { useMutation } from '@apollo/react-hooks';
import { cloudinaryCore } from '../../util/cloudinary';

import SearchFoldersModal from '../Modals/SearchFoldersModal';

// import useMoveMessagesMutation from '../../hooks/useMoveMessagesMutation';
import useUpdateCheckboxes from '../../hooks/useUpdateCheckboxes';
import useUpdateStarred from '../../hooks/useUpdateStarred';

import { AuthContext } from '../../util/context';
import useCheckBrowser from '../../util/useCheckBrowser';

import Hotkeys from 'react-hot-keys';
import {
    PALE_WHITE_2,
    PALE_WHITE_4,
    PALE_WHITE_8,
    PRIMARY_COLOR,
    WHITE,
    STARRED,
    GREEN
} from '../../appearance/Colors';

import { IoMdCopy, IoIosStar, IoMdArrowForward } from 'react-icons/io';
import { FaPlusCircle, FaRegTimesCircle } from 'react-icons/fa';
import customAnalytics from '../../util/custom-analytics';

function MessageOptions({ newMessage, onSuccess, style, containerWidth }) {
    const {
        state: { selectedFolder },
        context: { setSelectedFolder }
    } = React.useContext(AuthContext);

    const { updateCheckboxes, spliceFromCheckboxes } = useUpdateCheckboxes();
    const { updateStarred, spliceStarred } = useUpdateStarred();
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const [starMessage] = useMutation(MUTATION.STAR_MESSAGE, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    "action": "Star"
                }, newMessage);
            }
        }
    });
    const [unstarMessage] = useMutation(MUTATION.UNSTAR_MESSAGE, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    "action": "Unstar"
                }, newMessage);
            }
        }
    });

    // for check messages, it's questionable do we need the refetch
    // but I think we do because you change the status. lets see
    const [checkMessage] = useMutation(MUTATION.CHECK_MESSAGE);
    const [uncheckMessage] = useMutation(MUTATION.UNCHECK_MESSAGE);

    const [addCheckbox] = useMutation(MUTATION.ADD_CHECKBOX, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    'action': "Task"
                }, newMessage);
            }
        }
    });

    const [removeCheckbox] = useMutation(MUTATION.REMOVE_CHECKBOX, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    'action': "Unask"
                }, newMessage);
            }
        }
    });

    const { Platform, PLATFORMS } = useCheckBrowser();

    // const [ignored, forceUpdate] = React.useReducer(x => x + 1, 0);

    // React.useEffect(() => {
    //     // just so the titleRef can rerender
    //     if (containerWidth.current && containerWidth.current.offsetWidth) {
    //         console.log('cod2');
    //     }
    //     forceUpdate();
    // }, [containerWidth]);

    const renderCheckboxButtons = newMessage => {
        if (!newMessage.isCheckbox) {
            return (
                <Button
                    style={{ ...style }}
                    onClick={() => {
                        addCheckbox({
                            variables: {
                                data: {
                                    messageId: newMessage.id
                                }
                            },
                            update: async (store, { data }) => {
                                updateCheckboxes([newMessage]);
                            },
                            refetchQueries: [
                                {
                                    query: QUERY.GET_MESSAGE,
                                    variables: {
                                        data: {
                                            id: newMessage.id
                                        }
                                    }
                                }
                            ]
                        });
                    }}
                >
                    <div>
                        <FaPlusCircle size={18} color={PALE_WHITE_8}></FaPlusCircle>
                    </div>
                    <ButtonText>Add Task</ButtonText>
                </Button>
            );
        } else {
            return (
                <React.Fragment>
                    <Button
                        style={{ ...style }}
                        onClick={() => {
                            removeCheckbox({
                                variables: {
                                    data: {
                                        messageId: newMessage.id
                                    }
                                },
                                update: async (store, { data }) => {
                                    spliceFromCheckboxes([newMessage]);
                                },
                                refetchQueries: [
                                    {
                                        query: QUERY.GET_MESSAGE,
                                        variables: {
                                            data: {
                                                id: newMessage.id
                                            }
                                        }
                                    }
                                ]
                            });
                        }}
                    >
                        <div>
                            <FaRegTimesCircle size={18} color={PALE_WHITE_8}></FaRegTimesCircle>
                        </div>
                        <ButtonText>Remove Task</ButtonText>
                    </Button>
                    {/* <Button
                    style={{...style}}
                        onClick={() => {
                            if (!newMessage.isChecked) {
                                checkMessage();
                            } else {
                                uncheckMessage();
                            }
                        }}
                    >
                        <span>{newMessage.isChecked ? 'Uncheck' : 'Mark as Checked'}</span>
                    </Button> */}
                </React.Fragment>
            );
        }
    };

    const renderStarredButtons = newMessage => {
        return (
            <Button
                style={({ ...style }, newMessage.isStarred ? { backgroundColor: STARRED } : null)}
                onClick={() => {
                    if (!newMessage.isStarred) {
                        starMessage({
                            variables: {
                                data: {
                                    messageId: newMessage.id
                                }
                            },
                            update: async (store, { data }) => {
                                updateStarred([newMessage]);
                            },
                            refetchQueries: [
                                {
                                    query: QUERY.GET_MESSAGE,
                                    variables: {
                                        data: {
                                            id: newMessage.id
                                        }
                                    }
                                }
                            ]
                        });
                    } else {
                        unstarMessage({
                            variables: {
                                data: {
                                    messageId: newMessage.id
                                }
                            },
                            update: async (store, { data }) => {
                                spliceStarred([newMessage]);
                            },
                            refetchQueries: [
                                {
                                    query: QUERY.GET_MESSAGE,
                                    variables: {
                                        data: {
                                            id: newMessage.id
                                        }
                                    }
                                }
                            ]
                        });
                    }
                }}
            >
                {!newMessage.isStarred ? (
                    <>
                        <div>
                            <IoIosStar size={18} color={PALE_WHITE_8} />
                        </div>
                        <ButtonText style={{ color: PALE_WHITE_8 }}>Star</ButtonText>
                    </>
                ) : (
                    <>
                        <div>
                            <IoIosStar size={18} style={{ color: PRIMARY_COLOR }} />
                        </div>
                        <ButtonText style={{ color: PRIMARY_COLOR }}>Unstar</ButtonText>
                    </>
                )}
            </Button>
        );
    };

    // Move messages
    const [showFoldersModal, setShowFoldersModal] = useState(false);
    // const [moveMessages] = useMoveMessagesMutation();

    const onMoveMessages = () => {
        setShowFoldersModal(true);
        // analytics are in the modal
    };

    const renderMoveToButton = () => {
        return (
            <>
                <Button onClick={onMoveMessages} style={{ ...style }}>
                    <div style={{ position: 'absolute', top: 4, right: 4, color: PALE_WHITE_8, fontSize: 12 }}>{`${Platform === PLATFORMS.MACINTOSH ? '⌘J' : 'Ctr+J'
                        }`}</div>
                    <IoMdArrowForward size={18} color={PALE_WHITE_8} />
                    <ButtonText>Move to</ButtonText>
                </Button>
                <SearchFoldersModal
                    isOpen={showFoldersModal}
                    title={`Move message to`}
                    addNewMoveMessage
                    closeModal={() => {
                        setShowFoldersModal(false);
                        // onSuccess(true);
                    }}
                ></SearchFoldersModal>
            </>
        );
    };

    const copyToClipboard = async newMessage => {
        let list = '';

        if (newMessage.title.length > 0) {
            list = list + newMessage.title + `\n`;
        }
        if (newMessage.image) {
            const originalSize = cloudinaryCore.url(newMessage.image.url, { quality: 'auto', fetchFormat: 'auto' });
            list = list + originalSize + `\n`;
        }
        if (newMessage.file) {
            const url = newMessage.file.url;
            list = list + url + `\n`;
        }

        await navigator.clipboard.writeText(list);
        setCopiedToClipboard(true);
        setTimeout(() => {
            setCopiedToClipboard(false);
        }, 1500);
        customAnalytics.message_action({
            "action": "Copy",
            "type": "Title"
        }, newMessage);

    };

    const renderCopyButton = newMessage => {
        return (
            <Button
                onClick={() => copyToClipboard(newMessage)}
                style={({ ...style }, copiedToClipboard ? { backgroundColor: GREEN } : null)}
            >
                <IoMdCopy size={18} color={PALE_WHITE_8} />
                <ButtonText>{copiedToClipboard ? 'Copied' : 'Copy'}</ButtonText>
            </Button>
        );
    };

    // const renderGoToFolderButton = () => {
    //     if (newMessage.folder) {
    //         if (newMessage.folder.id !== selectedFolder.id)
    //             return (
    //                 <Button onClick={() => setSelectedFolder(newMessage.folder)}>
    //                     Go to {newMessage.folder.title}
    //                 </Button>
    //             );
    //     } else {
    //         return <div>This folder was deleted</div>;
    //     }
    // };

    return (
        <React.Fragment>
            <Hotkeys
                keyName="command+J, ctrl+J"
                onKeyDown={(keyName, e, handle) => {
                    e.preventDefault();
                    // console.log('working', e);
                    // console.log('working key', keyName);
                    // console.log('working handle', handle)
                    // setShowFoldersSearch(true);
                    if (keyName === 'command+J' || keyName === 'ctrl+J') {
                        // closeMessage();
                        // setShowFoldersSearch(true);
                        e.preventDefault();
                        onMoveMessages();
                        customAnalytics.ui_action({
                            "action": "Message Move",
                            "shortcut_key": keyName,
                            "screen": "MessageScreen"
                        })
                    }
                    // if (keyName === 'command+P') {
                    //     e.preventDefault();
                    // }
                }}
                filter={event => {
                    return true;
                }}
            >
                {containerWidth.current && containerWidth.current.offsetWidth < 300 ? (
                    <div style={{ marginBottom: 8 }}>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {renderMoveToButton()}
                            <div style={{ width: 8 }}></div>
                            {renderCheckboxButtons(newMessage)}
                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            {renderStarredButtons(newMessage)}
                            <div style={{ width: 8 }}></div>
                            {renderCopyButton(newMessage)}
                        </div>
                    </div>
                ) : (
                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                        {renderMoveToButton()}
                        <div style={{ width: 8 }}></div>
                        {renderCheckboxButtons(newMessage)}
                        <div style={{ width: 8 }}></div>
                        {renderStarredButtons(newMessage)}
                        <div style={{ width: 8 }}></div>
                        {renderCopyButton(newMessage)}
                    </div>
                )}
                {/* {renderGoToFolderButton()} */}
            </Hotkeys>
        </React.Fragment>
    );
}

function DeleteMessageButton({ deleteAll }) {
    return (
        <div
            style={{
                cursor: 'pointer',
                padding: 8,
                borderRadius: 8,
                color: 'rgba(255,255,255,.4)',
                justifyContent: 'center',
                display: 'flex',
                marginBottom: 8
            }}
            onClick={deleteAll}
        >
            <div>Delete message</div>
        </div>
    );
}

const Button = styled.div`
    border-radius: 8px;
    background-color: ${PALE_WHITE_2};
    justify-content: center;
    display: flex;
    position: relative;
    color: white;
    align-items: center;
    padding: 8px 2px;
    // color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    flex-direction: column;
    // width: 80px;
    flex: 1;
    height: 60px;
    &:hover {
        background-color: ${PALE_WHITE_4};
    }
    min-width: 50px;
    // flex-wrap: wrap;
`;

const ButtonText = styled.div`
    color: ${PALE_WHITE_8};
    font-size: 12px;
    text-align: center;
    margin-top: 4px;
`;

export { MessageOptions, DeleteMessageButton };
