import React, { useState } from 'react';

import Modal from './Modal';
import { PRIMARY_COLOR, PRIMARY_COLOR_4, GREEN, PRIMARY_COLOR_2 } from '../../appearance/Colors';

import styled from 'styled-components';

import getImagePreviews from '../../util/getImagePreviews';

import ActionTag from '../../components/Action/ActionTagButton';

import useUpdateUserMutation from '../../hooks/useUpdateUserMutation';

function BackgroundPreviewModal({ title, description, closeTitle, deleteTitle, isOpen, closeModal, imagePreview }) {
    const [imagesPreview, setImagesPreview] = useState(undefined);

    const { updateBackgroundPicture } = useUpdateUserMutation();

    const inputFile = React.useRef(null);

    const afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        // this.subtitle.style.color = '#f00';
    };

    const onChangeFile = async event => {
        event.stopPropagation();
        event.preventDefault();
        const fileListAsArray = Array.from(event.target.files);
        getPreviews(fileListAsArray);
        // this.setState({file}); /// if you want to upload latter
    };

    const getPreviews = async files => {
        let res = await getImagePreviews(files);
        if (res) {
            imagePreview(res);
            setImagesPreview(res);
        }
    };

    const clearFileToSend = () => {
        setImagesPreview(undefined);
        // inputFile.current.value = '';
    };

    const onButtonClick = () => {
        inputFile.current.click();
    };

    const publishFiles = async () => {
        console.log('whats wont', imagesPreview);
        let filesToSend = imagesPreview.map(previewItem => previewItem.file);
        let imageFiles = [];
        // let ordinaryFiles = [];
        filesToSend.forEach(file => {
            if (file.type.includes('image')) {
                imageFiles.push(file);
            }
        });
        // somewhere here the successful upload
        clearFileToSend();
        if (imageFiles.length) {
            const onlyFirst = imageFiles[0];
            console.log('what', onlyFirst);
            closeModal();
            await updateBackgroundPicture(onlyFirst);
        }
    };

    if (title) {
        return (
            <Modal
                isOpen={isOpen}
                onAfterOpen={afterOpenModal}
                // onRequestClose={() => closeModal(false)}
                style={deleteModalStyle}
                // className="Modal"
                overlayClassName="OverlayWithoutBackground"
                contentLabel="Example Modal"
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ color: PRIMARY_COLOR, fontWeight: 500, fontSize: 16, marginBottom: 8 }}>{title}</div>
                    {/* <span style={{ color: PRIMARY_COLOR, marginBottom: 16 }}>{description}</span> */}
                    <InputSelect
                        style={{
                            backgroundColor: PRIMARY_COLOR_2,
                            borderRadius: 4,
                            marginBottom: 8,
                            padding: 8,
                            cursor: 'pointer'
                        }}
                        onClick={onButtonClick}
                    >
                        {imagesPreview ? imagesPreview[0].file.name : 'Select image'}
                    </InputSelect>
                    <input
                        type="file"
                        accept="image/*"
                        id="file"
                        ref={inputFile}
                        style={{ display: 'none' }}
                        onChange={onChangeFile}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <ActionTag
                            label={closeTitle ? closeTitle : 'Cancel'}
                            style={{ marginRight: 8 }}
                            onClick={() => closeModal(false)}
                        />
                        <ActionTag
                            label={deleteTitle ? deleteTitle : 'Publish'}
                            style={{ backgroundColor: GREEN, marginRight: 8 }}
                            onClick={() => publishFiles()}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
    return null;
}

const deleteModalStyle = {
    content: {
        top: 50,
        left: 10,
        right: 'auto',
        bottom: 'auto',
        // marginRight: '-50%',
        // transform: 'translate(-50%, -50%)',
        width: 280,
        color: 'black',
        borderRadius: 0,
        // maxWidth: 320,
        border: 'none',
        borderRadius: 4,
        padding: 16
    }
};

const InputSelect = styled.div`
    background-color: ${PRIMARY_COLOR_2};
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 8px;
    cursor: pointer;

    &:hover {
        background-color: ${PRIMARY_COLOR_4};
    }
`;

export default BackgroundPreviewModal;
