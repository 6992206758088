import React, { useState, useRef, useEffect } from 'react';
import { prettySize } from '../../../util/fileSizeCalculator';

import FileIcon from '../../../appearance/FileIcon';
import ImageIcon from '../../../appearance/ImageIcon';

import { imageExists } from '../../../util/imageExists';

import styled from 'styled-components';

import { SECONDARY_COLOR, PRIMARY_COLOR, NOTE_BACKGROUND } from '../../../appearance/Colors';

import CarouselModal from '../../../components/Modals/CarouselModal';

import useDownloadFile from '../../../hooks/useDownloadFile';

function FileSection({ file }) {
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const wrapperRef = useRef(null);
    const resizeObserverRef = useRef(null);
    const { url, name, displayName, size, type, format } = file;
    let maxTypeChar = 28;

    const downloadFile = useDownloadFile();

    // Setup ResizeObserver
    useEffect(() => {
        let rafId = null;
        let isObserving = true;
        
        const checkWidth = () => {
            if (!isObserving) return;
            if (wrapperRef.current) {
                const width = wrapperRef.current.getBoundingClientRect().width;
                setIsSmallScreen(width < 400);
            }
        };

        const throttledCheckWidth = () => {
            if (rafId || !isObserving) return;
            
            rafId = window.requestAnimationFrame(() => {
                checkWidth();
                rafId = null;
            });
        };

        resizeObserverRef.current = new ResizeObserver(throttledCheckWidth);
        
        if (wrapperRef.current) {
            resizeObserverRef.current.observe(wrapperRef.current);
            checkWidth();
        }

        // Listen for drag start/end
        const handleDragStart = () => {
            isObserving = false;
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
        };

        const handleDragEnd = () => {
            isObserving = true;
            if (resizeObserverRef.current && wrapperRef.current) {
                resizeObserverRef.current.observe(wrapperRef.current);
                checkWidth();
            }
        };

        document.addEventListener('dragstart', handleDragStart);
        document.addEventListener('dragend', handleDragEnd);

        return () => {
            isObserving = false;
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
            if (rafId) {
                window.cancelAnimationFrame(rafId);
            }
            document.removeEventListener('dragstart', handleDragStart);
            document.removeEventListener('dragend', handleDragEnd);
        };
    }, []);

    const getImageWidth = () => {
        if (!wrapperRef.current) return 200;
        
        const containerWidth = wrapperRef.current.getBoundingClientRect().width;
        let maxWidth = 200; // Default width
        
        // Only resize if container is too small
        if (isSmallScreen && containerWidth < maxWidth + 32) {
            maxWidth = containerWidth - 16;
        }
        
        return maxWidth;
    };

    const renderCarousel = item => {
        return (
            <CarouselModal
                isOpen={lightboxIsOpen}
                closeModal={e => {
                    e.stopPropagation();
                    setLightboxIsOpen(false);
                }}
                items={[item]}
                fileType="File"
            />
        );
    };

    const fileName = () => {
        let title;
        if (displayName) {
            title = displayName;
        } else if (name) {
            title = name;
        } else {
            // in case that we haven't saved the name of the file
            title = url.substr(url.lastIndexOf('/') + 1); // Add assignment to title
        }
        return title;
    };

    const renderFileType = (url, type, format) => {
        if (type.includes('image')) {
            if (!type.includes('image/heic') || (format && !format.includes('heic'))) {
                return (
                    <ImageContainer
                        isSmallScreen={isSmallScreen}
                        onClick={e => {
                            e.stopPropagation();
                            setLightboxIsOpen(true);
                        }}
                    >
                        <img
                            src={url}
                            style={{ 
                                width: getImageWidth(),
                                objectFit: 'cover',
                                borderRadius: 8,
                                cursor: 'zoom-in'
                            }}
                        />
                    </ImageContainer>
                );
            } else {
                return (
                    <div style={{ marginRight: 4 }}>
                        <ImageIcon />
                    </div>
                );
            }
        } else {
            return (
                <div style={{ marginRight: 4 }}>
                    <FileIcon />
                </div>
            );
        }
    };

    return (
        <FileWrapper ref={wrapperRef} isSmallScreen={isSmallScreen}>
            <div style={{ marginRight: 8 }}>{renderFileType(url, type, format)}</div>

            <InfoSection isSmallScreen={isSmallScreen}>
                <div style={{ marginBottom: 4, wordBreak: 'break-word', color: PRIMARY_COLOR, fontWeight: 500 }}>
                    {fileName()}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ marginRight: 8, color: SECONDARY_COLOR }}>{size ? prettySize(size) : null}</div>

                    <ALink href={file.url} target="_blank" rel="noopener noreferrer" style={{ marginRight: 8 }}>
                        Open
                    </ALink>
                    <ALink onClick={() => downloadFile(file.name, file.size, file.displayName)} rel="noopener noreferrer">
                        Download
                    </ALink>
                </div>
            </InfoSection>
            {renderCarousel(file)}
        </FileWrapper>
    );
}

const FileWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 8px;
    width: 100%;
    align-items: flex-start;
`;

const ImageContainer = styled.div`
    min-height: 40px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${NOTE_BACKGROUND};
    overflow: hidden;
`;

const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 200px;
    flex: 1;
`;

const ALink = styled.a`
    font-weight: 500 !important;
    color: #2b88d9 !important;
    &:hover {
        color: #0770cc !important;
    }
`;

export default FileSection;
