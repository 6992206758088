import React from 'react'
import { DETAIL_TEXT, GREEN_OP, GREEN_OP_2, GREEN_OP_4, PALE_WHITE, PLACEHOLDER, PRIMARY_COLOR_6, WHITE } from '../../appearance/Colors'
import styled from 'styled-components';
import { format, compareAsc, formatDistance } from 'date-fns';
import ActionTagButton from '../../components/Action/ActionTagButton';

function Purchase({ purchase, activeSubscription, action, ...rest }) {
    const dateFormat = 'dd MMM yyyy, HH:mm';

    const renderPrice = () => {
        return (
            <Price>Price: {purchase.finalPrice / 100} <span style={{ textTransform: 'uppercase' }}>{purchase.plan.currency}</span></Price >
        )
    }

    const renderDaysLeft = () => {
        return (
            <>{formatDistance(new Date(purchase.currentPeriodEnd), new Date())}</>
        )
    }

    const prettifyDate = (date) => {
        return format(new Date(date), dateFormat)
    }

    const renderDate = () => {
        if (purchase.currentPeriodEnd) {
            return (
                <Description>
                    <Info>Days left: {renderDaysLeft()}</Info>
                    <Info>Starts: {prettifyDate(purchase.currentPeriodStart)}</Info>
                    <Info>Ends: {prettifyDate(purchase.currentPeriodEnd)}</Info>
                    {/* <div>Purchased on: {prettifyDate(purchase.purchaseDate)}</div> */}
                </Description>
            )
        } else {
            return (
                <Description>
                    <Info>Purchased on: {prettifyDate(purchase.purchaseDate)}</Info>
                </Description>
            )
        }
    }

    const renderCancel = () => {
        if (activeSubscription) {
            return (
                <ActionTagButton
                    label="Cancel Subscription"
                    onClick={() => {
                        if (action) {
                            action(true)
                        }
                    }}
                ></ActionTagButton>
            )
        }
    }

    const renderQuantity = () => {
        if (!purchase.subscriptionStatus) {
            return <Description>Quantity: {purchase.quantity}</Description>
        }
    }

    const renderStatus = () => {
        if (purchase.subscriptionStatus) {
            return (
                <Status
                    style={{
                        textTransform: "capitalize",
                        backgroundColor: purchase.subscriptionStatus === 'active' ? GREEN_OP : PALE_WHITE
                    }}>
                    {purchase.subscriptionStatus}
                </Status>
            )
        }
    }

    const renderTitle = () => {
        return (
            <div style={{ display: "flex", flexDirection: 'row' }}>
                <Title>{purchase.plan.name}</Title>
                {renderStatus()}
            </div>
        )
    }

    return (
        <div key={purchase.id} {...rest}>
            <BoxStyle>
                {renderTitle()}
                {renderQuantity()}
                {renderDate()}
                {renderPrice()}
                {renderCancel()}
            </BoxStyle>
        </div>
    )
}

const MARGIN_BOTTOM = '4px';

const BoxStyle = styled.div`
    background-color: ${PALE_WHITE};
    border-color: ${PLACEHOLDER};
    border-style: solid;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0,0,0,.07), 0 1px 1.5px 0 rgba(0,0,0,.05);
    // max-width: 380px;
`;

const Title = styled.div`
    color: ${WHITE};
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    flex: 1;
`;

const Description = styled.div`
    color: ${DETAIL_TEXT};
    font-size: 14px;
    margin-bottom: ${MARGIN_BOTTOM};
`;

const Status = styled.div`
    color: ${WHITE};
    font-size: 14px;
    font-weight: 500;
    padding: 6px 8px;
    border-radius: 8px;
`;

const Price = styled.div`
    color: ${DETAIL_TEXT};
    font-size: 14px;
    margin-bottom: ${MARGIN_BOTTOM};
`;

const Info = styled.div`
    margin-bottom: ${MARGIN_BOTTOM};
`


export default Purchase