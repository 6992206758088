import React, { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';

const EmbedSection = memo(({ embed }) => {
    const containerRef = useRef(null);
    const scriptsLoadedRef = useRef(false);

    useEffect(() => {
        if (!containerRef.current || !embed?.html || scriptsLoadedRef.current) return;

        try {
            // Clear previous content
            containerRef.current.innerHTML = '';

            // Add the iframe HTML
            containerRef.current.innerHTML = embed.html;

            // Load script if available and not already loaded
            if (!scriptsLoadedRef.current && embed.script?.src) {
                const scriptElement = document.createElement('script');
                Object.entries(embed.script).forEach(([key, value]) => {
                    if (value !== null && value !== undefined) {
                        scriptElement[key] = value;
                    }
                });
                containerRef.current.appendChild(scriptElement);
                scriptsLoadedRef.current = true;
            }
        } catch (error) {
            console.error('Error initializing embed:', error);
        }
    }, [embed?.html, embed?.script]);

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            scriptsLoadedRef.current = false;
        };
    }, []);

    if (!embed?.html) return null;

    return (
        <EmbedContainer>
            <EmbedContent ref={containerRef} />
        </EmbedContainer>
    );
});

const EmbedContainer = styled.div`
    width: 100%;
    max-width: 500px;
    min-width: 250px;
    border-radius: 8px;
    overflow: hidden;
    background-color: white;
    margin: 0 auto;
    
    iframe {
        border: none;
        width: 100%;
        min-height: 350px;
    }
`;

const EmbedContent = styled.div`
    width: 100%;
    
    iframe {
        border: none;
        width: 100%;
    }
`;

EmbedSection.displayName = 'EmbedSection';

export default EmbedSection;