import React, { useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { PRIMARY_COLOR, SECONDARY_COLOR, SECONDARY_TEXT, PALE_WHITE_2, WHITE, PRIMARY_COLOR_8, DETAIL_TEXT, BLUE_HIGHLIGHT, PRIMARY_COLOR_6, PRIMARY_COLOR_2, LINK } from '../../appearance/Colors';
import LayoutDefault from '../Landing/layouts/LayoutDefault';
import Markdown from 'markdown-to-jsx';
import { BLOG_POSTS, BLOG_META } from '../../data/blogPosts';
import { FaTwitter, FaFacebook, FaEnvelope } from 'react-icons/fa';

// Scroll restoration component
const ScrollToTop = () => {
    const { pathname } = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    
    return null;
};

const BlogPost = () => {
    const { slug } = useParams();
    const { pathname } = useLocation();
    const post = BLOG_POSTS[slug];

    // Scroll to top when pathname (URL) changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    // Ensure absolute URL for social sharing
    const postUrl = `https://luckynote.io/blog/${slug}`;
    
    // Social sharing image with fallbacks
    const socialImageUrl = post?.socialImage
        ? post.socialImage.startsWith('http')
            ? encodeURI(post.socialImage)
            : `https://luckynote.io${post.socialImage}`
        : post?.featuredImage
            ? post.featuredImage.startsWith('http')
                ? encodeURI(post.featuredImage)
                : `https://luckynote.io${post.featuredImage}`
            : `https://luckynote.io${BLOG_META.defaultSocialImage}`;

    useEffect(() => {
        if (post) {
            console.log('Debug Info:', {
                slug,
                postUrl,
                title: post.title,
                socialImageUrl,
                rawSocialImage: post.socialImage,
                rawFeaturedImage: post.featuredImage
            });
        }
    }, [post, slug, postUrl, socialImageUrl]);

    const handleShare = (platform) => {
        console.log(`Sharing to ${platform}:`, {
            url: postUrl,
            title: post.title,
            platform
        });
    };

    if (!post) {
        return (
            <LayoutDefault>
                <BlogContainer>
                    <NotFound>
                        <h1>Post Not Found</h1>
                        <p>The blog post you're looking for doesn't exist.</p>
                        <BackToBlogs to="/blog">← Back to Blog</BackToBlogs>
                    </NotFound>
                </BlogContainer>
            </LayoutDefault>
        );
    }

    return (
        <LayoutDefault>
            <Helmet>
                {/* Basic */}
                <title>{post.title}</title>
                <meta name="description" content={post.excerpt} />
                <meta name="keywords" content={`${post.category},productivity,note-taking,luckynote,${post.tags?.join(',')}`} />
                
                {/* Open Graph */}
                <meta property="og:title" content={post.title} />
                <meta property="og:description" content={post.excerpt} />
                <meta property="og:type" content="article" />
                <meta property="og:url" content={postUrl} />
                <meta property="og:image" content={socialImageUrl} />
                <meta property="og:image:secure_url" content={socialImageUrl} />
                <meta property="og:site_name" content={BLOG_META.siteName} />
                
                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={post.title} />
                <meta name="twitter:description" content={post.excerpt} />
                <meta name="twitter:image" content={socialImageUrl} />
                <meta name="twitter:image:src" content={socialImageUrl} />
                <meta name="twitter:site" content="@luckynoteapp" />
                <meta name="twitter:creator" content="@luckynoteapp" />
                <meta name="twitter:image:alt" content={post.title} />
                
                {/* Additional Open Graph */}
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:locale" content="en_US" />
                <meta property="article:author" content={post.author.name} />
                
                {/* Article Specific */}
                <meta property="article:published_time" content={post.createdAt} />
                <meta property="article:modified_time" content={post.updatedAt} />
                <meta property="article:section" content={post.category} />
                {post.tags?.map(tag => (
                    <meta key={tag} property="article:tag" content={tag} />
                ))}
                
                {/* Schema.org markup */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "BlogPosting",
                        "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": postUrl
                        },
                        "headline": post.title,
                        "description": post.excerpt,
                        "image": socialImageUrl,
                        "author": {
                            "@type": "Organization",
                            "name": BLOG_META.organization.name
                        },
                        "publisher": {
                            "@type": "Organization",
                            "name": BLOG_META.organization.name,
                            "logo": {
                                "@type": "ImageObject",
                                "url": BLOG_META.organization.logo
                            }
                        },
                        "datePublished": post.createdAt,
                        "dateModified": post.updatedAt
                    })}
                </script>
            </Helmet>
            
            <BlogContainer>
                <BackToBlogs to="/blog">← Back to Blog</BackToBlogs>
                
                <article>
                    <BlogHeader>
                        <CategoryTag>{post.category}</CategoryTag>
                        <h1>{post.title}</h1>
                        <BlogMeta>
                            <AuthorInfo>
                                <AuthorAvatar src={post.author.avatar} alt={post.author.name} />
                                By {post.author.name}
                            </AuthorInfo>
                            <PostDate>{new Date(post.createdAt).toLocaleDateString()}</PostDate>
                        </BlogMeta>
                    </BlogHeader>

                    {post.featuredImage && (
                        <FeaturedImage src={post.featuredImage} alt={post.title} />
                    )}

                    <BlogContent>
                        <Markdown
                            options={{
                                overrides: {
                                    h1: {
                                        component: ({ children, ...props }) => (
                                            <h1 {...props}>{children}</h1>
                                        ),
                                        props: {
                                            className: 'blog-heading-h1'
                                        }
                                    },
                                    h2: {
                                        component: ({ children, ...props }) => (
                                            <h2 {...props}>{children}</h2>
                                        ),
                                        props: {
                                            className: 'blog-heading-h2'
                                        }
                                    },
                                    h3: {
                                        component: ({ children, ...props }) => (
                                            <h3 {...props}>{children}</h3>
                                        ),
                                        props: {
                                            className: 'blog-heading-h3'
                                        }
                                    },
                                    p: {
                                        component: ({ children, ...props }) => {
                                            if (typeof children === 'string') {
                                                // Handle colon-separated lists
                                                if (children.includes(':') && children.includes('-')) {
                                                    const [intro, listPart] = children.split(':');
                                                    if (listPart) {
                                                        const items = listPart.split('-').filter(item => item.trim());
                                                        return (
                                                            <>
                                                                <p className="blog-paragraph">{intro}:</p>
                                                                <ul className="blog-list">
                                                                    {items.map((item, index) => (
                                                                        <li key={index} className="blog-list-item">{item.trim()}</li>
                                                                    ))}
                                                                </ul>
                                                            </>
                                                        );
                                                    }
                                                }
                                                
                                                // Handle regular inline lists
                                                if (children.startsWith('- ') || /\n-\s/.test(children)) {
                                                    const items = children.split(/\n?-\s+/).filter(item => item.trim());
                                                    return (
                                                        <ul className="blog-list">
                                                            {items.map((item, index) => (
                                                                <li key={index} className="blog-list-item">{item.trim()}</li>
                                                            ))}
                                                        </ul>
                                                    );
                                                }
                                            }
                                            return <p {...props} className="blog-paragraph">{children}</p>;
                                        }
                                    },
                                    ul: {
                                        component: ({ children, ...props }) => (
                                            <ul {...props}>{children}</ul>
                                        ),
                                        props: {
                                            className: 'blog-list'
                                        }
                                    },
                                    li: {
                                        component: ({ children, ...props }) => (
                                            <li {...props}>{children}</li>
                                        ),
                                        props: {
                                            className: 'blog-list-item'
                                        }
                                    },
                                    table: {
                                        component: ({ children, ...props }) => (
                                            <div className="table-container">
                                                <table {...props}>{children}</table>
                                            </div>
                                        ),
                                        props: {
                                            className: 'blog-table'
                                        }
                                    },
                                    tr: {
                                        component: props => <tr {...props} />,
                                        props: {
                                            className: 'blog-table-row'
                                        }
                                    },
                                    th: {
                                        component: props => <th {...props} />,
                                        props: {
                                            className: 'blog-table-header'
                                        }
                                    },
                                    td: {
                                        component: props => <td {...props} />,
                                        props: {
                                            className: 'blog-table-cell'
                                        }
                                    },
                                    a: {
                                        component: ({ children, ...props }) => (
                                            <a {...props} target="_blank" rel="noopener noreferrer">{children}</a>
                                        ),
                                        props: {
                                            className: 'blog-link'
                                        }
                                    },
                                    blockquote: {
                                        component: ({ children, ...props }) => (
                                            <blockquote {...props}>{children}</blockquote>
                                        ),
                                        props: {
                                            className: 'blog-blockquote'
                                        }
                                    }
                                }
                            }}
                        >
                            {post.content}
                        </Markdown>
                    </BlogContent>

                    <SocialShare>
                        <ShareText>Share on:</ShareText>
                        <ShareButtons>
                            <ShareButton 
                                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`${post.title}\n\n${postUrl}`)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Share on Twitter"
                                onClick={() => handleShare('Twitter')}
                            >
                                <FaTwitter size={26} />
                            </ShareButton>
                            <ShareButton 
                                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(postUrl)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Share on Facebook"
                                onClick={() => handleShare('Facebook')}
                            >
                                <FaFacebook size={26} />
                            </ShareButton>
                            <ShareButton 
                                href={`mailto:?subject=${encodeURIComponent(`${post.title} - ${BLOG_META.siteName}`)}&body=${encodeURIComponent(`Check out this article: ${postUrl}`)}`}
                                aria-label="Share via Email"
                                onClick={() => handleShare('Email')}
                            >
                                <FaEnvelope size={26} />
                            </ShareButton>
                        </ShareButtons>
                    </SocialShare>

                    <AuthorCard>
                        <AuthorImage src={post.author.avatar} alt={post.author.name} />
                        <AuthorInfoContainer>
                            <AuthorLabel>Article by</AuthorLabel>
                            <AuthorName>{post.author.name}</AuthorName>
                            <AuthorBio>
                                As the founder of Luckynote, {post.author.name} is happy helping people organize their thoughts and improve their well-being. He believes in asking meaningful questions and finding elegant solutions to life's complexities.
                                <AuthorSocial>
                                    <SocialLink 
                                        href="https://x.com/luckyursus" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        aria-label="Follow on Twitter/X"
                                    >
                                        <FaTwitter size={18} />
                                    </SocialLink>
                                </AuthorSocial>
                            </AuthorBio>
                        </AuthorInfoContainer>
                    </AuthorCard>

                    {post.tags && (
                        <TagsSection>
                            {post.tags.map(tag => (
                                <Tag key={tag}>#{tag}</Tag>
                            ))}
                        </TagsSection>
                    )}
                </article>
            </BlogContainer>
        </LayoutDefault>
    );
};

const BlogContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 120px 20px 40px;
`;

const BackToBlogs = styled(Link)`
    display: inline-block;
    color: ${LINK} !important;
    text-decoration: none;
    margin-bottom: 40px;
    
    &:hover {
        opacity: 0.8;
    }
`;

const BlogHeader = styled.header`
    margin-bottom: 40px;
    text-align: center;

    h1 {
        color: ${SECONDARY_COLOR};
        margin: 20px 0;
        font-size: 2.5rem;
        line-height: 1.2;
    }
`;

const BlogMeta = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    color: ${SECONDARY_COLOR};
    font-size: 0.9rem;
`;

const AuthorInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const AuthorAvatar = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 24px;
    object-fit: cover;
`;

const PostDate = styled.span`
    color: ${SECONDARY_COLOR};
`;

const CategoryTag = styled.span`
    background: ${PRIMARY_COLOR_8};
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
    color: ${WHITE};
`;

const FeaturedImage = styled.img`
    width: 100%;
    max-height: 400px;
    object-fit: cover;
    border-radius: 12px;
    margin-bottom: 40px;
`;

const BlogContent = styled.div`
    color: ${SECONDARY_COLOR};
    line-height: 1.8;
    font-size: 1.1rem;

    h1, h2, h3, h4 {
        color: ${SECONDARY_COLOR};
        margin: 40px 0 20px;
    }

    h1 { font-size: 2.2rem; }
    h2 { font-size: 1.8rem; }
    h3 { font-size: 1.5rem; }
    h4 { font-size: 1.3rem; }

    p {
        margin-bottom: 24px;
        color: ${SECONDARY_COLOR};
        font-size: 1.1rem;
    }

    /* Nested list styles */
    li {
        margin-bottom: 12px !important;
        color: ${SECONDARY_COLOR} !important;
        display: list-item !important;
        font-size: 1.1rem;
    }

    /* First level list items with strong titles */
    li > p {
        margin: 0 0 12px 0;
        font-size: 1.1rem;
    }

    li > p > strong {
        display: block;
        margin: 24px 0 16px 0;
        font-size: 1.2rem;
        color: ${SECONDARY_COLOR};
    }

    /* Nested lists */
    li > ul, li > ol {
        margin: 12px 0 24px 20px !important;
        padding-left: 20px !important;
    }

    /* Nested list items */
    li > ul > li, li > ol > li {
        margin-bottom: 8px !important;
        font-size: 1.1rem;
    }

    /* Base list styles */
    ul, ol {
        margin: 24px 0 !important;
        padding-left: 20px !important;
        color: ${SECONDARY_COLOR} !important;
        list-style-type: initial !important;
    }

    a {
        color: ${LINK} !important;
        text-decoration: underline !important;
        
        &:hover {
            opacity: 0.8;
        }
    }

    blockquote {
        border-left: 4px solid ${PALE_WHITE_2};
        padding-left: 20px;
        margin: 32px 0;
        font-style: italic;
        color: ${SECONDARY_COLOR};
    }

    code {
        background: ${PRIMARY_COLOR};
        padding: 2px 6px;
        border-radius: 4px;
        font-family: monospace;
        color: ${WHITE};
    }

    pre {
        background: ${PRIMARY_COLOR};
        padding: 20px;
        border-radius: 8px;
        overflow-x: auto;
        margin: 32px 0;

        code {
            background: none;
            padding: 0;
        }
    }

    /* Table styles */
    table {
        width: 100%;
        margin: 24px 0 32px;
        border-collapse: collapse;
        background: ${PRIMARY_COLOR_2};
        border-radius: 8px;
        overflow: hidden;
    }

    th {
        background: ${PRIMARY_COLOR_6};
        color: ${WHITE};
        font-weight: 600;
        text-align: left;
        padding: 12px 16px;
        border-bottom: 2px solid ${PALE_WHITE_2};
    }

    td {
        padding: 12px 16px;
        border-bottom: 1px solid ${PALE_WHITE_2};
        color: ${SECONDARY_COLOR};
    }

    tr:last-child td {
        border-bottom: none;
    }

    // tr:hover {
    //     background: ${PRIMARY_COLOR_2};
    // }

    /* Blockquote with note */
    blockquote:has(strong:first-child) {
        background: ${PRIMARY_COLOR_2};
        border-left: 4px solid ${PRIMARY_COLOR_8};
        border-radius: 4px;
        padding: 16px 20px;
        margin: 32px 0;
        font-style: normal;
    }
`;

const SocialShare = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 40px 0;
    padding: 20px 0;
    border-top: 1px solid ${PALE_WHITE_2};
    border-bottom: 1px solid ${PALE_WHITE_2};
`;

const ShareText = styled.div`
    color: ${SECONDARY_COLOR};
    font-size: 1.1rem;
`;

const ShareButtons = styled.div`
    display: flex;
    gap: 15px;
`;

const ShareButton = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 54px;
    height: 54px;
    background: ${PRIMARY_COLOR};
    border: 1px solid ${PALE_WHITE_2};
    border-radius: 12px;
    color: ${SECONDARY_COLOR};
    transition: all 0.2s ease;

    &:hover {
        background: ${PRIMARY_COLOR_6};
        transform: translateY(-2px);
    }
`;

const AuthorCard = styled.div`
    display: flex;
    align-items: center;
    gap: 24px;
    background: ${PRIMARY_COLOR_2};
    border: 1px solid ${PALE_WHITE_2};
    border-radius: 12px;
    padding: 24px;
    margin: 40px 0;

    @media (max-width: 640px) {
        flex-direction: column;
        text-align: center;
    }
`;

const AuthorImage = styled.img`
    width: 120px;
    height: 120px;
    border-radius: 24px;
    object-fit: cover;
`;

const AuthorInfoContainer = styled.div`
    flex: 1;
`;

const AuthorLabel = styled.div`
    color: ${SECONDARY_COLOR};
    font-size: 0.9rem;
    margin-bottom: 4px;
`;

const AuthorName = styled.div`
    color: ${SECONDARY_COLOR};
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 8px;
`;

const AuthorBio = styled.div`
    color: ${SECONDARY_COLOR};
    font-size: 1rem;
    line-height: 1.6;
`;

const AuthorSocial = styled.div`
    margin-top: 12px;
`;

const SocialLink = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: ${PRIMARY_COLOR};
    border: 1px solid ${PALE_WHITE_2};
    border-radius: 50%;
    color: ${SECONDARY_COLOR};
    text-decoration: none;
    transition: all 0.2s ease;

    &:hover {
        background: ${PRIMARY_COLOR_6};
        transform: translateY(-2px);
    }
`;

const TagsSection = styled.div`
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid ${PALE_WHITE_2};
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const Tag = styled.span`
    background: ${PRIMARY_COLOR};
    color: ${WHITE};
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
`;

const NotFound = styled.div`
    text-align: center;
    color: ${SECONDARY_COLOR};
    
    h1 {
        margin-bottom: 16px;
        color: ${SECONDARY_COLOR};
    }
    
    p {
        color: ${SECONDARY_COLOR};
        margin-bottom: 24px;
    }
`;

const ReadMore = styled(Link)`
    color: ${BLUE_HIGHLIGHT};
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
        opacity: 0.8;
    }
`;

const BlogTitle = styled.h2`
    margin: 0 0 10px;
    font-size: 1.5rem;
    
    a {
        color: ${BLUE_HIGHLIGHT};
        text-decoration: none;
        
        &:hover {
            opacity: 0.8;
        }
    }
`;

export default BlogPost;