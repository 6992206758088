import { hot } from 'react-hot-loader/root';
import React from 'react';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
// import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
// import { onError } from 'apollo-link-error';
import { onError } from '@apollo/client/link/error';
// import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { ApolloProvider, InMemoryCache, ApolloClient, HttpLink } from '@apollo/client';
// Error
import GraphqlErrorBoundary from './components/ErrorComponents/GraphqlErrorBoundary';
// Middlware and afterware
import { jwtHeadersAfterware } from './config/afterware';
import { accessTokenLink } from './config/middleware';
import Routes from './Routes';
import introspectionQueryResultData from '../src/fragmentTypes.json';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { PRIMARY_COLOR_8 } from './appearance/Colors';
import ReactPixel from 'react-facebook-pixel';
import environment from './env-config.js'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { TooltipProvider } from './components/Tooltip/TooltipManager';
import ReactTooltip from 'react-tooltip';

// Make sure to call loadStripe outside of a component's render to avoid recreating the Stripe object on every render.
const stripePromise = loadStripe(environment.REACT_STRIPE_PUBLISHABLE_KEY);

// APOLLO CLIENT SETUP
// 1) Instantiate an ApolloClient, and for the link property set the graphql application endpoint
// 2) Wrap the AppComponent with Apollo-Provider tag, and set the client property to the isntantiated
//    ApolloClient

// IF ERROR ON PRODUCTION, CHECK THE URL, MAYBE IT NEEDS PORT, SHOULDN'T BE THE CASE

const wsUri = environment.REACT_SUBSCRIPTION_URL;

const httpLink = new HttpLink({
    uri: environment.REACT_BACKEND_URL
});

// Error middleware
const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`);
            if (message.includes('You must be logged in')) {
                console.log('This should be the old error message');
                localStorage.clear();
                window.location.reload();
            } else {
                console.log('dispatch');
                // here to put snackbar
            }
        });
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLinkWithMiddleware = jwtHeadersAfterware.concat(accessTokenLink.concat(httpLink));

// console.error('give me access', accessTokenLink)

// Create a WebSocket link:
let wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
        reconnect: true,
        connectionParams: () => {
            return {
                'x-token': localStorage.getItem('token')
            };
        }
    }
});

export const changeSubscriptionToken = token => {
    // console.log('do you chagne me');
    // console.log(wsLink.subscriptionClient.connectionParams.authToken)
    // if (wsLink.subscriptionClient.connectionParams.authToken === token) {
    // 	console.log('ovo je token', token);
    // 	return;
    // }
    // console.log('I am here!!!!')

    // wsLink.subscriptionClient.connectionParams.authToken = token;
    // console.log(wsLink.subscriptionClient.connectionParams.authToken);

    wsLink.subscriptionClient.close();
    // console.log('This is a close() function',wsLink.subscriptionClient.close)
    // wsLink.subscriptionClient.connect();
};

console.log('are you undefined here?', wsLink);
// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
    // split based on operation type
    ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLinkWithMiddleware
);

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
});

const cache = new InMemoryCache({ fragmentMatcher });

// apollo client setup
const client = new ApolloClient({
    link: errorLink.concat(link),
    cache
});

function App() {
    return (
        // <TooltipProvider>
        <ApolloProvider client={client}>
            <GraphqlErrorBoundary>
                <Elements stripe={stripePromise}>
                    <Routes />
                </Elements>
                <CookieConsent
                    location="bottom"
                    // buttonText="Sure man!!"
                    cookieName="luckynote_cookie"
                    buttonStyle={{ borderRadius: 8 }}
                    style={{ backgroundColor: PRIMARY_COLOR_8 }}
                    expires={150}
                    onAccept={() => {
                        ReactPixel.grantConsent();
                    }}
                >
                    This website uses cookies to enhance the customer experience. Only essential cookies are being used.
                </CookieConsent>
                <ReactTooltip
                    effect="solid"
                    place="top"
                    className="global-tooltip"
                    delayShow={300}
                    html={true}
                />
            </GraphqlErrorBoundary>
        </ApolloProvider>
        // </TooltipProvider>
    );
}

export default hot(App);
