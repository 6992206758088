import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import MessagePreviewComponent from './MessagePreviewComponent';
import Hotkeys from 'react-hot-keys';

import { AuthContext } from '../util/context';
import { PALE_WHITE_1, PALE_WHITE_2, PALE_WHITE_6, PRIMARY_COLOR_8 } from '../appearance/Colors';
import ReactTooltip from 'react-tooltip';

function FolderOptionsComponent({ }) {
    const INITIAL_WIDTH = 350;

    const {
        state: { selectedMessage }
    } = React.useContext(AuthContext);

    const [width, setWidth] = useState(() => {
        const savedWidth = localStorage.getItem('folderOptionsWidth');
        return savedWidth ? parseInt(savedWidth, 10) : INITIAL_WIDTH;
    });

    const [isResizing, setIsResizing] = useState(false);
    const startXRef = useRef(0);
    const startWidthRef = useRef(width);
    const isResizingRef = useRef(false);

    // Add effect to rebuild tooltips when width changes or resizing stops
    useEffect(() => {
        ReactTooltip.rebuild();
        // Cleanup function to hide tooltips when component unmounts
        return () => {
            ReactTooltip.hide();
        };
    }, [width, isResizing]);


    useEffect(() => {
        localStorage.setItem('folderOptionsWidth', width.toString());
    }, [width]);

    const resetWidth = () => {
        setWidth(INITIAL_WIDTH);
        localStorage.setItem('folderOptionsWidth', INITIAL_WIDTH.toString());
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsResizing(true);
        isResizingRef.current = true;
        startXRef.current = e.clientX;
        startWidthRef.current = width;

        const handleMouseMove = (e) => {
            if (!isResizingRef.current) return;
            const diff = startXRef.current - e.clientX;
            const newWidth = Math.max(280, Math.min(800, startWidthRef.current + diff));
            setWidth(newWidth);
        };

        const handleMouseUp = () => {
            setIsResizing(false);
            isResizingRef.current = false;
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };


    if (selectedMessage) {
        return (
            <OuterContainer>
                <Container
                    width={width}
                    isResizing={isResizing}
                >
                    <ResizeHandle
                        onMouseDown={handleMouseDown}
                        onDoubleClick={resetWidth}
                        className={isResizing ? 'active' : ''}
                        data-tip="Double-click to reset width"
                        data-for="resetTip"
                    />
                    <ScrollContainer>
                        <MessagePreviewComponent message={selectedMessage} />
                    </ScrollContainer>
                </Container>
                <ReactTooltip
                    id="resetTip"
                    place="right"
                    effect="solid"
                    delayShow={300}
                    className="global-tooltip"
                />
            </OuterContainer>
        );
    }
    return null;
}

const OuterContainer = styled.div`
    position: relative;
    height: 100%;
    flex: 0 0 auto;
    display: flex;
    overflow: hidden;
`;

const Container = styled.div`
    position: relative;
    background-color: ${PRIMARY_COLOR_8};
    height: 100%;
    width: ${props => props.width}px;
    min-width: 280px;
    max-width: 800px;
    flex: 0 0 auto;
    transition: ${props => props.isResizing ? 'none' : 'width 0.1s ease'};
    display: flex; // Add this
    flex-direction: column; // Add this
    backdrop-filter: saturate(180%) blur(20px);
`;

const ScrollContainer = styled.div`
    flex: 1;
    overflow: hidden; // Let MessagePreviewComponent handle its own scrolling
    position: relative;
    display: flex;
    flex-direction: column;
`;

const ResizeHandle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 6px;
    background: ${props => props.className === 'active' ? PALE_WHITE_2 : 'transparent'};
    cursor: col-resize;
    z-index: 1000;
    
    &:hover {
        background: ${PALE_WHITE_1};
    }

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        height: 40px;
        width: 2px;
        background: ${PALE_WHITE_6};
        transform: translate(-50%, -50%);
    }

    &:active {
        background: ${PALE_WHITE_2};
    }
`;

export default FolderOptionsComponent;
