import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { IoMdAttach, IoMdSend } from 'react-icons/io';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import Hotkeys from 'react-hot-keys';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { MUTATION } from '../gql-operations';

import useUpdateMessageMutation from '../hooks/useUpdateMessageMutation';

import LoadingProgress from './Notifications/LoadingProgress';

import { useScrollToBottom } from '../hooks/useScrollToBottom';

import UploadPreviewModal from './Modals/UploadPreviewModal';
import DeleteModal from './Modals/DeleteModal';

import MessageInputEditPreview from '../components/MessageInputEditPreview';

import ReactTooltip from 'react-tooltip';

import useCheckBrowser from '../util/useCheckBrowser';
import { PRIMARY_COLOR, NOTE_BACKGROUND, PRIMARY_COLOR_4 } from '../appearance/Colors';
import SlashIcon from '../appearance/SlashIcon';

import useUpdateCheckboxes from '../hooks/useUpdateCheckboxes';
import useDeleteMessagesMutation from '../hooks/useDeleteMessagesMutation';
import useRedirectErrors from '../errors/useRedirectErrors';
import { AuthContext } from '../util/context';
import useAddMetadata from '../hooks/useAddMetadata';
import customAnalytics from '../util/custom-analytics';

function MessageInput({ folder, lastMessage, currentText, files, cleanOutsideFiles, title, autoCheckbox, taskFolderMessage }) {
    const { passingErrors } = useRedirectErrors();
    const {
        context: { setClientError }
    } = React.useContext(AuthContext);
    const [createMessage, { data, loading, error, client }] = useMutation(MUTATION.CREATE_MESSAGE, {
        onCompleted: res => {
            // let screenName = screen;
            // if (screenName === 'FolderScreen' && !folder.parent) {
            //     screenName = 'SubfolderScreen';
            // }
            if (taskFolderMessage) {
                res.createMessage.taskFolderMessage = true
            }
            customAnalytics.message_sent(res.createMessage);
        },
        onError: res => {
            passingErrors(res);
            setClientError(res.graphQLErrors);
        }
    });

    const inputFile = useRef();
    const textInput = useRef();
    const bodyInput = useRef();
    const formikRef = useRef();
    const [fileToSend, setFileToSend] = useState(files);

    // Load initial value from localStorage
    const [initialValues, setInitialValues] = useState({
        title: localStorage.getItem('messageTitle') || '',
        body: localStorage.getItem('messageBody') || ''
    });

    const [messageEdit, setMessageEdit] = useState(false);
    const { updateMessage } = useUpdateMessageMutation();
    const [showUploadPreviewModal, setShowUploadPreviewModal] = useState(false);
    const [fileUploadingStatus, setFileUploadingStatus] = useState(false);
    const [showDescription, setShowDescription] = useState(false);
    const [inputFocused, setInputFocused] = useState(false);
    const [bodyFocused, setBodyFocused] = useState(false);
    const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);

    // custom hooks
    const [deleteMessages] = useDeleteMessagesMutation();
    const { updateCheckboxes } = useUpdateCheckboxes();

    const [scrollToBottom] = useScrollToBottom();
    const { Platform, PLATFORMS } = useCheckBrowser();

    const { savedFromMeta } = useAddMetadata();

    const [hoverUpload, setHoverUpload] = useState(false);
    const [hoverNote, setHoverNote] = useState(false);

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const onChangeFile = async event => {
        event.stopPropagation();
        event.preventDefault();
        // var file = event.target.files[0];

        const fileListAsArray = Array.from(event.target.files);

        setFileToSend(fileListAsArray);
        setShowUploadPreviewModal(true);
        // this.setState({file}); /// if you want to upload latter
    };

    const clearFileToSend = () => {
        setFileToSend(undefined);
        inputFile.current.value = '';
        cleanOutsideFiles();
    };

    useEffect(() => {
        if (files) {
            // console.log('are you going here', files);
            setFileToSend(files);
            setShowUploadPreviewModal(true);
        }
        return () => {
            // cleanup
            setFileToSend(undefined);
        };
    }, [files]);

    useEffect(() => {
        if (textInput.current) {
            const { value } = textInput.current;
            textInput.current.setSelectionRange(value.length, value.length);
        }
        if (bodyInput.current) {
            const { value } = bodyInput.current;
            if (value.length > 0) {
                setShowDescription(true);
            }
            bodyInput.current.setSelectionRange(value.length, value.length);
        }
    }, [initialValues]);

    // const validateSize = file => {
    // 	var FileSize = file.files[0].size / 1024 / 1024; // in MB
    // 	if (FileSize > 2) {
    // 		alert('File size exceeds 2 MB');
    // 		// $(file).val(''); //for clearing with Jquery
    // 	} else {
    // 	}
    // };

    // if (data) {
    // console.log('good');
    // }

    // if (loading) {
    // console.log('just do it');
    // }

    // const isCheckbox = title => {
    //     console.log('it should start', title.startsWith('-'));
    //     return title.startsWith('-');
    // };

    const setContent = values => {
        let isCheckbox = false;
        let title = values.title;
        if (title.startsWith('-')) {
            title = title.replace('-', '').trim();
            isCheckbox = true;
            customAnalytics.dash_task();
        }

        if (autoCheckbox) {
            isCheckbox = true;
            // customAnalytics.task_from_tasks_folder();
        }

        if (showDescription && values.body) {
            return {
                title: title,
                body: values.body,
                isCheckbox: isCheckbox,
                metadata: savedFromMeta()
            };
        } else {
            return {
                title: title,
                body: values.body,
                isCheckbox: isCheckbox,
                metadata: savedFromMeta()
            };
        }
    };

    const sendMessage = values => {
        if ((showDescription && values.body.length > 0) || values.title.length > 0) {
            createMessage({
                variables: {
                    data: {
                        folderId: folder.id,
                        content: setContent(values)
                    }
                },
                update: async (store, { data: { createMessage } }) => {
                    if (autoCheckbox) {
                        updateCheckboxes([createMessage]);
                    }
                }
            });
            // update is done with subscriptions
            // clearFileToSend();
            resetForm();
            console.log('you should scroll to bottom');
            // setTimeout(() => {
            //     scrollToBottom();
            // }, 150);
        }
    };

    const renderActions = () => {
        if (
            // (inputFocused || bodyFocused) &&
            formikRef.current &&
            (formikRef.current.values.title.length > 0 || formikRef.current.values.body.length > 0)
        ) {
            return (
                <IconWrapper
                    onClick={() => {
                        console.log('happen something');
                        formikRef.current.handleSubmit();
                    }}
                >
                    <IoMdSend size={24} color={PRIMARY_COLOR} />
                </IconWrapper>
            );
            // } else if (inputFocused || bodyFocused) {
            //     // setInputFocused(false);
            //     // setBodyFocused(false);
            //     return <div style={{ width: 8 }}></div>;
        } else {
            // return <SlashIcon onClick={() => textInput.current.focus()} />;
            return <div style={{ width: 8 }}></div>
        }
    };

    const resetForm = () => {
        textInput.current.focus();
        setShowDescription(false);
        localStorage.removeItem('messageTitle');
        localStorage.removeItem('messageBody');
        setInitialValues((prevValues) => ({
            ...prevValues, // Spread previous state to retain body
            title: '', // Update title only,
            body: ''
        }));
        textInput.current.value = ''
        bodyInput.current.value = ''
        formikRef.current.resetForm();
        formikRef.current.setSubmitting(false);
    };

    const placeholderTitle = () => {
        let prefix = title ? title : 'Write in';
        let postfix = `Press "/" to focus input`
        if (folder) {
            if (folder.title.length > 30) {
                prefix = prefix + ' ' + folder.title.slice(0, 28) + '...';
            } else {
                prefix = prefix + ' ' + folder.title;
            }
        }
        // return prefix + ' ' + postfix;
        return postfix;
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                enableReinitialize
                onSubmit={async (values, actions) => {
                    // console.log('mes and fold', folder.id, values.title);
                    if (!messageEdit) {
                        sendMessage(values);
                    } else {
                        // updating the message
                        if (values.title.length > 0 || lastMessage.file || lastMessage.image) {
                            updateMessage(lastMessage, values);
                            resetForm();
                            setMessageEdit(false);
                        } else {
                            setShowDeleteMessageModal(true);
                        }
                    }
                }}
                validationSchema={Yup.object().shape({
                    // title: Yup.string().length() > 0
                })}
            >
                {({ values, handleSubmit, handleChange, handleBlur, errors, actions, setFieldValue, resetForm }) => (
                    <div>
                        <div>
                            <MessageInputEditPreview
                                resetForm={resetForm}
                                show={messageEdit}
                                lastMessage={lastMessage}
                                onClose={() => setMessageEdit(false)}
                            ></MessageInputEditPreview>

                            {/* {displayFilePreview()}
							{displayImagePreview()} */}
                        </div>
                        <InputWrapper>
                            <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                style={{ display: 'none' }}
                                onChange={onChangeFile}
                                multiple
                            />
                            <Hotkeys
                                keyName="shift+enter, enter, arrowUp, command+U, ctrl+U, command+B, ctrl+B, esc"
                                filter={event => {
                                    // if (event.target === 'input') {
                                    // 	alert('you pressed a!');
                                    // }
                                    return true;
                                }}
                                onKeyDown={(keyName, e, handle) => {
                                    if (
                                        document.activeElement.id === 'messageInput' ||
                                        document.activeElement.id === 'bodyInput'
                                    ) {
                                        if (keyName === 'enter') {
                                            e.preventDefault();
                                            handleSubmit();
                                        }
                                        if (keyName === 'command+U' || keyName === 'ctrl+U') {
                                            e.preventDefault();
                                            onButtonClick();
                                            customAnalytics.ui_action({
                                                "action": "File Picker",
                                                "shortcut_key": keyName
                                            })
                                        }
                                        if (keyName === 'command+B' || keyName === 'ctrl+B') {
                                            e.preventDefault();
                                            if (showDescription) {
                                                document.getElementById('messageInput').focus();
                                            } else {
                                                // hack to focus on the element
                                                setTimeout(function () {
                                                    document.getElementById('bodyInput').focus();
                                                }, 0);
                                            }
                                            setShowDescription(!showDescription);
                                            customAnalytics.ui_action({
                                                "action": "Fast Note",
                                                "shortcut_key": keyName
                                            })
                                        }
                                    }
                                    if (messageEdit && keyName === 'esc') {
                                        setMessageEdit(false);
                                        setShowDescription(false);
                                        resetForm();
                                    }
                                }}
                            >
                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                    <IconWrapper
                                        onMouseEnter={() => setHoverUpload(true)}
                                        onMouseLeave={() => setHoverUpload(false)}
                                        onClick={onButtonClick}
                                    >
                                        <IoMdAttach size={24} color={PRIMARY_COLOR} />
                                    </IconWrapper>
                                    <TextareaAutosize
                                        id="messageInput"
                                        ref={textInput}
                                        // useCacheForDOMMeasurements
                                        style={styles.textInputStyle}
                                        onFocus={() => setInputFocused(true)}
                                        // onBlur={() => setInputFocused(false)}
                                        autoFocus
                                        maxRows={24}
                                        name="title"
                                        onKeyDown={e => {
                                            if (e.key === 'ArrowUp' && !values.title.length > 0) {
                                                // editMessage();
                                                e.preventDefault();
                                                if (lastMessage.body && lastMessage.body.length > 0) {
                                                    setShowDescription(true);
                                                    setFieldValue('body', lastMessage.body);
                                                }
                                                document.getElementById('messageInput').focus();
                                                setFieldValue('title', lastMessage.title);
                                                setMessageEdit(true);
                                                // values.message = 'somethign'
                                                customAnalytics.ui_action({
                                                    'action': "Arrow Up Edit",
                                                    "shortcut_key": e.key
                                                })
                                            }
                                        }}
                                        // ref={inputTextarea}
                                        type="text"
                                        placeholder={
                                            hoverUpload
                                                ? `${Platform === PLATFORMS.MACINTOSH ? '(⌘+U)' : '(Ctr+U)'
                                                } or drag and drop on the folder to upload a file`
                                                : placeholderTitle()
                                        }
                                        value={values.title}
                                        onChange={e => {
                                            handleChange(e);
                                            localStorage.setItem('messageTitle', e.target.value);
                                            // if (textInput.current) {
                                            //     const { value } = e.target;
                                            //     setTimeout(() => textInput.current.setSelectionRange(value.length, value.length), 0);
                                            // }
                                        }}
                                        // onFocus={e => {
                                        //     if (textInput.current) {
                                        //         const { value } = e.target;
                                        //         setTimeout(() => textInput.current.setSelectionRange(value.length, value.length), 0);
                                        //     }
                                        // }}
                                    />
                                    <AddNote
                                        onMouseEnter={() => setHoverNote(true)}
                                        onMouseLeave={() => setHoverNote(false)}
                                        onClick={() => setShowDescription(!showDescription)}
                                    >
                                        <span>
                                            {showDescription ? '- Note' : '+ Note'}
                                            {hoverNote
                                                ? `${Platform === PLATFORMS.MACINTOSH ? ' (⌘B)' : ' (Ctr+B)'}`
                                                : null}
                                        </span>
                                    </AddNote>
                                    {renderActions()}
                                </div>

                                <div
                                    style={{
                                        // width: '100%',
                                        alignItems: 'center',
                                        display: showDescription ? 'flex' : 'none',
                                        backgroundColor: NOTE_BACKGROUND,
                                        padding: 8,
                                        borderRadius: 8,
                                        marginTop: 4
                                    }}
                                >
                                    <TextareaAutosize
                                        id="bodyInput"
                                        ref={bodyInput}
                                        // useCacheForDOMMeasurements
                                        style={styles.textInputStyle}
                                        // autoFocus
                                        onFocus={e => {
                                            // hack to set the cursor at the end of the input field
                                            e.target.value = '';
                                            e.target.value = values.body;
                                            setBodyFocused(true);
                                        }}
                                        onBlur={() => setBodyFocused(false)}
                                        maxRows={24}
                                        name="body"
                                        // ref={bodyRef}
                                        type="text"
                                        placeholder={`Add description`}
                                        value={values.body}
                                        onChange={e => {
                                            handleChange(e);
                                            localStorage.setItem('messageBody', e.target.value);
                                        }}
                                    />
                                </div>
                            </Hotkeys>
                        </InputWrapper>
                        <UploadPreviewModal
                            currentFolder={folder}
                            currentText={values.title}
                            files={fileToSend}
                            isOpen={showUploadPreviewModal}
                            autoCheckbox={autoCheckbox}
                            closeModal={loading => {
                                setFileUploadingStatus(loading);
                                setShowUploadPreviewModal(false);
                                setFileToSend(undefined);
                                cleanOutsideFiles();
                            }}
                            successfulUpload={response => {
                                console.log('response is', response);
                                if (response) {
                                    // setTimeout(() => {
                                    setFileUploadingStatus(false);
                                    setShowUploadPreviewModal(false);
                                    setFileToSend(undefined);
                                    setFieldValue('title', '');
                                    cleanOutsideFiles();
                                    // setFiles(undefined);
                                    // scrollToBottom();
                                    // }, 150);
                                }
                            }}
                        // here we should display the input filed text
                        // currentText={currentInputText.text}
                        ></UploadPreviewModal>
                        <DeleteModal
                            isOpen={showDeleteMessageModal}
                            title={`This action can't be undone`}
                            description="Delete selected message?"
                            closeModal={async response => {
                                if (response) {
                                    deleteMessages([lastMessage]);
                                    resetForm();
                                    setMessageEdit(false);
                                }
                                setShowDeleteMessageModal(false);
                            }}
                        ></DeleteModal>
                    </div>
                )}
            </Formik>
        </>
    );
}

const styles = {
    textInputStyle: {
        flex: 1,
        // paddingLeft: 8,
        // paddingRight: 8,
        paddingBottom: 4,
        margin: 0,
        border: 0,
        outline: 'none',
        fontSize: 14,
        resize: 'none',
        // minHeight: 'min-content',
        paddingTop: 1,
        backgroundColor: 'transparent'
    }
};

const IconWrapper = styled.div`
    // width: 40px;
    // height: 40px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 4px;
    cursor: pointer;
`;

const InputContainer = styled.input`
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
    margin: 0px;
    border: 0px;
    outline: none;
`;

const AddNote = styled.div`
    background-color: ${NOTE_BACKGROUND};
    padding: 6px 8px;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    &:hover {
        color: rgba(0, 0, 0, 0.8);
    }
`;

const InputWrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.7);
    // display: flex;
    // align-items: flex-end;
    // align-items: center;
    // flex-direction: column;
    margin: 8px;
    padding: 4px;
    border-radius: 12px;
    backdrop-filter: saturate(180%) blur(20px);
`;

const TruncateWrapper = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

export default MessageInput;
