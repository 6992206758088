import React, { useState } from 'react';
import jwt from 'jsonwebtoken';

import { MUTATION } from '../../../gql-operations';
import { Link } from 'react-router-dom';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { withRouter, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { changeSubscriptionToken } from '../../../App';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';

import styled from 'styled-components';
import LuckyIcon from '../../../appearance/LuckyIcon';
import * as COLOR from '../../../appearance/Colors';

import './Login.scss';
import { RED } from '../../../appearance/Colors';

import customAnalytics from '../../../util/custom-analytics';
import { useMediaQuery } from 'react-responsive';

function Login({ history, location, match }) {
    // console.log('loggin the match', location.state);
    const [loginResponse, setLoginResponse] = useState(undefined);
    const [showPassword, setShowPassword] = useState(false);
    const [login, { loading }] = useMutation(MUTATION.LOGIN, {
        onCompleted: res => {
            setLoginResponse(res);
            customAnalytics.login(res.login);
        }
    });

    const isMobile = useMediaQuery({ maxWidth: 767 });

    // const [forgotResponse, setForgotResponse] = useState(false);

    // useEffect(() => {
    // 	if (location.state?.forgotResponse) {
    // 		// forgotResponse updated, do something with `route.params.post`
    // 		// For example, send the post to the server
    // 		const { forgotResponse } = location.state;
    // 		console.log('i get this', forgotResponse);
    // 		if (forgotResponse && forgotResponse.data.forgotPasswordMail) {
    // 			setForgotResponse(true);
    // 		} else {
    // 			// TODO maybe display some error
    // 		}
    // 	}
    // 	return () => {
    // 		setForgotResponse(false);
    // 		location.state = {}
    // 	};
    // }, [location.state?.forgotResponse]);

    const _setLocalStorage = response => {
        const { login } = response;

        if (login && login.token && login.refreshToken) {
            console.log('SETTING LOCAL STORAGE');
            const { token, refreshToken, userData } = login;
            const decoded = jwt.decode(token);
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('userRole', decoded.role);
            changeSubscriptionToken(token);
            // localStorage.setItem('userData', JSON.stringify(userData));
        }
    };

    // const renderSuccessfulForgetResponse = () => {
    // 	if (forgotResponse) {
    // 		return (
    // 			<ErrorText>
    // 				The email has been sent, please check your inbox
    // 			</ErrorText>
    // 		);
    // 	}
    // };

    const renderResponseMessage = () => {
        if (loginResponse) {
            switch (loginResponse.login.__typename) {
                case 'AuthPayload':
                    _setLocalStorage(loginResponse);
                    setLoginResponse(undefined);
                    history.push('/home');
                    return true;
                case 'LoginInvalidInputError':
                    return <ErrorText>{loginResponse.login.message}</ErrorText>;
                case 'UserNotFound':
                    return <ErrorText>{loginResponse.login.message}</ErrorText>;
                case 'PasswordsDoNotMatch':
                    return <ErrorText>{loginResponse.login.message}</ErrorText>;
                case 'UserNotVerified':
                    return (
                        <div>
                            <ErrorText>
                                {loginResponse.login.message}{' '}
                                <Link to={'/check-account'} style={{ color: RED }}>
                                    Verify account
                                </Link>
                            </ErrorText>
                        </div>
                    );
                case 'UserLocked':
                    return <ErrorText>{loginResponse.login.message}</ErrorText>;
                case 'InternalServerError':
                    return <ErrorText>Server error. We'll be back soon. Sorry :(</ErrorText>;
                default:
                    return <ErrorText>Unknown error</ErrorText>;
            }
        }
    };

    return (
        <>
            <div
                style={{
                    backgroundColor: '#fafafa',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex'
                }}
            >
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={async (values, actions) => {
                        await setLoginResponse(undefined);
                        login({
                            variables: {
                                data: {
                                    email: values.email,
                                    password: values.password,
                                    settings: {
                                        usedDesktopWebApp: true
                                    }
                                }
                            }
                        });
                        // just checking before letting you in
                        actions.setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string().email('Invalid Email').required('Required'),
                        password: Yup.string().min(6, 'Minimum password length is 6.').max(50, 'Maximum password length is 50.').required('Required')
                    })}
                >
                    {({ values, handleSubmit, handleChange, handleBlur, errors, touched }) => (
                        <div>
                            {/* <div style={{ alignItems: 'center', marginBottom: 24 }}>
                        </div> */}
                            {isMobile ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        flex: 1,
                                        textAlign: 'center',
                                        backgroundColor: COLOR.GREEN_OP,
                                        padding: 16,
                                        flexDirection: 'column'
                                    }}
                                    onClick={() => {
                                        history.push('/mobile');
                                    }}
                                >
                                    <div style={{ fontSize: 14, lineHeight: '20px' }}>
                                        Trying to access Luckynote over your phone browser? It works better as a
                                        standalone Android and iOS app.
                                    </div>
                                    <div style={{ marginTop: 16, fontWeight: 600, fontSize: 16 }}>Download app</div>
                                </div>
                            ) : null}
                            <div
                                className="logo"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    history.push('/home');
                                }}
                            >
                                <LuckyIcon />
                                {/* <h4 style={{ color: '#333333' }}>Luckynote</h4> */}
                                {/* {renderSuccessfulForgetResponse()} */}
                            </div>
                            {renderResponseMessage()}

                            <div className="card">
                                <form onSubmit={handleSubmit} className="auth-form">
                                    <div className="row email">
                                        <input
                                            name="email"
                                            type="email"
                                            onChange={handleChange('email')}
                                            // onBlur={handleBlur}
                                            value={values.email}
                                            placeholder="Email"
                                            spellCheck="off"
                                            autoCorrect="off"
                                        />
                                        <ErrorMessage
                                            name="email"
                                            render={msg => {
                                                console.log('msg', msg);
                                                // return <Text style={styles.errorMsg}>{msg}</Text>;
                                                return (
                                                    <div id="email" className="error">
                                                        {msg}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="row password">
                                        <div style={{ position: 'relative', width: '100%' }}>
                                            <input
                                                name="password"
                                                type={showPassword ? "text" : "password"}
                                                onChange={handleChange('password')}
                                                value={values.password}
                                                placeholder="Password"
                                                autoCapitalize="off"
                                                autoCorrect="off"
                                                style={{ width: '90%' }}
                                            />
                                            <button
                                                type="button"
                                                onClick={() => setShowPassword(!showPassword)}
                                                style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    background: 'none',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    padding: '0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    color: COLOR.PRIMARY_COLOR_2
                                                }}
                                            >
                                                {showPassword ? <IoEyeOffOutline size={20} /> : <IoEyeOutline size={20} />}
                                            </button>
                                        </div>
                                        <ErrorMessage
                                            name="password"
                                            render={msg => {
                                                return (
                                                    <div id="password" className="error">
                                                        {msg}
                                                    </div>
                                                );
                                            }}
                                        />
                                    </div>

                                    <div className="row submit">
                                        <button type="submit" className="button big blue">
                                            {loading ? 'Signing in...' : 'Sign In'}
                                        </button>
                                    </div>

                                    <div className="link forgot-password">
                                        <Link to={'/forgot-password'}>Forgot password</Link>
                                    </div>
                                </form>

                                <span className="link signup">
                                    Don't have an account?
                                    <span> </span>
                                    <Link to={'/register'}>Create Account</Link>
                                </span>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
        </>
    );
}

const ErrorText = styled.div`
    color: #666;
    text-align: center;
    margin-top: 8px;
    line-height: 20px;
`;

export default withRouter(Login);
