import React, { useEffect, useRef } from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Video from '../components/sections/Video';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Cta from '../components/sections/Cta';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
// import ScrollReveal from '../utils/ScrollReveal';

import '../assets/scss/style.scss';
import Pricing from '../components/sections/Pricing';
import Downloads from '../components/sections/Downloads';
import FAQ from '../components/sections/FAQ';
import * as COLOR from '../../../appearance/Colors';

const LandingHome = () => {
    const childRef = useRef();
    let location = useLocation();

    useEffect(() => {
        const referralIdParam = new URLSearchParams(location.search).get('referral');
        if (referralIdParam) {
            localStorage.setItem("referralId", referralIdParam);
        }
    }, []);

    useEffect(() => {
        // Handle scroll to FAQ if coming from another page
        if (location.state?.scrollToFAQ) {
            const faqSection = document.getElementById('faq');
            if (faqSection) {
                setTimeout(() => {
                    faqSection.scrollIntoView({ behavior: 'smooth' });
                }, 100); // Small delay to ensure content is rendered
            }
        }
    }, [location]);

    return (
        <CustomStyle>
            <Header navPosition="right" className="reveal-from-bottom" />
            <main className="site-content">
                <Hero />
                {/* <Hero className="illustration-section-01" /> */}
                {/* <FeaturesTiles /> */}
                {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" /> */}
                <FeaturesSplit invertMobile imageFill />
                <Testimonial topDivider />
                <Video topDivider />
                <Downloads topDivider />
                <Pricing topDivider />
                <FAQ topDivider />
                {/* <Cta split /> */}
            </main>
            <Footer />
        </CustomStyle>
    );
};

const CustomStyle = styled.div`
    body {
        font-size: 1rem;
    }
    // color: #9ca9b3;
    a {
        // font-weight: 400 !important;
        // text-decoration: none;
        //     &:not(.button) {
        //         color: #9ca9b3;
        //     }
        //     &.button-link {
        //         color: #9ca9b3;
        //     }

        //     &.func-link {
        //         color: #9ca9b3;
        //     }
        //     &:hover {
        //         color: #eceded;
        //     }
    }
    // h2,
    // .h2 {
    //     font-size: 44px !important;
    //     line-height: 54px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // h3,
    // .h3 {
    //     font-weight: 700 !important;
    //     font-size: 32px !important;
    //     line-height: 42px !important;
    //     letter-spacing: -0.3px !important;
    // }
    // p {
    //     font-size: 1rem;
    //     line-height: 30px;
    // }
`;

export default LandingHome;
