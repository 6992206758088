import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { PRIMARY_COLOR, SECONDARY_TEXT, PALE_WHITE_2, WHITE, SECONDARY_COLOR, PALE_WHITE_6 } from '../../appearance/Colors';
import LayoutDefault from '../Landing/layouts/LayoutDefault';

const BLOG_POSTS = [
    {
        id: '1',
        title: 'Getting Started with Luckynote',
        slug: 'getting-started-with-luckynote',
        excerpt: 'Learn how to make the most of Luckynote for your personal note-taking and task management. From basic features to advanced tips, this guide will help you get started.',
        featuredImage: 'https://images.unsplash.com/photo-1517842645767-c639042777db?w=1200',
        category: 'Tutorial',
        tags: ['getting-started', 'tutorial', 'basics'],
        createdAt: '2025-03-23T10:00:00Z',
        author: {
            name: 'Uros',
            avatar: 'https://pbs.twimg.com/profile_images/1445126003140005895/hIqqppM4_400x400.jpg'
        }
    },
    {
        id: '2',
        title: 'Best Practices for Note Organization',
        slug: 'best-practices-for-note-organization',
        excerpt: 'Discover proven strategies for keeping your notes organized and easily accessible. Learn about the PARA method, effective tagging, and folder structure tips.',
        featuredImage: 'https://images.unsplash.com/photo-1484480974693-6ca0a78fb36b?w=1200',
        category: 'Tips & Tricks',
        tags: ['organization', 'productivity', 'tips'],
        createdAt: '2025-03-22T10:00:00Z',
        author: {
            name: 'Uros',
            avatar: 'https://pbs.twimg.com/profile_images/1445126003140005895/hIqqppM4_400x400.jpg'
        }
    },
    {
        id: '3',
        title: 'Productivity Hacks Using Luckynote',
        slug: 'productivity-hacks-using-luckynote',
        excerpt: 'Boost your productivity with these advanced Luckynote features and techniques. From keyboard shortcuts to automation, learn how to get more done with less effort.',
        featuredImage: 'https://images.unsplash.com/photo-1483058712412-4245e9b90334?w=1200',
        category: 'Productivity',
        tags: ['productivity', 'hacks', 'advanced'],
        createdAt: '2025-03-21T10:00:00Z',
        author: {
            name: 'Uros',
            avatar: 'https://pbs.twimg.com/profile_images/1445126003140005895/hIqqppM4_400x400.jpg'
        }
    },
    {
        id: '4',
        title: 'Comparing Note-Taking Apps: Why Luckynote Stands Out',
        slug: 'comparing-note-taking-apps',
        excerpt: 'A detailed comparison of popular note-taking apps and how Luckynote offers a unique approach to personal knowledge management.',
        featuredImage: 'https://images.unsplash.com/photo-1517842645767-c639042777db?w=1200',
        category: 'Comparison',
        tags: ['comparison', 'productivity', 'note-taking'],
        createdAt: '2024-03-23T10:00:00Z',
        author: {
            name: 'Uros',
            avatar: 'https://pbs.twimg.com/profile_images/1445126003140005895/hIqqppM4_400x400.jpg'
        }
    }
];

const BlogList = () => {
    return (
        <LayoutDefault>
            <Helmet>
                <title>Luckynote Blog - Productivity Tips & Updates</title>
                <meta 
                    name="description" 
                    content="Discover productivity tips, note-taking strategies, and the latest Luckynote updates in our blog."
                />
                <meta
                    name="keywords"
                    content="productivity blog,note-taking tips,task management,luckynote updates,productivity tools"
                />
                {/* Open Graph tags for better social sharing */}
                <meta property="og:title" content="Luckynote Blog - Productivity Tips & Updates" />
                <meta property="og:description" content="Discover productivity tips, note-taking strategies, and the latest Luckynote updates in our blog." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://luckynote.io/blog" />
            </Helmet>
            
            <BlogContainer>
                <BlogHeader>
                    <h1>Luckynote Blog</h1>
                    <Subtitle>Tips, tutorials, and updates to help you be more productive</Subtitle>
                </BlogHeader>

                <FeaturedPost>
                    <FeaturedImage src={BLOG_POSTS[0].featuredImage} alt={BLOG_POSTS[0].title} />
                    <FeaturedContent>
                        <CategoryTag>{BLOG_POSTS[0].category}</CategoryTag>
                        <FeaturedTitle>
                            <Link to={`/blog/${BLOG_POSTS[0].slug}`}>{BLOG_POSTS[0].title}</Link>
                        </FeaturedTitle>
                        <FeaturedExcerpt>{BLOG_POSTS[0].excerpt}</FeaturedExcerpt>
                        <ReadMore to={`/blog/${BLOG_POSTS[0].slug}`}>
                            Read Featured Post →
                        </ReadMore>
                    </FeaturedContent>
                </FeaturedPost>

                <BlogGrid>
                    {BLOG_POSTS.slice(1).map(post => (
                        <BlogCard key={post.id}>
                            {post.featuredImage && (
                                <BlogImage src={post.featuredImage} alt={post.title} />
                            )}
                            <BlogContent>
                                <BlogTitle>
                                    <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                                </BlogTitle>
                                <BlogMeta>
                                    <span>{new Date(post.createdAt).toLocaleDateString()}</span>
                                    {post.category && (
                                        <CategoryTag>{post.category}</CategoryTag>
                                    )}
                                </BlogMeta>
                                <BlogExcerpt>{post.excerpt}</BlogExcerpt>
                                <ReadMore to={`/blog/${post.slug}`}>
                                    Read More →
                                </ReadMore>
                            </BlogContent>
                        </BlogCard>
                    ))}
                </BlogGrid>
            </BlogContainer>
        </LayoutDefault>
    );
};

const BlogContainer = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
`;

const BlogHeader = styled.div`
    text-align: center;
    margin-bottom: 60px;

    h1 {
        color: ${SECONDARY_COLOR};
        font-size: 2.5rem;
        margin-bottom: 20px;
    }
`;

const Subtitle = styled.p`
    color: ${SECONDARY_COLOR};
    font-size: 1.2rem;
    margin-bottom: 40px;
`;

const FeaturedPost = styled.article`
    background: ${PRIMARY_COLOR};
    border: 1px solid ${PALE_WHITE_2};
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const FeaturedImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 400px;
`;

const FeaturedContent = styled.div`
    padding: 40px;
    flex-direction: column;
    justify-content: center;
`;

const FeaturedTitle = styled.h2`
    font-size: 2rem;
    margin: 16px 0;
    
    a {
        color: ${WHITE};
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
`;

const FeaturedExcerpt = styled.p`
    color: ${SECONDARY_TEXT};
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 24px;
`;

const BlogGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
`;

const BlogCard = styled.article`
    background: ${PRIMARY_COLOR};
    border: 1px solid ${PALE_WHITE_2};
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s ease;
    
    &:hover {
        transform: translateY(-5px);
    }
`;

const BlogImage = styled.img`
    width: 100%;
    height: 200px;
    object-fit: cover;
`;

const BlogContent = styled.div`
    padding: 20px;
`;

const BlogTitle = styled.h2`
    margin: 0 0 10px;
    font-size: 1.5rem;
    
    a {
        color: ${WHITE};
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
`;

const BlogMeta = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;
    color: ${SECONDARY_TEXT};
    font-size: 0.9rem;
`;

const CategoryTag = styled.span`
    background: ${PALE_WHITE_2};
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    color: ${PALE_WHITE_6};
`;

const BlogExcerpt = styled.p`
    color: ${SECONDARY_TEXT};
    margin: 0 0 20px;
    line-height: 1.6;
`;

const ReadMore = styled(Link)`
    color: ${WHITE};
    text-decoration: none;
    font-weight: 500;
    
    &:hover {
        text-decoration: underline;
    }
`;

export default BlogList; 