import { gql } from 'apollo-boost';

export const FIND_MESSAGES = gql`
    query($data: FindMessagesInput!) {
        findMessages(data: $data) {
            messages {
                id
                title
                body
                createdAt
                updatedAt
                deletedAt
                lastViewedAt
                lastMovedAt
                image {
                    url
                    name
                    displayName
                    type
                    format
                    size
                    width
                    aspect_ratio
                    public_id
                }
                file {
                    url
                    name
                    displayName
                    type
                    format
                    size
                }
                isCheckbox
                isChecked
                isStarred
                folder {
                    id
                    title
                    owner
                    parent
                    deletedAt
                }
                urlMeta {
                    author
                    date
                    description
                    image
                    logo
                    publisher
                    title
                    url
                    public_id
                    width
                    format
                    aspect_ratio
                    size
                    embed {
                        html
                        script {
                            src
                            async
                            charset
                        }
                    }
                }
            }
            cursor
        }
    }
`;
