import React from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/react-hooks';
import { QUERY } from '../gql-operations';
import { AuthContext } from '../util/context';

function useUpdateStarred() {
    const client = useApolloClient();

    const updateStarred = messages => {
        try {
            let data;
            try {
                data = client.readQuery({
                    query: QUERY.GET_STARRED_MESSAGES_GLOBAL
                });
            } catch (error) {
                // Initialize the cache if it doesn't exist
                client.writeQuery({
                    query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                    data: {
                        getStarredMessagesGlobal: []
                    }
                });
                data = {
                    getStarredMessagesGlobal: []
                };
            }

            const dataClone = JSON.parse(JSON.stringify(data));
            let cloneData = [...dataClone.getStarredMessagesGlobal];

            messages.forEach(message => {
                let folderIndex = cloneData.findIndex(object => {
                    if (object.folder.id === message.folder.id) return true;
                });

                if (folderIndex > -1) {
                    let newMessages = [];
                    newMessages = [...messages, ...cloneData[folderIndex].messages];
                    cloneData[folderIndex].messages = newMessages;

                    const newData = {
                        getStarredMessagesGlobal: cloneData
                    };

                    client.writeQuery({
                        query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                        data: newData
                    });
                } else {
                    // custom creation of folder for the sake of speed
                    let newFolder = {
                        __typename: 'FolderContentGlobal',
                        folder: message.folder,
                        messages: [message]
                    };

                    // doesn't work with .push
                    let newCloneData = [newFolder, ...cloneData];

                    const newData = {
                        getStarredMessagesGlobal: newCloneData
                    };

                    client.writeQuery({
                        query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                        data: newData
                    });
                }
            });
        } catch (error) {
            console.log('Error while updating starred messages', error);
        }
    };

    const spliceStarred = messages => {
        try {
            let data;
            try {
                data = client.readQuery({
                    query: QUERY.GET_STARRED_MESSAGES_GLOBAL
                });
            } catch (error) {
                // If there's no data to splice from, just return
                return;
            }

            const dataClone = JSON.parse(JSON.stringify(data));
            let cloneData = [...dataClone.getStarredMessagesGlobal];

            messages.forEach(message => {
                let folderIndex = cloneData.findIndex(object => {
                    if (object.folder.id === message.folder.id) return true;
                });

                if (folderIndex > -1) {
                    let newMessages = [...cloneData[folderIndex].messages];

                    // finding the message in the apollo cache
                    let index = newMessages.findIndex(obj => obj.id === message.id);

                    if (index > -1) {
                        if (newMessages.length - 1 === 0) {
                            // removing the whole folder
                            cloneData.splice(folderIndex, 1);
                        } else {
                            newMessages.splice(index, 1);
                            cloneData[folderIndex].messages = newMessages;
                        }
                    }

                    const newData = {
                        getStarredMessagesGlobal: cloneData
                    };

                    client.writeQuery({
                        query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                        data: newData
                    });
                }
            });
        } catch (error) {
            console.log('Error while removing starred messages', error);
        }
    };

    return { updateStarred, spliceStarred };
}

export default useUpdateStarred;
