import React, { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { DETAIL_TEXT, PRIMARY_COLOR, PRIMARY_COLOR_8, SECONDARY_TEXT } from '../../../../appearance/Colors';

const propTypes = {
    ...SectionTilesProps.types
};

const defaultProps = {
    ...SectionTilesProps.defaults
};

const sectionHeader = {
    title: 'Frequently Asked Questions',
    paragraph: 'Everything you need to know about Luckynote'
};

const faqItems = [
    {
        question: 'What is Luckynote?',
        answer: 'Luckynote is a smart note-taking app that helps you organize and manage your messages, links, and content across all your devices. It\'s designed to make saving and finding information quick and effortless.'
    },
    {
        question: 'How does Luckynote compare to other note-taking apps?',
        answer: 'Unlike other tools that require you to think about organization before saving, Luckynote lets you quickly save first and organize later. Even if you never organize your notes, our smart search helps you find information based on content type and keywords you remember. We focus on removing friction from the capture process - save now, organize when convenient.'
    },
    {
        question: 'What makes Luckynote special?',
        answer: 'Our key differentiator is the combination of speed and intelligence. The quick capture feature lets you save content in seconds, while Lucky Actions and smart organization help manage it automatically. You can expand any message to see more details, edit the content, add tags, or move it to different folders. This flexibility means your notes can grow and evolve as your needs change.'
    },
    {
        question: 'How does Luckynote handle privacy?',
        answer: 'We take privacy seriously. Your data is encrypted and stored securely on our servers. We never sell your information or data to third parties. You have full control over your data and can delete it at any time. We also minimize data collection to only what\'s necessary for the app to function.'
    },
    {
        question: 'How does the free version work?',
        answer: 'The free version includes unlimited folders, sync across all devices, Lucky Actions for smart message handling, and our Look Up feature for quick searches. It\'s perfect for getting started and organizing your basic notes.'
    },
    {
        question: 'What are Lucky Actions?',
        answer: 'Lucky Actions are smart shortcuts that help you quickly perform common tasks with your messages. They make it easy to organize, share, or take action on your saved content with just one click.'
    },
    {
        question: 'Can I access my notes offline?',
        answer: 'Not completely, you can create messages while offline and they will be synced when you\'re back online. Messages in Stars and Tasks folders are cached for offline access.'
    },
    {
        question: 'What\'s included in the Standard plan?',
        answer: 'The Standard plan includes everything in the free version plus unlimited messages, advanced in-app browser for quick content saving, and development support. You\'ll also get early access to new features as they\'re released.'
    },
    {
        question: 'What payment options do you offer?',
        answer: 'We offer both one-time payment and subscription options. The Standard plan is a one-time payment that gives you lifetime access to all core features. For users needing more storage and advanced features, we offer flexible monthly subscription plans.'
    },
    {
        question: `What's included in the subscription plans?`,
        answer: 'Our subscription plans include everything in the Standard plan plus additional storage space, priority support, and early access to new features. You can choose between different storage tiers to match your needs.'
    }
];

const FAQ = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
}) => {
    const [openIndex, setOpenIndex] = useState(null);
    const [heights, setHeights] = useState({});
    const answerRefs = useRef({});
    const observers = useRef({});

    useEffect(() => {
        // Setup ResizeObserver for each answer
        faqItems.forEach((_, index) => {
            if (answerRefs.current[index]) {
                observers.current[index] = new ResizeObserver(entries => {
                    for (let entry of entries) {
                        setHeights(prev => ({
                            ...prev,
                            [index]: entry.contentRect.height
                        }));
                    }
                });
                observers.current[index].observe(answerRefs.current[index]);
            }
        });

        // Cleanup observers
        return () => {
            Object.values(observers.current).forEach(observer => observer.disconnect());
        };
    }, []);

    const outerClasses = classNames(
        'faq section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
    );

    const innerClasses = classNames(
        'faq-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    return (
        <section {...props} className={outerClasses} id="faq">
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader data={sectionHeader} className="center-content" />
                    <div className="faq-content">
                        {faqItems.map((item, index) => (
                            <div
                                key={index}
                                className="faq-item"
                                style={{
                                    borderBottom: '1px solid rgba(156,169,179,0.1)',
                                    marginBottom: 24
                                }}
                            >
                                <div
                                    className="faq-question"
                                    onClick={() => setOpenIndex(openIndex === index ? null : index)}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        paddingBottom: 16
                                    }}
                                >
                                    <h4 style={{ 
                                        margin: 0,
                                        fontSize: 18,
                                        fontWeight: '500',
                                        color: openIndex === index ? PRIMARY_COLOR : 'inherit'
                                    }}>
                                        {item.question}
                                    </h4>
                                    {openIndex === index ? (
                                        <FiChevronUp size={24} color={PRIMARY_COLOR} />
                                    ) : (
                                        <FiChevronDown size={24} />
                                    )}
                                </div>
                                <div
                                    className="faq-answer-wrapper"
                                    style={{
                                        height: openIndex === index ? `${heights[index] + 16}px` : '0',
                                        overflow: 'hidden',
                                        transition: 'height 0.25s ease-out'
                                    }}
                                >
                                    <div
                                        className="faq-answer"
                                        ref={el => answerRefs.current[index] = el}
                                        style={{
                                            opacity: openIndex === index ? 1 : 0,
                                            transition: 'opacity 0.15s ease-out',
                                            fontSize: 16,
                                            lineHeight: '28px',
                                            color: PRIMARY_COLOR_8,
                                            paddingBottom: 16,
                                            willChange: 'opacity'
                                        }}
                                    >
                                        {item.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};

FAQ.propTypes = propTypes;
FAQ.defaultProps = defaultProps;

export default FAQ; 