import React from 'react';

import { useMutation } from '@apollo/react-hooks';
import { MUTATION, QUERY } from '../gql-operations';

import { AuthContext } from '../util/context';

import { STARRED, CHECKBOXES, HIERARCHY, TRASH, SEARCH_MESSAGES } from '../util/specialFolderEnums';
import customAnalytics from '../util/custom-analytics';
import { DEFAULT_SORT_FIELD } from '../constants/dateField';

function useDeleteMessagesQuery() {
    const [deleteMessages, { data: successResponse }] = useMutation(MUTATION.DELETE_MESSAGES, {
        onCompleted: res => {
            // analytics on the bottom
        }
    });

    const {
        state: {
            folderFilterType,
            selectedFolder,
            showDeletedMessages,
            searchTerm,
            searchGlobal,
            searchDeletedMessages,
            searchFolderFilterType,
            searchActive,
            selectedMessage
        },
        context: { setMessagesToSend, setSelectedMessagesMap, setSelectedMessage }
    } = React.useContext(AuthContext);

    const deleteFromFilteredCustomFolder = (messages, fromFolder, store) => {
        console.log('you are the hierarcy with thype');
        console.log('you go into filtered');
        try {
            const filterData = store.readQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: '',
                        type: folderFilterType,
                        folderId: fromFolder?.id || '',
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                }
            });

            if (!filterData?.filterMessages?.messages) {
                return; // Skip if no data exists
            }

            console.log('got the filterData,', filterData);

            const newMessages = JSON.parse(JSON.stringify(filterData.filterMessages.messages));

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }

                closeMessagePreview(message);
            });

            const newData = {
                filterMessages: {
                    messages: [...newMessages],
                    cursor: filterData.filterMessages.cursor,
                    __typename: filterData.filterMessages.__typename
                }
            };

            store.writeQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: '',
                        type: folderFilterType,
                        folderId: fromFolder?.id || '',
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from hierarchy filtered', error);
        }
    };

    const deleteFromSearchFolder = (messages, fromFolder, store) => {
        console.log('trash from search', fromFolder, messages);
        try {
            const filterData = store.readQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || selectedFolder?.id === HIERARCHY ? '' : fromFolder?.id,
                        isTrash: searchDeletedMessages ? true : false,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                }
            });

            if (!filterData?.filterMessages?.messages) {
                return; // Skip if no data exists
            }

            console.log('this is the data from hy', filterData);

            // you need to spread so you get the new object, not the reference
            const newMessages = JSON.parse(JSON.stringify(filterData.filterMessages.messages));

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }

                closeMessagePreview(message);
            });

            const newData = {
                filterMessages: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    messages: [...newMessages],
                    cursor: filterData.filterMessages.cursor,
                    __typename: filterData.filterMessages.__typename
                }
            };

            console.log('old data', filterData);
            console.log('new data', newData);

            store.writeQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: searchFolderFilterType,
                        folderId: searchGlobal || selectedFolder?.id === HIERARCHY ? '' : fromFolder?.id,
                        isTrash: searchDeletedMessages ? true : false,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from search folder', error);
        }
    };

    const deleteFromCustomFolder = (messages, fromFolder, store) => {
        console.log('you are the hierarcy');
        try {
            const filterData = store.readQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: folderFilterType,
                        folderId: (searchGlobal || selectedFolder?.id === HIERARCHY) ? '' : (fromFolder?.id || ''),
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages || searchDeletedMessages,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                }
            });

            if (!filterData?.filterMessages?.messages) {
                return; // Skip if no data exists
            }

            console.log('this is the data from hy', filterData);

            // you need to spread so you get the new object, not the reference
            const newMessages = JSON.parse(JSON.stringify(filterData.filterMessages.messages));

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }

                closeMessagePreview(message);
            });

            const newData = {
                filterMessages: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    messages: [...newMessages],
                    cursor: filterData.filterMessages.cursor,
                    __typename: filterData.filterMessages.__typename
                }
            };

            console.log('old data', filterData);
            console.log('new data', newData);

            store.writeQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: searchTerm,
                        type: folderFilterType,
                        folderId: (searchGlobal || selectedFolder?.id === HIERARCHY) ? '' : (fromFolder?.id || ''),
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages || searchDeletedMessages,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from custom folder', error);
        }
    };

    const deleteFromFilteredFolder = (messages, fromFolder, store) => {
        console.log('you go into filtered');
        try {
            const filterData = store.readQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: '',
                        type: folderFilterType,
                        folderId: fromFolder?.id === HIERARCHY ? '' : fromFolder?.id,
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                }
            });

            if (!filterData?.filterMessages?.messages) {
                return; // Skip if no data exists
            }

            console.log('got the filterData,', filterData);

            const newMessages = JSON.parse(JSON.stringify(filterData.filterMessages.messages));

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }

                closeMessagePreview(message);
            });

            const newData = {
                filterMessages: {
                    messages: [...newMessages],
                    cursor: filterData.filterMessages.cursor,
                    __typename: filterData.filterMessages.__typename
                }
            };

            store.writeQuery({
                query: QUERY.FILTER_MESSAGES,
                variables: {
                    data: {
                        text: '',
                        type: folderFilterType,
                        folderId: fromFolder?.id === HIERARCHY ? '' : fromFolder?.id,
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages,
                        sortBy: DEFAULT_SORT_FIELD
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from filtered', error);
        }
    };

    const deleteFromCurrentFolder = (messages, fromFolder, store) => {
        // DELETE FROM ORIGINAL DATA
        console.log('you go into original');
        try {
            const data = store.readQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: fromFolder?.id,
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages
                    }
                }
            });

            if (!data?.getFolderContent?.messages?.messages) {
                return; // Skip if no data exists
            }

            // you need to spread so you get the new object, not the reference
            const newMessages = [...data.getFolderContent.messages.messages];

            messages.forEach(message => {
                // finding the message in the apollo cache
                let index = newMessages.findIndex(obj => obj.id === message.id);

                // removing the message from the apollo cache
                if (index > -1) {
                    newMessages.splice(index, 1);
                }

                closeMessagePreview(message);
            });

            const newData = {
                getFolderContent: {
                    // we are not cursoring the folders, that's why it's only previousResult2
                    folder: data.getFolderContent.folder,
                    parent: data.getFolderContent.parent,
                    path: data.getFolderContent.path,
                    folders: [...data.getFolderContent.folders],
                    messages: {
                        messages: [...newMessages],
                        cursor: data.getFolderContent.messages.cursor,
                        __typename: data.getFolderContent.messages.__typename
                    },
                    __typename: data.getFolderContent.__typename
                }
            };

            console.log('old data', data);
            console.log('new data', newData);

            store.writeQuery({
                query: QUERY.GET_FOLDER_CONTENT,
                variables: {
                    data: {
                        folderId: fromFolder?.id,
                        isTrash: fromFolder?.deletedAt ? true : showDeletedMessages
                    }
                },
                data: newData
            });
        } catch (error) {
            console.log('error from original', error);
        }
    };

    const deleteFromStarred = (messages, store) => {
        try {
            const data = store.readQuery({
                query: QUERY.GET_STARRED_MESSAGES_GLOBAL
            });

            if (!data?.getStarredMessagesGlobal) {
                return; // Skip if no data exists
            }

            const dataClone = JSON.parse(JSON.stringify(data));

            messages.forEach(message => {
                for (let index = 0; index < dataClone.getStarredMessagesGlobal.length; index++) {
                    const { folder, messages } = dataClone.getStarredMessagesGlobal[index];
                    if (messages) {
                        let messageIndex = messages.findIndex(obj => obj.id === message.id);

                        // removing the message from the apollo cache
                        if (messageIndex > -1) {
                            messages.splice(messageIndex, 1);
                            if (messages.length === 0) {
                                // deleting the whole folder if nothing is left
                                dataClone.getStarredMessagesGlobal.splice(index, 1);
                            }
                            break;
                        }
                    }
                }
            });

            store.writeQuery({
                query: QUERY.GET_STARRED_MESSAGES_GLOBAL,
                data: dataClone
            });
        } catch (error) {
            console.log('error from starred', error);
        }
    };

    const deleteFromChecked = (messages, store) => {
        try {
            const data = store.readQuery({
                query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL
            });

            if (!data?.getCheckboxMessagesGlobal) {
                return; // Skip if no data exists
            }

            const dataClone = JSON.parse(JSON.stringify(data));
            let cloneData = [...dataClone.getCheckboxMessagesGlobal];

            if (messages) {
                messages.forEach(message => {
                    try {
                        let folderIndex = cloneData.findIndex(object => {
                            if (object.folder.id === message.folder.id) return true;
                        });

                        if (folderIndex > -1) {
                            let newMessages = [];

                            newMessages = [...cloneData[folderIndex].messages];

                            // finding the message in the apollo cache
                            let index = newMessages.findIndex(obj => obj.id === message.id);

                            // removing the message from the apollo cache
                            console.log('message is', index);
                            if (index > -1) {
                                if (newMessages.length - 1 === 0) {
                                    // removing the whole folder
                                    cloneData.splice(folderIndex, 1);
                                } else {
                                    newMessages.splice(index, 1);
                                    cloneData[folderIndex].messages = newMessages;
                                }
                            }

                            closeMessagePreview(message);

                            const newData = {
                                getCheckboxMessagesGlobal: cloneData
                            };

                            store.writeQuery({
                                query: QUERY.GET_CHECKBOX_MESSAGES_GLOBAL,
                                data: newData
                            });
                        }
                    } catch (error) {
                        console.log('Error while splicing the checks', error);
                    }
                });
            }
        } catch (error) {
            console.log('error from checked', error);
        }
    };

    const closeMessagePreview = message => {
        if (selectedMessage && selectedMessage.id === message.id) {
            setSelectedMessage(null);
        }
    };

    const prepareMessages = messages => {
        console.log('incoming messagews', messages);
        let better = [...messages];
        let clean = better.map(message => message.id);
        console.log('better', clean);
        return clean;
    };

    const runThis = (messages, fromFolder) => {
        deleteMessages({
            variables: {
                data: {
                    messageIds: prepareMessages(messages)
                }
            },
            update: async (store, { data: { deleteMessages } }) => {
                try {
                    console.log('you go altogether', selectedFolder);
                    if (searchActive) {
                        deleteFromSearchFolder(messages, selectedFolder, store);
                    } else if (!selectedFolder || selectedFolder.id === '' || selectedFolder.id === HIERARCHY) {
                        // For FolderAll.js (HIERARCHY view), we need to update both filtered and custom folder caches
                        try {
                            deleteFromFilteredCustomFolder(messages, selectedFolder, store);
                        } catch (e) {
                            console.log('Error updating filtered custom folder cache:', e);
                        }
                        try {
                            deleteFromCustomFolder(messages, selectedFolder, store);
                        } catch (e) {
                            console.log('Error updating custom folder cache:', e);
                        }
                    } else if (selectedFolder && folderFilterType.length > 0) {
                        deleteFromFilteredFolder(messages, selectedFolder, store);
                        deleteFromCurrentFolder(messages, selectedFolder, store);
                    } else if (selectedFolder) {
                        deleteFromCurrentFolder(messages, selectedFolder, store);
                    }
                    
                    // Always try to update starred and checked caches
                    try {
                        deleteFromStarred(messages, store);
                    } catch (e) {
                        console.log('Error updating starred cache:', e);
                    }
                    try {
                        deleteFromChecked(messages, store);
                    } catch (e) {
                        console.log('Error updating checked cache:', e);
                    }
                } catch (error) {
                    console.log('there is no current folder for this message', error);
                }
            }
        });
        setMessagesToSend([]);
        setSelectedMessagesMap({});
        customAnalytics.delete_messages(messages);
    };

    return [runThis, { successResponse }];
}

export default useDeleteMessagesQuery;
