import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { IoMdMenu } from 'react-icons/io';
import { FiMoreVertical } from 'react-icons/fi';

import { PALE_WHITE_2, PALE_WHITE_4, WHITE } from '../../appearance/Colors';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { cloudinaryCore } from '../../util/cloudinary';

import useTrashMessagesMutation from '../../hooks/useTrashMessagesMutation';

import useRestoreMessagesMutation from '../../hooks/useRestoreMessagesMutation';
import useUpdateCheckboxes from '../../hooks/useUpdateCheckboxes';
import useUpdateStarred from '../../hooks/useUpdateStarred';
import useNotificationSystem from '../../components/Notifications/useNotificationSystem';

import { AuthContext } from '../../util/context';

import { QUERY, MUTATION } from '../../gql-operations';

import { usePopper } from 'react-popper';

import FileSaver from 'file-saver';
import customAnalytics from '../../util/custom-analytics';

function MessageDropdown({ message, index, hovering, showDeleteModal, hide, showMoveMessageModal, openMenu, screenName }) {
    const dropdownMenu = useRef(null);

    const [showDropMenu, setShowDropMenu] = useState(false);

    const [starMessage] = useMutation(MUTATION.STAR_MESSAGE, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    "action": "Star"
                }, message);
            }
        }
    });
    const [unstarMessage] = useMutation(MUTATION.UNSTAR_MESSAGE, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    "action": "Unstar"
                }, message);
            }
        }
    });

    const [addCheckbox] = useMutation(MUTATION.ADD_CHECKBOX, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    'action': "Task"
                }, message);
            }
        }
    });

    const [removeCheckbox] = useMutation(MUTATION.REMOVE_CHECKBOX, {
        onCompleted: res => {
            if (res) {
                customAnalytics.message_edit({
                    'action': "Unask"
                }, message);
            }
        }
    });


    const [trashMessages] = useTrashMessagesMutation();

    const { restoreMessagesMutation } = useRestoreMessagesMutation();

    const { updateStarred, spliceStarred } = useUpdateStarred();


    const { updateCheckboxes, spliceFromCheckboxes } = useUpdateCheckboxes();

    const { showDefaultNotification, showCopyToClipboardNotification } = useNotificationSystem();

    const {
        state: {
            messagesToSend, selectedMessagesMap, selectedMessage, folderFilterType,
            searchFolderFilterType,
            messagesSearchInput,
            searchGlobal,
            searchDeletedMessages,
            showDeletedMessages
        },
        context: { setMessagesToSend, setSelectedMessagesMap, setShowMultiSelect, setSelectedMessage, searchTerm }
    } = React.useContext(AuthContext);

    const closeOnEscape = event => {
        if (event.key === 'Escape') {
            setShowDropMenu(false);
            openMenu(false);
            document.removeEventListener('click', closeMenu);
        }
    };

    const showMenu = event => {
        event.preventDefault();
        event.stopPropagation();
        if (!showDropMenu) {
            document.addEventListener('click', closeMenu);
            document.addEventListener('keydown', closeOnEscape, true);
        } else {
            document.removeEventListener('click', closeMenu);
        }
        setShowDropMenu(!showDropMenu);
        openMenu(!showDropMenu);
    };

    const closeMenu = event => {
        // use contains only if you want not to close when clicking on an option
        // if (dropdownMenu.current && !dropdownMenu.current.contains(event.target)) {
        setShowDropMenu(false);
        openMenu(false);
        document.removeEventListener('click', closeMenu);
        document.removeEventListener('keydown', closeOnEscape, true);
        // }
    };

    const copyToClipboard = async newMessage => {
        let list = '';

        if (newMessage.title.length > 0) {
            list = newMessage.title;
        }

        //  NOT DEVELOPED YET
        // if (newMessage.image) {
        //     const originalSize = cloudinaryCore.url(newMessage.image.url, { quality: 'auto', fetchFormat: 'auto' });
        //     list = list + originalSize + `\n`;
        // }
        // if (newMessage.file) {
        //     const url = newMessage.file.url;
        //     list = list + url + `\n`;
        // }

        await navigator.clipboard.writeText(list);
        showCopyToClipboardNotification();
        // setCopiedToClipboard(true);
        // setTimeout(() => {
        //     setCopiedToClipboard(false);
        // }, 1500);
    };

    const sendMessageAction = (action, message, index) => {
        let actionObject = {}
        if (folderFilterType && folderFilterType.length > 0) {
            actionObject["filters_active"] = [...folderFilterType];
        }
        if (searchFolderFilterType && searchFolderFilterType.length > 0) {
            actionObject["search_filters_active"] = [...searchFolderFilterType];
        }
        if (messagesSearchInput && messagesSearchInput.length > 0) {
            actionObject["search_term_length"] = messagesSearchInput.length;
        }
        if (searchGlobal) {
            actionObject["search_global"] = true;
        }
        if (searchDeletedMessages) {
            actionObject["search_trashed"] = true;
        }
        if (showDeletedMessages) {
            actionObject["show_trashed"] = true;
        }

        actionObject = {
            "action": action,
            "screen": screenName,
            "index_position": index,
            ...actionObject
        }
        console.log('Dropdown select', actionObject)
        customAnalytics.message_action(actionObject, message);
    }

    const selectMessage = (message, listIndex) => {
        if (selectedMessagesMap[message.id]) {
            let newSpread = [...messagesToSend];
            let index = newSpread.findIndex(obj => obj.id === message.id);

            if (index > -1) {
                newSpread.splice(index, 1);
            }

            setMessagesToSend([...newSpread]);

            delete selectedMessagesMap[message.id];
            setSelectedMessagesMap({
                ...selectedMessagesMap
            });
            sendMessageAction('Deselect', message, listIndex)
        } else {
            setSelectedMessagesMap({
                ...selectedMessagesMap,
                [message.id]: true
            });
            setMessagesToSend([...messagesToSend, message]);
            sendMessageAction('Select', message, listIndex)
        }
    };

    const removeSelectedMessages = () => {
        setSelectedMessagesMap({});
        setMessagesToSend([]);
        setShowMultiSelect(false);
    };

    const renderStarred = () => {
        return (
            <li>
                <Element
                    onClick={e => {
                        e.stopPropagation();
                        if (!message.isStarred) {
                            starMessage({
                                variables: {
                                    data: {
                                        messageId: message.id
                                    }
                                },
                                update: async (store, { data }) => {
                                    updateStarred([message]);
                                },
                                refetchQueries: [
                                    {
                                        query: QUERY.GET_MESSAGE,
                                        variables: {
                                            data: {
                                                id: message.id
                                            }
                                        }
                                    }
                                ]
                            });
                        } else {
                            unstarMessage({
                                variables: {
                                    data: {
                                        messageId: message.id
                                    }
                                },
                                update: async (store, { data }) => {
                                    spliceStarred([message]);
                                },
                                refetchQueries: [
                                    {
                                        query: QUERY.GET_MESSAGE,
                                        variables: {
                                            data: {
                                                id: message.id
                                            }
                                        }
                                    }
                                ]
                            });
                        }
                    }}
                >
                    {!message.isStarred ? 'Star' : 'Unstar'}
                </Element>
            </li>
        );
    };

    const renderCheck = () => {
        return (
            <li>
                <Element
                    onClick={e => {
                        e.stopPropagation();
                        if (!message.isCheckbox) {
                            addCheckbox({
                                variables: {
                                    data: {
                                        messageId: message.id
                                    }
                                },
                                update: async (store, { data }) => {
                                    updateCheckboxes([message]);
                                },
                                refetchQueries: [
                                    {
                                        query: QUERY.GET_MESSAGE,
                                        variables: {
                                            data: {
                                                id: message.id
                                            }
                                        }
                                    }
                                ]
                            });
                        } else {
                            removeCheckbox({
                                variables: {
                                    data: {
                                        messageId: message.id
                                    }
                                },
                                update: async (store, { data }) => {
                                    spliceFromCheckboxes([message]);
                                },
                                refetchQueries: [
                                    {
                                        query: QUERY.GET_MESSAGE,
                                        variables: {
                                            data: {
                                                id: message.id
                                            }
                                        }
                                    }
                                ]
                            });
                        }
                    }}
                >
                    {!message.isCheckbox ? 'Add Task' : 'Untask'}
                </Element>
            </li>
        );
    };

    const downloadImage = async (url, name, size) => {
        try {
            // downloadFile(cloudinaryCore.url(message.image.url));
            let url = await cloudinaryCore.url(url);
            FileSaver.saveAs(url, name);
            // const link = document.createElement('a');
            // link.href = cloudinaryCore.url(message.image);
            // link.click();
        } catch (error) {
            console.log('error, trying the old way', error);
            const link = document.createElement('a');
            link.href = cloudinaryCore.url(url);
            link.target = '_blank';
            link.download = true;
            link.click();
        }
        customAnalytics.ui_action({
            'action': "Download Image",
            "size": size
        })
    };

    const downloadFile = async (url, name, size) => {
        try {
            // downloadFile(cloudinaryCore.url(message.image.url));
            FileSaver.saveAs(url, name);
            // const link = document.createElement('a');
            // link.href = cloudinaryCore.url(message.image);
            // link.click();
        } catch (error) {
            console.log('error, trying the old way', error);
            const link = document.createElement('a');
            link.href = cloudinaryCore.url(url);
            link.target = '_blank';
            link.download = true;
            link.click();
        }
        customAnalytics.ui_action({
            'action': "Download File",
            "size": size
        })
    };

    const renderImageDownload = () => {
        if (message.image) {
            const { url, public_id, size, name, format, displayName } = message.image;
            return (
                <li>
                    <Element
                        onClick={e => {
                            e.stopPropagation();
                            downloadImage(url, displayName, size);
                        }}
                    >
                        Download image
                    </Element>
                </li>
            );
        }
    };

    const renderFileDownload = () => {
        if (message.file) {
            const { url, size, name, format, displayName } = message.file;
            return (
                <li>
                    <Element
                        onClick={e => {
                            e.stopPropagation();
                            downloadFile(url, displayName, size);
                        }}
                    >
                        Download file
                    </Element>
                </li>
            );
        }
    };

    const trashAll = () => {
        // onDeselect();
        removeSelectedMessages();
        if (message.id === selectedMessage && selectedMessage.id) {
            setSelectedMessage(undefined);
        }
        trashMessages([message]);
        showDefaultNotification(message.id, 'Messages moved to trash');
    };

    const restoreMessageMutation = () => {
        // onDeselect();
        removeSelectedMessages();
        restoreMessagesMutation([message]);
    };

    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'fixed',
        position: 'right',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [88, 8]
                }
            },
            { name: 'arrow', options: { element: arrowElement } }
        ]
    });

    // if (hovering || showDropMenu) {
    if (!hide && (hovering || showDropMenu)) {
        return (
            <React.Fragment>
                <div>
                    <DropDownElement onClick={showMenu} ref={setReferenceElement}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <FiMoreVertical size={20} color={WHITE}></FiMoreVertical>
                        </div>
                    </DropDownElement>
                    {showDropMenu ? (
                        <DropDownMenu ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                            <div role="menu" style={{ width: 200 }}>
                                <div role="content">
                                    <ul>
                                        {!message.deletedAt ? (
                                            <>
                                                <li>
                                                    <Element
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            showMoveMessageModal(true);
                                                        }}
                                                    >
                                                        Move to...
                                                    </Element>
                                                </li>
                                                <li>
                                                    <Element
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            selectMessage(message, index);
                                                        }}
                                                    >
                                                        Select
                                                    </Element>
                                                </li>
                                                {message.title ? (
                                                    <li>
                                                        <Element
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                copyToClipboard(message);
                                                            }}
                                                        >
                                                            Copy title
                                                        </Element>
                                                    </li>
                                                ) : null}
                                                {renderStarred()}
                                                {renderCheck()}
                                                {renderImageDownload()}
                                                {renderFileDownload()}
                                            </>
                                        ) : (
                                            <>
                                                <li>
                                                    <Element
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            restoreMessageMutation();
                                                        }}
                                                    >
                                                        Restore
                                                    </Element>
                                                </li>
                                                <li>
                                                    <Element
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            selectMessage(message, index);
                                                        }}
                                                    >
                                                        Select
                                                    </Element>
                                                </li>
                                            </>
                                        )}
                                        <SeparatorLI />
                                        {message.deletedAt ? (
                                            <li>
                                                <Element
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        showDeleteModal(true);
                                                    }}
                                                >
                                                    Delete permanently
                                                </Element>
                                            </li>
                                        ) : (
                                            <li>
                                                <Element
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        trashAll();
                                                    }}
                                                >
                                                    Trash
                                                </Element>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </DropDownMenu>
                    ) : null}
                </div>
                {/* <UpdateFolderModal
                folder={folder}
                isOpen={showUpdateFolderModal}
                closeModal={() => {
                    setShowUpdateFolderModal(false);
                }}
            /> */}
            </React.Fragment>
        );
    } else {
        return null;
    }
}

const DropDownElement = styled.div`
    color: white;
    cursor: pointer;
    border-radius: 8px;
    position: absolute;
    background-color: ${PALE_WHITE_2};
    backdrop-filter: saturate(180%) blur(20px);
    top: 0px;
    right: -36px;
    padding: 4px;
    &:hover {
        background-color: ${PALE_WHITE_4};
    }
`;

const DropDownMenu = styled.div`
    // position: absolute;
    // right: -242px;
    // top: 0;
    // margin-top: 6px;
    // margin-left: 4px;
    background-color: white;
    min-height: 15px;
    min-width: 200px;
    max-width: 304px;
    z-index: 1500;
    color: #262626;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
`;

const SeparatorLI = styled.li`
    padding-bottom: 1px;
    margin-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const Element = styled.div`
    padding: 10px 12px;
    cursor: pointer;
    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

export default MessageDropdown;
