import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

// GQL
import { QUERY } from '../../gql-operations';
import { useLazyQuery, useQuery, useSubscription, useMutation } from '@apollo/react-hooks';

// Custom components
import MyDropzone from '../MyDropzone';
import MessageInput from '../MessageInput';

import SearchFoldersModal, { searchActions } from '../Modals/SearchFoldersModal';

import FilterComponent from './Components/FilterComponent';
import LoadingProgress from '../Notifications/LoadingProgress';
import DeleteModal from '../Modals/DeleteModal';

import subscribeToMessages from './Components/subscribeToMessages';

import MultiSelectOptions from './Components/MultiSelectOptions';
import MessagesList from './Components/MessagesList';
import FolderHeader from './Components/FolderHeader';

import useGetSubfolders from '../../hooks/useGetSubfolders';
import { useScrollToBottom } from '../../hooks/useScrollToBottom';
import useKeyboardListener from '../../hooks/useKeyboardListener';
import useDeleteFoldersMutation from '../../hooks/useDeleteFoldersMutation';

import ActionTag from '../../components/Action/ActionTagButton';

import { AuthContext } from '../../util/context';
import { pluralHelper } from '../../util/pluralHelper';

import { RED, WHITE } from '../../appearance/Colors';

import HideDeletedMessagesComponent from '../../components/Folder/HideDeletedMessagesComponent';

import useRedirectErrors from '../../errors/useRedirectErrors';
import useRestoreFoldersMutation from '../../hooks/useRestoreFolders';

import customAnalytics from '../../util/custom-analytics';

const FolderContainer = ({ enableShiftSelect = true }) => {
    // const {} = useKeyboardListener();
    const {
        state: {
            homeFolder,
            selectedFolder,
            folderFilterType,
            showDeletedMessages,
            messagesToSend,
            selectedMessagesMap,
            showMultiSelect
        },
        context: {
            setSelectedMessage,
            setShowDeletedMessages,
            setMessagesToSend,
            setSelectedMessagesMap,
            setShowMultiSelect
        }
    } = React.useContext(AuthContext);

    // MODALS
    const [showFoldersModal, setShowFoldersModal] = useState(false);
    const [showDeleteFolderModal, setShowDeleteFolderModal] = useState(false);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

    const [files, setFiles] = useState(null);

    // multiple select
    // const [selectedMessagesMap, setSelectedMessagesMap] = useState({});
    // const [messagesToSend, setMessagesToSend] = useState([]);

    // HOOKS
    const { restoreFoldersMutation } = useRestoreFoldersMutation();
    const { deleteFolders } = useDeleteFoldersMutation();
    const [getSubfolders] = useGetSubfolders();
    const [scrollToBottom, endRef] = useScrollToBottom();
    const { passingErrors } = useRedirectErrors();

    const [
        filterMessages,
        {
            loading: filterLoading,
            error: filterError,
            data: filterData,
            fetchMore: filterFetchMore,
            client,
            updateQuery
        }
    ] = useLazyQuery(QUERY.FILTER_MESSAGES, {
        onError: error => {
            passingErrors(error);
        },
        fetchPolicy: 'network-only'
    });

    // FOLDER CONTENT QUERY
    const { subscribeToMore, loading, error, data, fetchMore } = useQuery(QUERY.GET_FOLDER_CONTENT, {
        variables: {
            data: {
                folderId: selectedFolder.id,
                isTrash: selectedFolder.deletedAt ? true : showDeletedMessages
            }
        },
        fetchPolicy: 'network-only',
        onError: error => {
            passingErrors(error);
        },
        onCompleted: async folderContentObject => {
            console.log('now here it is', folderContentObject);
            getSubfolders(selectedFolder);
            scrollToBottom();
            // await updateTheList(folderContentObject)
            // await smallestUp(folderContentObject);
            // forceUpdate();
            // smallUpdate(folderContentObject);
        }
    });

    // setting the file listener for paste event
    useEffect(() => {
        window.addEventListener('paste', e => {
            // console.log('checking on paste', e.clipboardData);
            // console.log('checking on paste', e.clipboardData.files);
            const fileListAsArray = Array.from(e.clipboardData.files);
            if (fileListAsArray.length) {
                setFiles(fileListAsArray);
            }
        });

        return () => {
            // console.log('do you cleanup here')
            setFiles(undefined);
        };
    }, []);

    useEffect(() => {
        // focus on input field
        if (document.getElementById('messageInput')) {
            document.getElementById('messageInput').focus();
        }
        // unsubscribe function
        let unsubscribe = subscribeToMessages(selectedFolder, subscribeToMore);
        if (folderFilterType.length > 0) {
            customTypeFilter(folderFilterType);
        }
        if ((selectedFolder && selectedFolder.id) === (homeFolder && homeFolder.id)) {
            customAnalytics.pageview('/inbox_view', 'Inbox');
        } else {
            customAnalytics.pageview('/folder_view',
                selectedFolder && selectedFolder.parent ? "SubfolderScreen" : 'FolderScreen',
                {
                    "folder_id": selectedFolder && selectedFolder.id,
                }
            );
        }

        return () => {
            // here we unsubscribe the folder
            unsubscribe();
            // setCurrentOffset(0);
        };
    }, [selectedFolder, folderFilterType]);

    const customTypeFilter = type => {
        filterMessages({
            variables: {
                data: {
                    text: '',
                    type: type,
                    folderId: selectedFolder.id,
                    isTrash: selectedFolder.deletedAt ? true : showDeletedMessages
                }
            }
        });
    };

    const onDeleteFolder = () => {
        setShowDeleteFolderModal(true);
    };

    const renderInputOrMultiSelect = folder => {
        if (folder) {
            if (Object.keys(selectedMessagesMap).length > 0) {
                return (
                    <MultiSelectOptions
                        onSelectMove={bool => {
                            setShowFoldersModal(bool);
                        }}
                    ></MultiSelectOptions>
                );
            } else {
                if (!showDeletedMessages) {
                    return (
                        <>
                            {!folder.deletedAt ? (
                                <MessageInput
                                    folder={folder}
                                    files={files}
                                    cleanOutsideFiles={() => {
                                        setFiles(null);
                                    }}
                                    lastMessage={data.getFolderContent.messages.messages[0]}
                                // currentText={(value)=> {
                                // 	console.log('Here we should receive the value from input field', value)
                                // }}
                                />
                            ) : (
                                <div style={{ marginBottom: 8 }}></div>
                            )}
                        </>
                    );
                }
                return null;
            }
        }
    };

    const renderDeletedFolder = currentFolder => {
        if (currentFolder && currentFolder.deletedAt) {
            return (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        backgroundColor: RED
                    }}
                >
                    <div
                        style={{
                            padding: 10,
                            display: 'flex',
                            flex: 1,
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <span style={{ color: WHITE, fontWeight: '600', marginRight: 16 }}>
                            This folder is in Trash
                        </span>
                        <ActionTag
                            label="Restore folder"
                            style={{ marginRight: 8 }}
                            onClick={() => {
                                restoreFoldersMutation(selectedFolder.id);
                            }}
                        ></ActionTag>
                        <ActionTag label="Delete permanently" onClick={onDeleteFolder}></ActionTag>
                    </div>
                </div>
            );
        }
    };

    const sendMessageAction = (action, message, index) => {
        let actionObject = {}
        if (folderFilterType && folderFilterType.length > 0) {
            actionObject["filters_active"] = [...folderFilterType];
        }
        if (showDeletedMessages) {
            actionObject["show_trashed"] = true;
        }
        actionObject = {
            "action": action,
            "screen": (selectedFolder && selectedFolder.id) === (homeFolder && homeFolder.id) ? "Inbox" : selectedFolder && selectedFolder.parent ? "SubfolderScreen" : "FolderScreen",
            "index_position": index,
            ...actionObject
        }
        console.log('this', actionObject)
        customAnalytics.message_action(actionObject, message);
    }

    const selectMessage = (message, listIndex, isShiftClick) => {
        if (enableShiftSelect && isShiftClick && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, listIndex);
            const end = Math.max(lastSelectedIndex, listIndex);
            const newSelectedMessagesMap = { ...selectedMessagesMap };
            const newMessagesToSend = [...messagesToSend];

            const messages = data.getFolderContent.messages.messages;

            for (let i = start; i <= end; i++) {
                const msg = messages[i];
                if (!newSelectedMessagesMap[msg.id]) {
                    newSelectedMessagesMap[msg.id] = true;
                    newMessagesToSend.push(msg);
                    sendMessageAction('Select', msg, i);
                }
            }

            setSelectedMessagesMap(newSelectedMessagesMap);
            setMessagesToSend(newMessagesToSend);
            setShowMultiSelect(true);
        } else {
            let newMessagesToSend;
            let newSelectedMessagesMap;

            if (selectedMessagesMap[message.id]) {
                newMessagesToSend = messagesToSend.filter(msg => msg.id !== message.id);
                newSelectedMessagesMap = { ...selectedMessagesMap };
                delete newSelectedMessagesMap[message.id];

                sendMessageAction('Deselect', message, listIndex);
            } else {
                newMessagesToSend = [...messagesToSend, message];
                newSelectedMessagesMap = {
                    ...selectedMessagesMap,
                    [message.id]: true
                };
                sendMessageAction('Select', message, listIndex);
            }

            setMessagesToSend(newMessagesToSend);
            setSelectedMessagesMap(newSelectedMessagesMap);

            // Check if this was the last message to be deselected
            if (Object.keys(newSelectedMessagesMap).length === 0) {
                setShowMultiSelect(false);
                setLastSelectedIndex(null);
            } else {
                setShowMultiSelect(true);
                setLastSelectedIndex(listIndex);
            }
        }
    };


    // TODO show/hide the scroll to bottom
    // if (messagesContainerRef.current){
    // 	console.log('height is', height)
    // 	console.log('container for mess',messagesContainerRef.current.scrollTop)
    // 	console.log('container for mess',messagesContainerRef.current.scrollHeight)
    // 	console.log('doc',window.scrollY)
    // 	console.log('doc',window.scrollX)
    // }

    // if (data) {
    let currentFolder = data && data.getFolderContent.folder;
    // let payload = {
    //     messages: data.getFolderContent.messages.messages,
    //     cursor: data.getFolderContent.messages.cursor,
    //     folders: data.getFolderContent.folders,
    //     parent: data.getFolderContent.parent,
    //     folder: data.getFolderContent.folder,
    //     path: data.getFolderContent.path
    // };
    return (
        <React.Fragment>
            <FolderWrapper
                style={{
                    // background: `url(${background}) no-repeat center center fixed`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                    // backgroundPosition: 'center'
                }}
                isSelecting={showMultiSelect}
            >
                <FolderHeader titleEditable></FolderHeader>
                <HideDeletedMessagesComponent />

                <MyDropzone
                    filesSelected={files => {
                        // console.log('received file from dropzone', files);
                        if (files) {
                            setFiles(files);
                        }
                    }}
                    disabled={showDeletedMessages}
                >
                    {/* {fileUploadingStatus ? <div style={{ backgroundColor: 'red' }}>File is uploading</div> : null} */}
                    {renderDeletedFolder(currentFolder)}
                    {/* RENDERING CUSTOM CONTENT */}

                    <MessagesList
                        payload={{
                            messages: data ? data.getFolderContent.messages.messages : undefined,
                            cursor: data ? data.getFolderContent.messages.cursor : undefined,
                            folders: data ? data.getFolderContent.folders : undefined,
                            parent: data ? data.getFolderContent.parent : undefined,
                            folder: data ? data.getFolderContent.folder : undefined,
                            path: data ? data.getFolderContent.path : undefined
                        }}
                        isTrash={showDeletedMessages}
                        filterMessages={filterData && filterData.filterMessages.messages}
                        filterMessagesCursor={filterData && filterData.filterMessages.cursor}
                        fetchMore={fetchMore}
                        filterFetchMore={filterFetchMore}
                        selectMessage={selectMessage}
                        enableShiftSelect={enableShiftSelect}
                        selectedMessagesMap={selectedMessagesMap}
                        screenName={(selectedFolder && selectedFolder.id) === (homeFolder && homeFolder.id) ? "Inbox" : selectedFolder && selectedFolder.parent ? "SubfolderScreen" : "FolderScreen"}
                    />
                    {/* {data ? (
                    ) : null} */}
                </MyDropzone>

                {renderInputOrMultiSelect(currentFolder)}
            </FolderWrapper>

            <SearchFoldersModal
                isOpen={showFoldersModal}
                action={searchActions.MOVE_MESSAGES}
                title={`Move ${messagesToSend.length} ${pluralHelper(messagesToSend.length, 'message')} to...`}
                addNewMoveMessage
                closeModal={() => {
                    setShowFoldersModal(false);
                }}
            ></SearchFoldersModal>
            <DeleteModal
                isOpen={showDeleteFolderModal}
                title={`${selectedFolder.title} will be deleted forever`}
                description={[
                    `This folder will be `,
                    <b key={'title_1'}>deleted forever</b>,
                    ` with `,
                    <b key={'title_2'}>all subfolders and messages</b>,
                    ` and you will not going to be able to access it's content any longer.`
                ]}
                closeModal={async response => {
                    if (response) {
                        await deleteFolders([selectedFolder]);
                    }
                    setShowDeleteFolderModal(false);
                }}
            ></DeleteModal>
        </React.Fragment>
    );
    // }
    // return null;
};

const FolderWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    // background-color: #7575c3;
    position: relative;
     ${props => props.isSelecting && `
        user-select: none;
    `}
`;

export default FolderContainer;
