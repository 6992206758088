import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Login from './screens/AuthScreens/Login/Login';
import Register from './screens/AuthScreens/Register/Register';
import Home from './screens/Home';
import Admin from './screens/Admin';
import Mobile from './screens/Mobile';
import Verified from './screens/Verified';
// import Welcome from './screens/Welcome';
import LandingHome from './screens/Landing/views/LandingHome';
import Privacy from './screens/Landing/views/Privacy';
import AppContainer from './AppContainer';
import Terms from './screens/Landing/views/Terms';
import Contact from './screens/Landing/views/Contact';
import ErrorPage from './screens/ErrorScreen/ErrorPage';
import ResetPasswordPage from './screens/AuthScreens/ResetPassword/ResetPasswordPage';
import VerifyAccountPage from './screens/AuthScreens/Verify/VerifyAccountPage';
import ForgotPasswordPage from './screens/AuthScreens/ForgotPassword/ForgotPasswordPage';
import { Helmet } from 'react-helmet';
import Checkout from './screens/Checkout/Checkout';
import PaymentSuccess from './screens/PaymentSuccess';
import PaymentCancel from './screens/PaymentCancel';
import Purchases from './screens/Checkout/Purchases';
import BlogList from './screens/Blog/BlogList';
import BlogPost from './screens/Blog/BlogPost';

function Routes() {
    return (
        <BrowserRouter>
            <Helmet>
                <title>Luckynote: Message Yourself Notes, Bookmarks and Tasks</title>
                <meta 
                    name="description"
                    content="Luckynote helps you collect and organize your notes, images, bookmarks, ideas and web content as a chat. Every note is a message to yourself. Everything in lucky note can be converted to a task or a to do."
                />
                <meta
                    name="keywords"
                    content="message yourself,whatsapp yourself,telegram yourself,signal yourself,viber yourself,todo,to do,productivity,luckynote,lucky note,remote working,remote work tools,startup tools,diary,journal,bullet journal,bujo,gtd,getting things done,school tools,research tools,movies,series,productivity hacking,life hacks,project management,chrome bookmark extension,web bookmark extension,book tracker,inbox,luckynotes,lucky notes"
                />
            </Helmet>
            <AppContainer>
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={() => {
                            if (localStorage.getItem('token')) {
                                return <Home />;
                            } else {
                                return <LandingHome />;
                            }
                        }}
                    />
                    <Route
                        path="/register"
                        render={() => {
                            if (localStorage.getItem('token')) {
                                return <Redirect to="/home" />;
                            } else {
                                return <Register />;
                            }
                        }}
                    />
                    <Route
                        path="/login"
                        render={() => {
                            if (localStorage.getItem('token')) {
                                return <Redirect to="/home" />;
                            } else {
                                return <Login />;
                            }
                        }}
                    />
                    <Route path="/home" render={() => <Redirect to="/"></Redirect>} />
                    {/* <Route path="/home/:id" component={Home} /> */}
                    <Route path="/mobile" component={Mobile} />
                    <Route path="/solucky" component={Admin} />
                    <Route path="/verified" component={Verified} />
                    <Route path="/check-account" component={VerifyAccountPage} />
                    <Route path="/forgot-password" component={ForgotPasswordPage} />
                    <Route path="/error" component={ErrorPage} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/contact" component={Contact} />
                    <Route path="/reset-password/:token" component={ResetPasswordPage} />
                    <Route path="/checkout" component={Checkout} />
                    <Route path="/purchases" component={Purchases} />
                    <Route path="/payment-success" component={PaymentSuccess} />
                    <Route path="/payment-cancelled" component={PaymentCancel} />
                    <Route path="/blog" exact component={BlogList} />
                    <Route path="/blog/:slug" exact component={BlogPost} />
                </Switch>
            </AppContainer>
        </BrowserRouter>
    );
}

export default Routes;
